import React, { useEffect, useState } from "react";
import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
import { useDispatch, useSelector } from "react-redux";
import { setLocationModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Button from "../WidgetsProfile/Buttons/Button";
import InputText from "../WidgetsProfile/Inputs/InputText";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { setCurrentRootData } from "../../../../../reducersToolkit/catalogSliceV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowLocationDetailModeration = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const { tempPreviewData, tempRootData } = useSelector(
    (state) => state?.catalogSliceV4 || {}
  );

  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);

  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);

  // const [countryOptions, setCountryOptions] = useState(null);
  // const [cityOptions, setCityOptions] = useState(null);

  // const [countryId, setCountryId] = useState(null);
  // const [cityid, setCityid] = useState(null);
  const [selectLocarion, setSelectLocarion] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [address, setAddress] = useState(null);
  const [newData, setNewData] = useState(null);

  useEffect(() => {
    if (mode === "moderation") {
      setSelectLocarion(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location || null
      );
      setAddress(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location
          ?.pointAddress || null
      );
      setSelectedCountry(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location
          ?.country || null
      );
      setSelectedCity(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location?.city ||
          null
      );
    } else if (mode === "moderatorAdded") {
      setSelectLocarion(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location || null
      );
      setAddress(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location
          ?.pointAddress || null
      );
      setSelectedCountry(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location
          ?.country || null
      );
      setSelectedCity(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location?.city ||
          null
      );
    }
  }, [
    mode,
    tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location,
    tempRootData?.rentalPoint_create_createRerntalPoint?.location,
  ]);

  useEffect(() => {
    setNewData({
      ...selectLocarion,
      country: selectedCountry || null,
      city: selectedCity || null,
      pointAddress: address || "",
    });
  }, [selectLocarion, selectedCity, selectedCountry, address]);

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setRentalPoint_countriesListPreview(
      tempPreviewData?.rentalPoint_countriesListPreview || []
    );
    setRentalPoint_citiesListPreview(
      tempPreviewData?.rentalPoint_citiesListPreview || []
    );
  }, [
    tempPreviewData?.rentalPoint_countriesListPreview,
    tempPreviewData?.rentalPoint_citiesListPreview,
  ]);

  const handleCountrySelect = (data) => {
    if (data?.idRootDocument !== selectedCountry?.idRootDocument) {
      setSelectedCountry(data);
      setSelectedCity(null);
      dispatch(
        fetchPreviewDataV4({
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          previewCollectionPath: `citiesPreview.countryIds.${data?.idRootDocument}`,
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
          errorStateName: "currentError",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "rentalPoint_citiesListPreview",
        })
      );
    }
  };

  const handleCitySelect = (data) => {
    if (data?.idRootDocument !== selectedCity?.idRootDocument) {
      setSelectedCity(data);
    }
  };

  const handleSetAddress = (event) => {
    setAddress(event.target.value);
  };

  /** Сохранение в стейт содержащий структуру данных rentalPoint информацию раздела "basicInformation" */
  const handleApply = () => {
    if (mode === "moderation") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState:
            "rentalPoint_moderation_rootRerntalPoint.location",
          data: newData,
        })
      );
      onClose();
    } else if (mode === "moderatorAdded") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState:
            "rentalPoint_create_createRerntalPoint.location",
          data: newData,
        })
      );
      onClose();
    }
  };

  // Устанавливаем начальные значения для адреса, страны и города при загрузке
  // useEffect(() => {
  //   if (mode === "moderatorAdded") {
  //     setAddress(newRentalPoint?.location?.pointAddress || "");
  //     setCountryId(newRentalPoint?.location?.countryId || null);
  //     setCityid(newRentalPoint?.location?.cityid || null);
  //   } else if (mode === "moderation") {
  //     setAddress(selectedRentalPoint?.location?.pointAddress || "");
  //     setCountryId(selectedRentalPoint?.location?.countryId || null);
  //     setCityid(selectedRentalPoint?.location?.cityid || null);
  //   }
  // }, [mode, newRentalPoint, selectedRentalPoint]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              aspectRatio: "16/9",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "16px",
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
              backgroundSize: "auto 300%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
              alt=""
              style={{
                width: "80px",
              }}
            />
          </div>

          <InputText
            label="Rental point address"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={address || ""}
            onChange={handleSetAddress}
          />
        </ContentBox>

        <ContentBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
              width: "100%",
            }}
          >
            <DropdownList
              options={rentalPoint_countriesListPreview}
              onSelect={handleCountrySelect}
              label="Select country"
              description="Please select a country."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCountry}
            />

            <DropdownList
              options={rentalPoint_citiesListPreview}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCity}
            />
          </div>
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            label="Add location"
            active={selectedCity && selectedCountry && address}
            onClick={handleApply}
            color="--color-primary"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to add a location?</h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowLocationDetailModeration;

// import React, { useEffect, useState } from "react";
// import DropdownList from "../WidgetsProfile/DropdownList/DropdownList";
// import { useDispatch, useSelector } from "react-redux";
// import { setLocationModeration } from "../../../../../reducersToolkit/moderationSliceV2";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import Button from "../WidgetsProfile/Buttons/Button";
// import InputText from "../WidgetsProfile/Inputs/InputText";

// const WindowLocationDetailModeration = ({ mode, onClose }) => {
//   const dispatch = useDispatch();
//   const [countryOptions, setCountryOptions] = useState([]);
//   const [cityOptions, setCityOptions] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [selectedCity, setSelectedCity] = useState(null);
//   const [countryId, setCountryId] = useState(null);
//   const [cityid, setCityid] = useState(null);
//   const [address, setAddress] = useState("");

//   const currentUser = useSelector((state) => state.userSliceV2.currentUser);
//   const { newRentalPoint, selectedRentalPoint, isLoading } = useSelector(
//     (state) => state.moderationSliceV2
//   );
//   const { countries, cities } = useSelector((state) => state.directorySliceV2);

//   useEffect(() => {
//     const formattedCountries = countries.map((country) => ({
//       id: country.id,
//       title: country?.title?.titleOriginal,
//     }));

//     setCountryOptions(formattedCountries);

//     if (selectedCountry) {
//       const foundCountry = countries.find(
//         (country) => country.id === selectedCountry.id
//       );
//       if (foundCountry) {
//         const filteredCities = cities.filter((city) =>
//           foundCountry.citiesIds.includes(city.id)
//         );
//         const formattedCities = filteredCities.map((city) => ({
//           id: city.id,
//           title: city?.title?.titleOriginal,
//         }));
//         setCityOptions(formattedCities);

//         // Устанавливаем первый город из отфильтрованного списка
//         if (formattedCities.length > 0) {
//           setSelectedCity(formattedCities[0]);
//         } else {
//           setSelectedCity(null);
//         }
//       } else {
//         setCityOptions([]);
//         setSelectedCity(null);
//       }
//     } else {
//       setCityOptions([]);
//       setSelectedCity(null);
//     }
//   }, [countries, cities, selectedCountry]);

//   useEffect(() => {
//     if (!selectedCountry && countryId) {
//       const initialCountry = countryOptions.find(
//         (country) => country.id === countryId
//       );
//       if (initialCountry) {
//         setSelectedCountry(initialCountry);
//       }
//     }
//   }, [countryOptions, countryId, selectedCountry]);

//   useEffect(() => {
//     if (!selectedCity && cityid) {
//       const initialCity = cityOptions.find((city) => city.id === cityid);
//       if (initialCity) {
//         setSelectedCity(initialCity);
//       }
//     }
//   }, [cityOptions, cityid, selectedCity]);

//   const handleCountrySelect = (data) => {
//     if (data?.id !== selectedCountry?.id) {
//       setSelectedCountry(data);
//     }
//   };

//   const handleCitySelect = (data) => {
//     if (data?.id !== selectedCity?.id) {
//       setSelectedCity(data);
//     }
//   };

//   const handleSetAddress = (event) => {
//     setAddress(event.target.value);
//   };

//   const handleAddLocation = () => {
//     if (selectedCity && selectedCountry && address) {
//       dispatch(
//         setLocationModeration({
//           cityId: selectedCity.id || null,
//           countryId: selectedCountry.id || null,
//           address: address || "",
//           userId: currentUser.id,
//           mode: mode,
//         })
//       );
//     } else {
//       console.error("Selected city or country is undefined");
//     }
//     onClose();
//   };

//   useEffect(() => {
//     if (mode === "moderatorAdded") {
//       setAddress(newRentalPoint?.location?.pointAddress || "");
//       setCountryId(newRentalPoint?.location?.countryId || null);
//       setCityid(newRentalPoint?.location?.cityid || null);
//     } else if (mode === "moderation") {
//       setAddress(selectedRentalPoint?.location?.pointAddress || "");
//       setCountryId(selectedRentalPoint?.location?.countryId || null);
//       setCityid(selectedRentalPoint?.location?.cityid || null);
//     }
//   }, [mode, newRentalPoint, selectedRentalPoint]);

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: "8px",
//       }}
//     >
//       <ContentBox position="start">
//         <div
//           style={{
//             width: "100%",
//             aspectRatio: "16/9",
//             backgroundColor: "var(--color-gray-100)",
//             borderRadius: "16px",
//             backgroundImage:
//               "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
//             backgroundSize: "auto 300%",
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <img
//             src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
//             alt=""
//             style={{
//               width: "80px",
//             }}
//           />
//         </div>

//         <InputText
//           label="Rental point address"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           value={address}
//           onChange={handleSetAddress}
//         />
//       </ContentBox>
//       <ContentBox>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "flex-start",
//             gap: "16px",
//             width: "100%",
//           }}
//         >
//           <DropdownList
//             options={countryOptions}
//             onSelect={handleCountrySelect}
//             label="Select country"
//             description="Please select a country."
//             fieldView="title"
//             fieldId="id"
//             initialSelectedOption={selectedCountry}
//           />
//           <DropdownList
//             options={cityOptions}
//             onSelect={handleCitySelect}
//             label="Select city"
//             description="Please select a city."
//             fieldView="title"
//             fieldId="id"
//             initialSelectedOption={selectedCity}
//           />
//         </div>
//       </ContentBox>
//       <ContentBox position="end" isLoading={isLoading}>
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             gap: "16px",
//           }}
//         >
//           <Button
//             label="Add location"
//             active={selectedCity && selectedCountry && address}
//             onClick={handleAddLocation}
//             color="--color-primary"
//             allert={
//               <div
//                 style={{
//                   padding: "48px",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   gap: "16px",
//                   textAlign: "center",
//                 }}
//               >
//                 <h1>Are you sure you want to add a location?</h1>
//               </div>
//             }
//           />
//         </div>
//       </ContentBox>
//     </div>
//   );
// };

// export default WindowLocationDetailModeration;
