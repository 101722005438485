// По пути metadataDocumentPath содержится lastUpdatedDocument:{
//   lastUpdatedDocumentId:id
//   }
//   И по пути metadataGeneralDocumentPath содержится lastUpdatedDocument:{
//   lastUpdatedDocumentId:id
//   }
//   Значения lastUpdatedDocumentId по каждому пути отличаются и уникальны.
//   Нужно учесть это в работе функции. Потому как сейчас при редактировании lastUpdatedDocumentId берется из metadataDocumentPath что является ошибкой.

//   Сделай пожалуйста полный код компонента.

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
import { db } from "../../../../../services/firebase/authFirebase";
import { v4 as uuidv4 } from "uuid";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// Максимальное количество попыток
const MAX_RETRIES = 3;

// Список кодов ошибок, для которых будут выполняться повторные попытки
const RETRYABLE_ERRORS = [
  "unavailable", // Firestore backend не доступен
  "deadline-exceeded", // Превышено время ожидания запроса
  "internal", // Внутренняя ошибка Firestore
  "failed-precondition", // Некорректные пред условия
  "network-error", // Ошибка сети
];

export const updateDataThunkV4 = createAsyncThunk(
  "countries/updateDataThunkV4",
  async (
    {
      rootData,
      previewData,
      newFiles = {}, // Новые файлы для обновления (если есть)
      iconFields,
      idPreviewDocument = null,
      idRootDocument,
      rootCollectionPath,
      previewCollectionPath,
      previewGeneralCollectionPath,
      metadataDocumentPath,
      metadataGeneralDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      previewGeneralDocumenState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue }
  ) => {
    let attempt = 0;
    let cleanedFinalData = {}; // Предварительное определение переменной
    let modifiablePreviewData = {}; // Предварительное определение переменной

    while (attempt < MAX_RETRIES) {
      try {
        console.log(`--- Начало обновления: Попытка ${attempt + 1} ---`);

        // Проверяем, нужно ли обновлять rootData
        if (rootData) {
          console.log(
            `Обновление rootData для документа ID: ${idRootDocument}`
          );

          // 1. Получение ссылки на документ корневой коллекции
          const docRef = doc(
            db,
            ...rootCollectionPath.split("."),
            idRootDocument
          );

          // 2. Проверка наличия документа перед обновлением
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            console.error(`Документ с ID ${idRootDocument} не существует.`);
            return rejectWithValue(
              `Document with ID ${idRootDocument} does not exist.`
            );
          }

          // 3. Получение текущих файлов из Firestore
          const currentFiles = docSnap.data().files || {};
          console.log("Текущие файлы из Firestore:", currentFiles);

          // 4. Загрузка новых файлов и получение URL
          const uploadedFiles = {};
          if (newFiles && Object.keys(newFiles).length > 0) {
            console.log("Загрузка новых файлов:", newFiles);
            for (const [fieldName, fileArray] of Object.entries(newFiles)) {
              if (fileArray && fileArray.length > 0) {
                const uploaded = await uploadFilesToFirebase(
                  `files/${idRootDocument}/${fieldName}`,
                  fileArray
                );
                const formattedFiles = uploaded.map((file) => ({
                  url: file.url,
                  name: file.name,
                  priority: file.priority || false,
                  id: uuidv4(),
                }));
                uploadedFiles[fieldName] = formattedFiles;
                console.log(
                  `Загруженные файлы для поля ${fieldName}:`,
                  formattedFiles
                );
              }
            }
          }

          // 5. Объединение старых и новых файлов
          const mergedFiles = Object.keys({
            ...currentFiles,
            ...uploadedFiles,
          }).reduce((acc, key) => {
            const existingFiles = currentFiles[key] || [];
            const newFilesArray = uploadedFiles[key] || [];
            acc[key] = [
              ...existingFiles.map((file) => ({
                url: file.url,
                name: file.name,
                priority: file.priority || false,
                id: file.id || uuidv4(),
              })),
              ...newFilesArray,
            ].filter((file) => file.url !== undefined);
            return acc;
          }, {});
          console.log("Объединенные файлы:", mergedFiles);

          // 6. Формирование данных для обновления
          const finalData = { ...rootData, files: mergedFiles };
          const cleanData = (data) =>
            JSON.parse(
              JSON.stringify(data, (key, value) =>
                value === undefined ? null : value
              )
            );
          cleanedFinalData = cleanData(finalData);
          console.log("Окончательные данные для обновления:", cleanedFinalData);

          // 7. Обновление корневого документа в Firestore
          await updateDoc(docRef, cleanedFinalData);
          console.log("Документ обновлен в Firestore:", docRef.path);
        }

        // Проверяем, нужно ли обновлять previewData
        if (previewData) {
          console.log(
            `Обновление previewData для документа ID: ${idRootDocument}`
          );

          // 8. Поиск idPreviewDocument, если не был передан
          if (!idPreviewDocument) {
            const querySnapshot = await getDocs(
              collection(db, ...previewCollectionPath.split("."))
            );
            console.log(
              "Получение документов из previewCollectionPath:",
              previewCollectionPath
            );

            for (const doc of querySnapshot.docs) {
              const data = doc.data().data || [];
              const foundItem = data.find(
                (item) => item.idRootDocument === idRootDocument
              );
              if (foundItem) {
                idPreviewDocument = doc.id;
                console.log(`idPreviewDocument найден: ${idPreviewDocument}`);
                break;
              }
            }

            if (!idPreviewDocument) {
              throw new Error(
                `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
              );
            }
          }

          // 9. Обновление документа предварительного просмотра
          const previewDocRef = doc(
            db,
            ...previewCollectionPath.split("."),
            idPreviewDocument
          );

          const previewDocSnap = await getDoc(previewDocRef);

          if (!previewDocSnap.exists()) {
            throw new Error(
              `Документ с ID ${idPreviewDocument} в коллекции preview не найден.`
            );
          }

          let previewDataArray = previewDocSnap.data().data || [];
          const itemIndex = previewDataArray.findIndex(
            (item) => item.idRootDocument === idRootDocument
          );

          if (itemIndex !== -1) {
            // Удаление редактируемого объекта из массива
            previewDataArray.splice(itemIndex, 1);
          }

          // --- Логика выбора fileUrl ---
          let fileUrl = previewData.fileUrl; // Начальное значение из previewData
          console.log("fileUrl", previewData, fileUrl);
          if (newFiles && Object.keys(newFiles).length > 0) {
            if (rootData && rootData.files && iconFields) {
              const filesArray = rootData.files[iconFields] || [];
              const fileWithPriority = filesArray.find(
                (file) => file.priority === true
              );
              fileUrl = fileWithPriority
                ? fileWithPriority.url
                : filesArray[0]?.url || "";
            } else {
              // Если rootData нет, получаем файлы из Firestore
              const docRef = doc(
                db,
                ...rootCollectionPath.split("."),
                idRootDocument
              );
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                const currentFiles = docSnap.data().files || {};
                const filesArray = currentFiles[iconFields] || [];
                const fileWithPriority = filesArray.find(
                  (file) => file.priority === true
                );
                fileUrl = fileWithPriority
                  ? fileWithPriority.url
                  : filesArray[0]?.url || "";
              }
            }
          }

          // Создаем копию previewData перед изменением
          // modifiablePreviewData = { ...previewData };
          modifiablePreviewData = { ...previewData, fileUrl };
          // modifiablePreviewData.fileUrl = fileUrl;
          modifiablePreviewData.idPreviewDocument = idPreviewDocument;
          modifiablePreviewData.idRootDocument = idRootDocument;
          // modifiablePreviewData.created = Date.now();
          modifiablePreviewData.lastUpdated = Date.now();

          console.log(
            "Данные предварительного просмотра после обновления:",
            modifiablePreviewData
          );

          // 10. Обновление preview документа в previewCollectionPath
          const objectSize = new Blob([JSON.stringify(modifiablePreviewData)])
            .size;
          const totalCurrentSize = previewDataArray.reduce(
            (acc, item) => acc + new Blob([JSON.stringify(item)]).size,
            0
          );
          const newTotalSize = totalCurrentSize + objectSize;
          const maxSize = 1024 * 1024; // 1 MB

          let idDocumentToSave;
          let newDocumentSize;

          if (newTotalSize <= maxSize) {
            previewDataArray.push(modifiablePreviewData);
            newDocumentSize = newTotalSize;
            idDocumentToSave = idPreviewDocument;
          } else {
            const newOriginalDocRef = doc(
              collection(db, ...previewCollectionPath.split("."))
            );
            idDocumentToSave = newOriginalDocRef.id;
            modifiablePreviewData.idPreviewDocument = idDocumentToSave;
            newDocumentSize = objectSize;
            previewDataArray = [modifiablePreviewData];
          }

          const originalDocRef = doc(
            collection(db, ...previewCollectionPath.split(".")),
            idDocumentToSave
          );
          await setDoc(
            originalDocRef,
            { data: previewDataArray, documentSize: newDocumentSize },
            { merge: true }
          );
          console.log(
            "Обновление preview документа завершено:",
            originalDocRef.path
          );

          // 11. Обновление preview документа в previewGeneralCollectionPath, если предоставлен
          if (previewGeneralCollectionPath) {
            console.log("Начало обновления для previewGeneralCollectionPath");

            // Получаем метаданные для general preview collection, чтобы найти данные последнего обновленного документа
            const generalMetadataDocRef = doc(
              db,
              ...metadataGeneralDocumentPath.split(".")
            );

            console.log(
              "updateDataThunkV4 metadataGeneralDocumentPath",
              metadataGeneralDocumentPath
            );
            const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

            let generalLastUpdatedDocId;
            let generalLastUpdatedDocSize;

            if (generalMetadataDocSnap.exists()) {
              const generalMetadataData = generalMetadataDocSnap.data();
              console.log(
                "updateDataThunkV4 generalMetadataData",
                generalMetadataData
              );
              generalLastUpdatedDocId =
                generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
              generalLastUpdatedDocSize =
                generalMetadataData.lastUpdatedDocument?.documentSize || 0;
            } else {
              // Инициализируем, если документа метаданных нет
              generalLastUpdatedDocId = null;
              generalLastUpdatedDocSize = 0;
            }

            // Определяем, в каком документе сохранить preview данные
            let generalNewDocumentSize;
            let generalIdDocumentToSave;

            const objectSize = new Blob([JSON.stringify(modifiablePreviewData)])
              .size;

            if (
              generalLastUpdatedDocId &&
              generalLastUpdatedDocSize + objectSize <= 1024 * 1024
            ) {
              generalIdDocumentToSave = generalLastUpdatedDocId;
              console.log(
                "updateDataThunkV4 generalIdDocumentToSave",
                generalIdDocumentToSave
              );
              generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
              console.log(
                "updateDataThunkV4 generalNewDocumentSize",
                generalNewDocumentSize
              );
            } else {
              const newGeneralDocRef = doc(
                collection(db, ...previewGeneralCollectionPath.split("."))
              );
              console.log(
                "updateDataThunkV4 newGeneralDocRef.id",
                newGeneralDocRef.id
              );
              generalIdDocumentToSave = newGeneralDocRef.id;
              generalNewDocumentSize = objectSize;
            }

            const generalDocRef = doc(
              db,
              ...previewGeneralCollectionPath.split("."),
              generalIdDocumentToSave
            );

            // Получаем существующие данные документа для обновления или инициализации нового массива

            const generalDocSnap = await getDoc(generalDocRef);
            let generalPreviewDataArray = generalDocSnap.exists()
              ? generalDocSnap.data().data || []
              : [];
            console.log(
              "updateDataThunkV4 generalPreviewDataArray",
              generalPreviewDataArray
            );

            // Находим и заменяем существующий элемент, если он есть
            const generalItemIndex = generalPreviewDataArray.findIndex(
              (item) => item.idRootDocument === idRootDocument
            );
            console.log("updateDataThunkV4 generalItemIndex", generalItemIndex);

            if (generalItemIndex !== -1) {
              console.log(
                `Элемент с idRootDocument ${idRootDocument} найден, заменяем его`
              );
              generalPreviewDataArray[generalItemIndex] = modifiablePreviewData;
            } else {
              console.log(
                `Элемент с idRootDocument ${idRootDocument} не найден, добавляем новый`
              );
              generalPreviewDataArray.push(modifiablePreviewData);
            }

            // Обновляем или создаем документ в previewGeneralCollectionPath
            await setDoc(
              generalDocRef,
              {
                data: generalPreviewDataArray,
                documentSize: generalNewDocumentSize,
              },
              { merge: true }
            );
            console.log(
              "Завершено обновление general preview документа:",
              generalDocRef.path
            );

            // Обновляем метаданные для последнего обновленного документа
            await setDoc(
              generalMetadataDocRef,
              {
                lastUpdatedDocument: {
                  lastUpdatedDocumentId: generalIdDocumentToSave,
                  documentSize: generalNewDocumentSize,
                },
              },
              { merge: true }
            );
            console.log(
              "Обновлено lastUpdatedDocument в general metadata:",
              generalMetadataDocRef.path
            );
          }

          // 12. Обновление lastUpdatedDocument в metadataDocumentPath
          // const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
          // await setDoc(
          //   languageDocRef,
          //   {
          //     lastUpdatedDocument: {
          //       lastUpdatedDocumentId: idDocumentToSave,
          //       documentSize: newDocumentSize,
          //     },
          //   },
          //   { merge: true }
          // );
          // console.log(
          //   "Обновление lastUpdatedDocument в metadata завершено:",
          //   languageDocRef.path
          // );

          // 13. Обновление lastUpdatedDocument в metadataGeneralDocumentPath, если предоставлен
          // if (metadataGeneralDocumentPath) {
          //   const generalMetadataDocRef = doc(
          //     db,
          //     ...metadataGeneralDocumentPath.split(".")
          //   );
          //   await setDoc(
          //     generalMetadataDocRef,
          //     {
          //       lastUpdatedDocument: {
          //         lastUpdatedDocumentId: idDocumentToSave,
          //         documentSize: newDocumentSize,
          //       },
          //     },
          //     { merge: true }
          //   );
          //   console.log(
          //     "Обновление lastUpdatedDocument в general metadata завершено:",
          //     generalMetadataDocRef.path
          //   );
          // }
          // 12. Обновление lastUpdatedDocument в metadataDocumentPath
          const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
          const languageMetadataDocSnap = await getDoc(languageDocRef);

          let lastUpdatedDocumentId = idDocumentToSave; // Значение по умолчанию

          if (languageMetadataDocSnap.exists()) {
            const languageMetadataData = languageMetadataDocSnap.data();
            // Сохраняем lastUpdatedDocumentId из существующего документа
            lastUpdatedDocumentId =
              languageMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
              idDocumentToSave;
          }

          await setDoc(
            languageDocRef,
            {
              lastUpdatedDocument: {
                lastUpdatedDocumentId: lastUpdatedDocumentId, // Сохраняем правильное значение
                documentSize: newDocumentSize,
              },
            },
            { merge: true }
          );
          console.log(
            "Обновление lastUpdatedDocument в metadata завершено:",
            languageDocRef.path
          );

          // 13. Обновление lastUpdatedDocument в metadataGeneralDocumentPath, если предоставлен
          if (metadataGeneralDocumentPath) {
            const generalMetadataDocRef = doc(
              db,
              ...metadataGeneralDocumentPath.split(".")
            );
            const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

            let generalLastUpdatedDocumentId = idDocumentToSave; // Значение по умолчанию

            if (generalMetadataDocSnap.exists()) {
              const generalMetadataData = generalMetadataDocSnap.data();
              // Сохраняем lastUpdatedDocumentId из существующего документа
              generalLastUpdatedDocumentId =
                generalMetadataData.lastUpdatedDocument
                  ?.lastUpdatedDocumentId || idDocumentToSave;
            }

            await setDoc(
              generalMetadataDocRef,
              {
                lastUpdatedDocument: {
                  lastUpdatedDocumentId: generalLastUpdatedDocumentId, // Сохраняем правильное значение
                  documentSize: newDocumentSize,
                },
              },
              { merge: true }
            );
            console.log(
              "Обновление lastUpdatedDocument в general metadata завершено:",
              generalMetadataDocRef.path
            );
          }
        }

        // Формируем payload для return
        const resultPayload = {
          idRootDocument,
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
          loadingStateName,
          errorStateName,
        };

        if (rootData) {
          resultPayload.rootDocumentData = cleanedFinalData;
        }

        if (previewData) {
          resultPayload.previewDocumentData = modifiablePreviewData;
        }

        return resultPayload;
      } catch (error) {
        console.error(`Ошибка при попытке обновления: ${attempt + 1}:`, error);

        if (RETRYABLE_ERRORS.includes(error.code)) {
          attempt++;
          console.log(
            `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
          );
          if (attempt >= MAX_RETRIES) {
            const errorMessage = interpretErrorV4(error.code);
            return rejectWithValue({ errorMessage });
          }
        } else {
          const errorMessage = interpretErrorV4(error.code);
          return rejectWithValue({ errorMessage });
        }
      }
    }
  }
);
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   getDoc,
//   getDocs,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// // Максимальное количество попыток
// const MAX_RETRIES = 3;

// // Список кодов ошибок, для которых будут выполняться повторные попытки
// const RETRYABLE_ERRORS = [
//   "unavailable", // Firestore backend не доступен
//   "deadline-exceeded", // Превышено время ожидания запроса
//   "internal", // Внутренняя ошибка Firestore
//   "failed-precondition", // Некорректные пред условия
//   "network-error", // Ошибка сети
// ];

// export const updateDataThunkV4 = createAsyncThunk(
//   "countries/updateDataThunkV4",
//   async (
//     {
//       rootData,
//       previewData,
//       newFiles = {}, // Новые файлы для обновления (если есть)
//       iconFields,
//       idPreviewDocument = null,
//       idRootDocument,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     let attempt = 0;
//     let cleanedFinalData = {}; // Предварительное определение переменной
//     let modifiablePreviewData = {}; // Предварительное определение переменной

//     while (attempt < MAX_RETRIES) {
//       try {
//         console.log(`--- Начало обновления: Попытка ${attempt + 1} ---`);

//         // Проверяем, нужно ли обновлять rootData
//         if (rootData) {
//           console.log(
//             `Обновление rootData для документа ID: ${idRootDocument}`
//           );

//           // 1. Получение ссылки на документ корневой коллекции
//           const docRef = doc(
//             db,
//             ...rootCollectionPath.split("."),
//             idRootDocument
//           );

//           // 2. Проверка наличия документа перед обновлением
//           const docSnap = await getDoc(docRef);
//           if (!docSnap.exists()) {
//             console.error(`Документ с ID ${idRootDocument} не существует.`);
//             return rejectWithValue(
//               `Document with ID ${idRootDocument} does not exist.`
//             );
//           }

//           // 3. Получение текущих файлов из Firestore
//           const currentFiles = docSnap.data().files || {};
//           console.log("Текущие файлы из Firestore:", currentFiles);

//           // 4. Загрузка новых файлов и получение URL
//           const uploadedFiles = {};
//           if (newFiles && Object.keys(newFiles).length > 0) {
//             console.log("Загрузка новых файлов:", newFiles);
//             for (const [fieldName, fileArray] of Object.entries(newFiles)) {
//               if (fileArray && fileArray.length > 0) {
//                 const uploaded = await uploadFilesToFirebase(
//                   `files/${idRootDocument}/${fieldName}`,
//                   fileArray
//                 );
//                 const formattedFiles = uploaded.map((file) => ({
//                   url: file.url,
//                   name: file.name,
//                   priority: file.priority || false,
//                   id: uuidv4(),
//                 }));
//                 uploadedFiles[fieldName] = formattedFiles;
//                 console.log(
//                   `Загруженные файлы для поля ${fieldName}:`,
//                   formattedFiles
//                 );
//               }
//             }
//           }

//           // 5. Объединение старых и новых файлов
//           const mergedFiles = Object.keys({
//             ...currentFiles,
//             ...uploadedFiles,
//           }).reduce((acc, key) => {
//             const existingFiles = currentFiles[key] || [];
//             const newFilesArray = uploadedFiles[key] || [];
//             acc[key] = [
//               ...existingFiles.map((file) => ({
//                 url: file.url,
//                 name: file.name,
//                 priority: file.priority || false,
//                 id: file.id || uuidv4(),
//               })),
//               ...newFilesArray,
//             ].filter((file) => file.url !== undefined);
//             return acc;
//           }, {});
//           console.log("Объединенные файлы:", mergedFiles);

//           // 6. Формирование данных для обновления
//           const finalData = { ...rootData, files: mergedFiles };
//           const cleanData = (data) =>
//             JSON.parse(
//               JSON.stringify(data, (key, value) =>
//                 value === undefined ? null : value
//               )
//             );
//           cleanedFinalData = cleanData(finalData);
//           console.log("Окончательные данные для обновления:", cleanedFinalData);

//           // 7. Обновление корневого документа в Firestore
//           await updateDoc(docRef, cleanedFinalData);
//           console.log("Документ обновлен в Firestore:", docRef.path);
//         }

//         // Проверяем, нужно ли обновлять previewData
//         if (previewData) {
//           console.log(
//             `Обновление previewData для документа ID: ${idRootDocument}`
//           );

//           // 8. Поиск idPreviewDocument, если не был передан
//           if (!idPreviewDocument) {
//             const querySnapshot = await getDocs(
//               collection(db, ...previewCollectionPath.split("."))
//             );
//             console.log(
//               "Получение документов из previewCollectionPath:",
//               previewCollectionPath
//             );

//             for (const doc of querySnapshot.docs) {
//               const data = doc.data().data || [];
//               const foundItem = data.find(
//                 (item) => item.idRootDocument === idRootDocument
//               );
//               if (foundItem) {
//                 idPreviewDocument = doc.id;
//                 console.log(`idPreviewDocument найден: ${idPreviewDocument}`);
//                 break;
//               }
//             }

//             if (!idPreviewDocument) {
//               throw new Error(
//                 `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
//               );
//             }
//           }

//           // 9. Обновление документа предварительного просмотра
//           const previewDocRef = doc(
//             db,
//             ...previewCollectionPath.split("."),
//             idPreviewDocument
//           );

//           const previewDocSnap = await getDoc(previewDocRef);

//           if (!previewDocSnap.exists()) {
//             throw new Error(
//               `Документ с ID ${idPreviewDocument} в коллекции preview не найден.`
//             );
//           }

//           let previewDataArray = previewDocSnap.data().data || [];
//           const itemIndex = previewDataArray.findIndex(
//             (item) => item.idRootDocument === idRootDocument
//           );

//           if (itemIndex !== -1) {
//             // Удаление редактируемого объекта из массива
//             previewDataArray.splice(itemIndex, 1);
//           }

//           // --- Логика выбора fileUrl ---
//           let fileUrl = previewData.fileUrl; // Начальное значение из previewData
//           console.log("fileUrl", previewData, fileUrl);
//           if (newFiles && Object.keys(newFiles).length > 0) {
//             if (rootData && rootData.files && iconFields) {
//               const filesArray = rootData.files[iconFields] || [];
//               const fileWithPriority = filesArray.find(
//                 (file) => file.priority === true
//               );
//               fileUrl = fileWithPriority
//                 ? fileWithPriority.url
//                 : filesArray[0]?.url || "";
//             } else {
//               // Если rootData нет, получаем файлы из Firestore
//               const docRef = doc(
//                 db,
//                 ...rootCollectionPath.split("."),
//                 idRootDocument
//               );
//               const docSnap = await getDoc(docRef);
//               if (docSnap.exists()) {
//                 const currentFiles = docSnap.data().files || {};
//                 const filesArray = currentFiles[iconFields] || [];
//                 const fileWithPriority = filesArray.find(
//                   (file) => file.priority === true
//                 );
//                 fileUrl = fileWithPriority
//                   ? fileWithPriority.url
//                   : filesArray[0]?.url || "";
//               }
//             }
//           }

//           // Создаем копию previewData перед изменением
//           // modifiablePreviewData = { ...previewData };
//           modifiablePreviewData = { ...previewData, fileUrl };
//           // modifiablePreviewData.fileUrl = fileUrl;
//           modifiablePreviewData.idPreviewDocument = idPreviewDocument;
//           modifiablePreviewData.idRootDocument = idRootDocument;
//           // modifiablePreviewData.created = Date.now();
//           modifiablePreviewData.lastUpdated = Date.now();

//           console.log(
//             "Данные предварительного просмотра после обновления:",
//             modifiablePreviewData
//           );

//           // 10. Обновление preview документа в previewCollectionPath
//           const objectSize = new Blob([JSON.stringify(modifiablePreviewData)])
//             .size;
//           const totalCurrentSize = previewDataArray.reduce(
//             (acc, item) => acc + new Blob([JSON.stringify(item)]).size,
//             0
//           );
//           const newTotalSize = totalCurrentSize + objectSize;
//           const maxSize = 1024 * 1024; // 1 MB

//           let idDocumentToSave;
//           let newDocumentSize;

//           if (newTotalSize <= maxSize) {
//             previewDataArray.push(modifiablePreviewData);
//             newDocumentSize = newTotalSize;
//             idDocumentToSave = idPreviewDocument;
//           } else {
//             const newOriginalDocRef = doc(
//               collection(db, ...previewCollectionPath.split("."))
//             );
//             idDocumentToSave = newOriginalDocRef.id;
//             modifiablePreviewData.idPreviewDocument = idDocumentToSave;
//             newDocumentSize = objectSize;
//             previewDataArray = [modifiablePreviewData];
//           }

//           const originalDocRef = doc(
//             collection(db, ...previewCollectionPath.split(".")),
//             idDocumentToSave
//           );
//           await setDoc(
//             originalDocRef,
//             { data: previewDataArray, documentSize: newDocumentSize },
//             { merge: true }
//           );
//           console.log(
//             "Обновление preview документа завершено:",
//             originalDocRef.path
//           );

//           // 11. Обновление preview документа в previewGeneralCollectionPath, если предоставлен
//           if (previewGeneralCollectionPath) {
//             console.log("Начало обновления для previewGeneralCollectionPath");

//             // Получаем метаданные для general preview collection
//             const generalMetadataDocRef = doc(
//               db,
//               ...metadataGeneralDocumentPath.split(".")
//             );
//             const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

//             let generalLastUpdatedDocId;
//             let generalLastUpdatedDocSize;

//             if (generalMetadataDocSnap.exists()) {
//               const generalMetadataData = generalMetadataDocSnap.data();
//               generalLastUpdatedDocId =
//                 generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
//               generalLastUpdatedDocSize =
//                 generalMetadataData.lastUpdatedDocument?.documentSize || 0;
//             } else {
//               // Инициализируем, если документа метаданных нет
//               generalLastUpdatedDocId = null;
//               generalLastUpdatedDocSize = 0;
//             }

//             // Определяем, в каком документе сохранить preview данные
//             let generalNewDocumentSize;
//             let generalIdDocumentToSave;

//             const objectSize = new Blob([JSON.stringify(modifiablePreviewData)])
//               .size;

//             if (
//               generalLastUpdatedDocId &&
//               generalLastUpdatedDocSize + objectSize <= 1024 * 1024
//             ) {
//               generalIdDocumentToSave = generalLastUpdatedDocId;
//               generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
//             } else {
//               const newGeneralDocRef = doc(
//                 collection(db, ...previewGeneralCollectionPath.split("."))
//               );
//               generalIdDocumentToSave = newGeneralDocRef.id;
//               generalNewDocumentSize = objectSize;
//             }

//             const generalDocRef = doc(
//               db,
//               ...previewGeneralCollectionPath.split("."),
//               generalIdDocumentToSave
//             );

//             // Получаем существующие данные документа для обновления или инициализации нового массива
//             const generalDocSnap = await getDoc(generalDocRef);
//             let generalPreviewDataArray = generalDocSnap.exists()
//               ? generalDocSnap.data().data || []
//               : [];

//             // Находим и заменяем существующий элемент, если он есть
//             const generalItemIndex = generalPreviewDataArray.findIndex(
//               (item) => item.idRootDocument === idRootDocument
//             );
//             if (generalItemIndex !== -1) {
//               console.log(
//                 `Элемент с idRootDocument ${idRootDocument} найден, заменяем его`
//               );
//               generalPreviewDataArray[generalItemIndex] = modifiablePreviewData;
//             } else {
//               console.log(
//                 `Элемент с idRootDocument ${idRootDocument} не найден, добавляем новый`
//               );
//               generalPreviewDataArray.push(modifiablePreviewData);
//             }

//             // Обновляем или создаем документ в previewGeneralCollectionPath
//             await setDoc(
//               generalDocRef,
//               {
//                 data: generalPreviewDataArray,
//                 documentSize: generalNewDocumentSize,
//               },
//               { merge: true }
//             );
//             console.log(
//               "Завершено обновление general preview документа:",
//               generalDocRef.path
//             );

//             // Обновляем метаданные для последнего обновленного документа
//             await setDoc(
//               generalMetadataDocRef,
//               {
//                 lastUpdatedDocument: {
//                   lastUpdatedDocumentId: generalIdDocumentToSave,
//                   documentSize: generalNewDocumentSize,
//                 },
//               },
//               { merge: true }
//             );
//             console.log(
//               "Обновлено lastUpdatedDocument в general metadata:",
//               generalMetadataDocRef.path
//             );
//           }

//           // 12. Обновление lastUpdatedDocument в metadataDocumentPath
//           const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
//           await setDoc(
//             languageDocRef,
//             {
//               lastUpdatedDocument: {
//                 lastUpdatedDocumentId: idDocumentToSave,
//                 documentSize: newDocumentSize,
//               },
//             },
//             { merge: true }
//           );
//           console.log(
//             "Обновление lastUpdatedDocument в metadata завершено:",
//             languageDocRef.path
//           );

//           // 13. Обновление lastUpdatedDocument в metadataGeneralDocumentPath, если предоставлен
//           if (metadataGeneralDocumentPath) {
//             const generalMetadataDocRef = doc(
//               db,
//               ...metadataGeneralDocumentPath.split(".")
//             );
//             await setDoc(
//               generalMetadataDocRef,
//               {
//                 lastUpdatedDocument: {
//                   lastUpdatedDocumentId: idDocumentToSave,
//                   documentSize: newDocumentSize,
//                 },
//               },
//               { merge: true }
//             );
//             console.log(
//               "Обновление lastUpdatedDocument в general metadata завершено:",
//               generalMetadataDocRef.path
//             );
//           }
//         }

//         // Формируем payload для return
//         const resultPayload = {
//           idRootDocument,
//           rootCollectionPath,
//           previewCollectionPath,
//           previewGeneralCollectionPath,
//           rootCurrentDocumentState,
//           previewCurrentDocumenState,
//           previewGeneralDocumenState,
//           loadingStateName,
//           errorStateName,
//         };

//         if (rootData) {
//           resultPayload.rootDocumentData = cleanedFinalData;
//         }

//         if (previewData) {
//           resultPayload.previewDocumentData = modifiablePreviewData;
//         }

//         return resultPayload;
//       } catch (error) {
//         console.error(`Ошибка при попытке обновления: ${attempt + 1}:`, error);

//         if (RETRYABLE_ERRORS.includes(error.code)) {
//           attempt++;
//           console.log(
//             `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
//           );
//           if (attempt >= MAX_RETRIES) {
//             const errorMessage = interpretErrorV4(error.code);
//             return rejectWithValue({ errorMessage });
//           }
//         } else {
//           const errorMessage = interpretErrorV4(error.code);
//           return rejectWithValue({ errorMessage });
//         }
//       }
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   getDoc,
//   getDocs,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// // Максимальное количество попыток
// const MAX_RETRIES = 3;

// // Список кодов ошибок, для которых будут выполняться повторные попытки
// const RETRYABLE_ERRORS = [
//   "unavailable", // Firestore backend не доступен
//   "deadline-exceeded", // Превышено время ожидания запроса
//   "internal", // Внутренняя ошибка Firestore
//   "failed-precondition", // Некорректные пред условия
//   "network-error", // Ошибка сети
// ];

// export const updateDataThunkV4 = createAsyncThunk(
//   "countries/updateDataThunkV4",
//   async (
//     {
//       rootData,
//       previewData,
//       newFiles = {}, // Новые файлы для обновления (если есть)
//       iconFields,
//       idPreviewDocument = null,
//       idRootDocument,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     let attempt = 0;

//     while (attempt < MAX_RETRIES) {
//       try {
//         console.log(`--- Начало обновления: Попытка ${attempt + 1} ---`);

//         // Проверяем, нужно ли обновлять rootData
//         if (rootData) {
//           console.log(
//             `Обновление rootData для документа ID: ${idRootDocument}`
//           );

//           // 1. Получение ссылки на документ корневой коллекции
//           const docRef = doc(
//             db,
//             ...rootCollectionPath.split("."),
//             idRootDocument
//           );

//           // 2. Проверка наличия документа перед обновлением
//           const docSnap = await getDoc(docRef);
//           if (!docSnap.exists()) {
//             console.error(`Документ с ID ${idRootDocument} не существует.`);
//             return rejectWithValue(
//               `Document with ID ${idRootDocument} does not exist.`
//             );
//           }

//           // 3. Получение текущих файлов из Firestore
//           const currentFiles = docSnap.data().files || {};
//           console.log("Текущие файлы из Firestore:", currentFiles);

//           // 4. Загрузка новых файлов и получение URL
//           const uploadedFiles = {};
//           if (newFiles && Object.keys(newFiles).length > 0) {
//             console.log("Загрузка новых файлов:", newFiles);
//             for (const [fieldName, fileArray] of Object.entries(newFiles)) {
//               if (fileArray && fileArray.length > 0) {
//                 const uploaded = await uploadFilesToFirebase(
//                   `files/${idRootDocument}/${fieldName}`,
//                   fileArray
//                 );
//                 const formattedFiles = uploaded.map((file) => ({
//                   url: file.url,
//                   name: file.name,
//                   priority: file.priority || false,
//                   id: uuidv4(),
//                 }));
//                 uploadedFiles[fieldName] = formattedFiles;
//                 console.log(
//                   `Загруженные файлы для поля ${fieldName}:`,
//                   formattedFiles
//                 );
//               }
//             }
//           }

//           // 5. Объединение старых и новых файлов
//           const mergedFiles = Object.keys({
//             ...currentFiles,
//             ...uploadedFiles,
//           }).reduce((acc, key) => {
//             const existingFiles = currentFiles[key] || [];
//             const newFilesArray = uploadedFiles[key] || [];
//             acc[key] = [
//               ...existingFiles.map((file) => ({
//                 url: file.url,
//                 name: file.name,
//                 priority: file.priority || false,
//                 id: file.id || uuidv4(),
//               })),
//               ...newFilesArray,
//             ].filter((file) => file.url !== undefined);
//             return acc;
//           }, {});
//           console.log("Объединенные файлы:", mergedFiles);

//           // 6. Формирование данных для обновления
//           const finalData = { ...rootData, files: mergedFiles };
//           const cleanData = (data) =>
//             JSON.parse(
//               JSON.stringify(data, (key, value) =>
//                 value === undefined ? null : value
//               )
//             );
//           const cleanedFinalData = cleanData(finalData);
//           console.log("Окончательные данные для обновления:", cleanedFinalData);

//           // 7. Обновление корневого документа в Firestore
//           await updateDoc(docRef, cleanedFinalData);
//           console.log("Документ обновлен в Firestore:", docRef.path);
//         }

//         // Проверяем, нужно ли обновлять previewData
//         if (previewData) {
//           console.log(
//             `Обновление previewData для документа ID: ${idRootDocument}`
//           );

//           // 8. Поиск idPreviewDocument, если не был передан
//           if (!idPreviewDocument) {
//             const querySnapshot = await getDocs(
//               collection(db, ...previewCollectionPath.split("."))
//             );
//             console.log(
//               "Получение документов из previewCollectionPath:",
//               previewCollectionPath
//             );
//             for (const doc of querySnapshot.docs) {
//               const data = doc.data().data || [];
//               const foundItem = data.find(
//                 (item) => item.idRootDocument === idRootDocument
//               );
//               if (foundItem) {
//                 idPreviewDocument = doc.id;
//                 console.log(`idPreviewDocument найден: ${idPreviewDocument}`);
//                 break;
//               }
//             }

//             if (!idPreviewDocument) {
//               throw new Error(
//                 `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
//               );
//             }
//           }

//           // 9. Обновление документа предварительного просмотра
//           const previewDocRef = doc(
//             db,
//             ...previewCollectionPath.split("."),
//             idPreviewDocument
//           );

//           console.log("previewCollectionPath", previewCollectionPath);
//           const previewDocSnap = await getDoc(previewDocRef);

//           if (!previewDocSnap.exists()) {
//             throw new Error(
//               `Документ с ID ${idPreviewDocument} в коллекции preview не найден.`
//             );
//           }

//           let previewDataArray = previewDocSnap.data().data || [];
//           const itemIndex = previewDataArray.findIndex(
//             (item) => item.idRootDocument === idRootDocument
//           );

//           if (itemIndex !== -1) {
//             // Удаление редактируемого объекта из массива
//             previewDataArray.splice(itemIndex, 1);
//           }

//           // --- Логика выбора fileUrl ---
//           let fileUrl = "";
//           if (rootData && rootData.files && iconFields) {
//             const filesArray = rootData.files[iconFields] || [];
//             const fileWithPriority = filesArray.find(
//               (file) => file.priority === true
//             );
//             fileUrl = fileWithPriority
//               ? fileWithPriority.url
//               : filesArray[0]?.url || "";
//           } else {
//             // Если rootData нет, получаем файлы из Firestore
//             const docRef = doc(
//               db,
//               ...rootCollectionPath.split("."),
//               idRootDocument
//             );
//             const docSnap = await getDoc(docRef);
//             if (docSnap.exists()) {
//               const currentFiles = docSnap.data().files || {};
//               const filesArray = currentFiles[iconFields] || [];
//               const fileWithPriority = filesArray.find(
//                 (file) => file.priority === true
//               );
//               fileUrl = fileWithPriority
//                 ? fileWithPriority.url
//                 : filesArray[0]?.url || "";
//             }
//           }

//           // Создаем копию previewData перед изменением
//           const modifiablePreviewData = { ...previewData };
//           modifiablePreviewData.fileUrl = fileUrl;
//           modifiablePreviewData.idPreviewDocument = idPreviewDocument;
//           modifiablePreviewData.idRootDocument = idRootDocument;
//           modifiablePreviewData.createdat = Date.now();
//           modifiablePreviewData.lastUpdated = Date.now();
//           console.log(
//             "Данные предварительного просмотра после обновления:",
//             modifiablePreviewData
//           );

//           // 10. Обновление preview документа
//           const objectSize = new Blob([JSON.stringify(modifiablePreviewData)])
//             .size;
//           const totalCurrentSize = previewDataArray.reduce(
//             (acc, item) => acc + new Blob([JSON.stringify(item)]).size,
//             0
//           );
//           const newTotalSize = totalCurrentSize + objectSize;
//           const maxSize = 1024 * 1024; // 1 MB

//           let idDocumentToSave;
//           let newDocumentSize;

//           if (newTotalSize <= maxSize) {
//             previewDataArray.push(modifiablePreviewData);
//             newDocumentSize = newTotalSize;
//             idDocumentToSave = idPreviewDocument;
//           } else {
//             const newOriginalDocRef = doc(
//               collection(db, ...previewCollectionPath.split("."))
//             );
//             idDocumentToSave = newOriginalDocRef.id;
//             modifiablePreviewData.idPreviewDocument = idDocumentToSave;
//             newDocumentSize = objectSize;
//             previewDataArray = [modifiablePreviewData];
//           }

//           const originalDocRef = doc(
//             collection(db, ...previewCollectionPath.split(".")),
//             idDocumentToSave
//           );
//           await setDoc(
//             originalDocRef,
//             { data: previewDataArray, documentSize: newDocumentSize },
//             { merge: true }
//           );
//           console.log(
//             "Обновление preview документа завершено:",
//             originalDocRef.path
//           );

//           // 12. Обновление lastUpdatedDocument в metadataDocumentPath
//           const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
//           await setDoc(
//             languageDocRef,
//             {
//               lastUpdatedDocument: {
//                 lastUpdatedDocumentId: idDocumentToSave,
//                 documentSize: newDocumentSize,
//               },
//             },
//             { merge: true }
//           );
//           console.log(
//             "Обновление lastUpdatedDocument в metadata завершено:",
//             languageDocRef.path
//           );
//         }

//         // Формируем payload для return
//         const resultPayload = {
//           idRootDocument,
//           rootCollectionPath,
//           previewCollectionPath,
//           previewGeneralCollectionPath,
//           rootCurrentDocumentState,
//           previewCurrentDocumenState,
//           previewGeneralDocumenState,
//           loadingStateName,
//           errorStateName,
//         };

//         if (rootData) {
//           resultPayload.rootDocumentData = rootData;
//         }

//         if (previewData) {
//           resultPayload.previewDocumentData = previewData;
//         }

//         return resultPayload;
//       } catch (error) {
//         console.error(`Ошибка при попытке обновления: ${attempt + 1}:`, error);

//         if (RETRYABLE_ERRORS.includes(error.code)) {
//           attempt++;
//           console.log(
//             `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
//           );
//           if (attempt >= MAX_RETRIES) {
//             const errorMessage = interpretErrorV4(error.code);
//             return rejectWithValue({ errorMessage });
//           }
//         } else {
//           const errorMessage = interpretErrorV4(error.code);
//           return rejectWithValue({ errorMessage });
//         }
//       }
//     }
//   }
// );
