import React, { useEffect, useState } from "react";
import InputText from "../WidgetsProfile/Inputs/InputText";

const WidgetOrderSearchUserV4 = ({ setEmail }) => {
  const [emailSearchedUser, setEmailSearchedUser] = useState("");

  useEffect(() => {
    if (typeof setEmail === "function") {
      setEmail(emailSearchedUser);
    }
  }, [setEmail, emailSearchedUser]);

  return (
    <InputText
      type="text"
      label="Enter email client"
      value={emailSearchedUser}
      onChange={(event) => setEmailSearchedUser(event.target.value)}
    />
  );
};

export default WidgetOrderSearchUserV4;
