import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointModeration from "./WindowRentalPointModeration";

const WidgetRentalPointModeration = ({
  mode,
  currentRentalPoint,
  comments,
}) => {
  // console.log("currentRentalPoint", currentRentalPoint.rentalPointStatus);

  const [windowRentalPointModeration, setWindowRentalPointModeration] =
    useState(false);
  const [latestComment, setLatestComment] = useState(null);
  const handleOpenWindowRentalPointModeration = () => {
    setWindowRentalPointModeration(true);
  };

  const handleClose = () => {
    setWindowRentalPointModeration(false);
  };

  useEffect(() => {
    const latestComment = comments?.reduce((latest, current) => {
      return current.timestamp > (latest?.timestamp || 0) ? current : latest;
    }, null);

    setLatestComment(latestComment);
  }, [comments]);
  return (
    mode === "moderation" && (
      <>
        {/* POPUP WINDOW */}
        <PopupDirrectory
          isOpen={windowRentalPointModeration}
          onClose={handleClose}
          title="Rental point inform"
        >
          <WindowRentalPointModeration mode={mode} onClose={handleClose} />
        </PopupDirrectory>
        {/* POPUP WINDOW */}

        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title={
            currentRentalPoint?.rentalPointStatus?.currentStatus ===
            "moderation"
              ? "Moderation"
              : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                "approved"
              ? "Approved"
              : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                  "banned" && "Banned"
          }
          description={
            latestComment?.content ||
            (currentRentalPoint?.rentalPointStatus?.currentStatus ===
            "moderation"
              ? "Moderation Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                "approved"
              ? "Approved Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              : currentRentalPoint?.rentalPointStatus?.currentStatus ===
                  "banned" &&
                "Banned Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")
          }
          onClick={handleOpenWindowRentalPointModeration}
        />
      </>
    )
  );
};

export default WidgetRentalPointModeration;
