import React, { useState } from "react";
import { formatCurrencyUSD } from "../../../../../../functions/currencyFormatter";
import CarouselMenu from "../Carousel/CarouselMenu";

const getMonthName = (monthNumber) => {
  const date = new Date(2024, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const Rate = ({
  setSelectedMonth,
  rate,
  startBookingDates,
  endBookingDates,
  title,
  description,
  activator,
}) => {
  const startDate = new Date(startBookingDates);
  const endDate = new Date(endBookingDates);

  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const maxPrice = Math.max(...rate.map((rateItem) => rateItem.price));
  const calculateHeight = (price) => (price / maxPrice) * 40;

  const isMonthInRange = (monthIndex) => {
    if (startYear === endYear) {
      return monthIndex >= startMonth && monthIndex <= endMonth;
    }
    if (startYear < endYear) {
      return (
        (monthIndex >= startMonth && startYear === 2024) || // Диапазон конца 2024
        (monthIndex <= endMonth && endYear === 2025) // Диапазон начала 2025
      );
    }
    return false;
  };

  const shouldHighlightMonth = (index) => {
    const isDecember2024 =
      new Date().getMonth() === 11 && new Date().getFullYear() === 2024;
    const isJanuary2025 = index === 0; // Январь 2025 — первый элемент массива
    return monthsInRangeCount > 2 && isDecember2024 && isJanuary2025;
  };

  const getMonthsInRange = (start, end) => {
    const months = (end.getFullYear() - start.getFullYear()) * 12;
    return months - start.getMonth() + end.getMonth() + 1;
  };

  const monthsInRangeCount = getMonthsInRange(startDate, endDate);

  const [selectedMonth, setSelectedMonthState] = useState(null);

  const handleMonthClick = (index) => {
    setSelectedMonthState(index);

    const selectedItems = rate.slice(index, index + monthsInRangeCount);
    const hasZeroPrice = selectedItems.some((item) => item.price === 0);
    if (hasZeroPrice) {
      console.log("Устанавливаем selectedMonth в null из-за price === 0");
      activator(false);
    } else {
      console.log("Устанавливаем selectedMonth в выбранные элементы");
      activator(true);
    }
    setSelectedMonth((prev) => (prev === index ? null : index));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      {title && <h5>{title}</h5>}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {rate?.map((rateItem, index) => {
          const isInRange = isMonthInRange(index);

          const backgroundColor = shouldHighlightMonth(index)
            ? "var(--color-success)" // Зеленый фон для января 2025
            : index === selectedMonth || isInRange
            ? rateItem.price === 0
              ? "var(--color-alarm)" // Красный фон для выбранного месяца с ценой 0
              : "var(--color-success)" // Зеленый фон для выбранного месяца с ценой > 0
            : "var(--color-gray-100)"; // Серый фон по умолчанию

          return (
            <div key={index} style={{ width: "100%" }}>
              <div
                onClick={() => handleMonthClick(index)}
                title={`${getMonthName(index)} — ${formatCurrencyUSD(
                  rateItem?.price
                )}`}
                style={{
                  height: `${calculateHeight(rateItem.price)}px`,
                  width: "100%",
                  borderRadius: "8px 8px 0 0",
                  backgroundColor: backgroundColor,
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "flex-end",
                  ...(rateItem?.price > 0
                    ? {
                        minHeight: "23px",
                        padding: "4px 4px",
                      }
                    : {
                        minHeight: "8px",
                        padding: "0",
                      }),
                  ...(rateItem?.price > 0 && { cursor: "pointer" }),
                }}
              >
                {/* {rateItem?.price > 0 &&
                  (isInRange || index === selectedMonth) && (
                    <p
                      className="p-light"
                      style={{
                        color: "var(--color-white)",
                        fontSize: "0.5em",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {formatCurrencyUSD(rateItem?.price)}
                    </p>
                  )} */}
              </div>

              <div
                style={{
                  height: "16px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <p
                  className="p-light"
                  style={{
                    color: "var(--color-gray-600)",
                    // transform: "rotate(-45deg)",
                    fontSize: "0.5em",
                  }}
                >
                  {getMonthName(index)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {description && (
        <div
          style={{
            width: "100%",
          }}
        >
          <p
            style={{
              width: "70%",
            }}
            className="p-light"
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Rate;
