import React, { useState, useEffect, useRef } from "react";
import "./counter.css";
import { truncateText } from "../../../../../../functions/truncateText";

const Counter = ({
  maxValue = 0,
  minValue = 0,
  value = 0,
  onChange,
  label,
  description,
  children,
  buttonStyle,
  type,
}) => {
  const [count, setCount] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newCount =
        maxValue !== undefined
          ? Math.min(prevCount + 1, maxValue)
          : prevCount + 1;
      if (onChange) onChange(newCount); // Вызываем onChange только если он передан
      return newCount;
    });
  };

  const handleDecrement = () => {
    setCount((prevCount) => {
      const newCount = Math.max(prevCount - 1, minValue);
      if (onChange) onChange(newCount); // Вызываем onChange только если он передан
      return newCount;
    });
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      const newCount =
        newValue >= minValue && (maxValue === undefined || newValue <= maxValue)
          ? newValue
          : newValue < minValue
          ? minValue
          : maxValue;
      setCount(newCount);
      if (onChange) onChange(newCount); // Вызываем onChange только если он передан
    } else {
      setCount(minValue);
      if (onChange) onChange(minValue); // Вызываем onChange только если он передан
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onChange) onChange(count); // Вызываем onChange при потере фокуса
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return type === "small" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "4px",
        // backgroundColor: "var(--color-white)",
        borderRadius: "80px",
      }}
    >
      <button
        onClick={handleDecrement}
        disabled={count <= minValue}
        style={{
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "80px 0 0 80px",
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "32px",
          cursor: "pointer",
          border: "0px",
        }}
      >
        <span className="material-symbols-outlined">remove</span>
      </button>

      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "32px",
          // width: "2ch",
        }}
      >
        <input
          // className="input-text--write"
          style={{
            backgroundColor: "var(--color-gray-100)",
            padding: "0 8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "32px",
            width: "2ch",
            border: "0",
            fontSize: "1rem",
            fontWeight: "500",
            appearance: "none",
            MozAppearance: "textfield",
          }}
          // type="number"
          id={label}
          name={label}
          placeholder={label}
          value={count}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          required={true}
          min={minValue}
        />
      </div>

      <button
        onClick={handleIncrement}
        disabled={maxValue !== undefined && count >= maxValue}
        style={{
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "0 80px 80px 0",
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "32px",
          cursor: "pointer",
          border: "0px",
        }}
      >
        <span className="material-symbols-outlined">add</span>
      </button>
    </div>
  ) : (
    <div className="counter__content-box">
      {children}
      <div className={`counter__controls-box ${children && "children "}`}>
        <button
          style={buttonStyle}
          className={`counter__quantity-button ${
            count === minValue ? "disable" : "active"
          }`}
          onClick={handleDecrement}
          disabled={count <= minValue}
        >
          <span className="material-symbols-outlined">remove</span>
        </button>

        <div className="input-text__content-box">
          <label
            style={{ textTransform: "capitalize" }}
            htmlFor={label}
            className={`input-text__label ${
              isFocused || label ? "input-text__label--up" : ""
            }`}
          >
            {label}
          </label>

          <input
            className="input-text--write"
            type="number"
            id={label}
            name={label}
            placeholder={label}
            value={count}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            required={true}
            min={minValue}
          />
        </div>

        <button
          style={buttonStyle}
          className={`counter__quantity-button ${
            count >= maxValue ? "disable" : "active"
          }`}
          onClick={handleIncrement}
          disabled={maxValue !== undefined && count >= maxValue}
        >
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      {description && (
        <div style={{ width: "100%" }}>
          <p style={{ width: "70%" }} className="p-light">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Counter;

// import React, { useState, useEffect, useRef } from "react";
// import "./counter.css";

// const Counter = ({
//   maxValue = 0,
//   minValue = 0,
//   value = 0,
//   onChange,
//   label,
//   description,
//   children,
// }) => {
//   const [count, setCount] = useState(value);
//   const [isFocused, setIsFocused] = useState(false);
//   const inputRef = useRef(null);

//   useEffect(() => {
//     setCount(value);
//   }, [value]);

//   const handleIncrement = () => {
//     setCount((prevCount) => {
//       const newCount =
//         maxValue !== undefined
//           ? Math.min(prevCount + 1, maxValue)
//           : prevCount + 1;
//       onChange(newCount);
//       return newCount;
//     });
//   };

//   const handleDecrement = () => {
//     setCount((prevCount) => {
//       const newCount = Math.max(prevCount - 1, minValue);
//       onChange(newCount);
//       return newCount;
//     });
//   };

//   const handleChange = (event) => {
//     const newValue = parseInt(event.target.value, 10);
//     if (!isNaN(newValue)) {
//       const newCount =
//         newValue >= minValue && (maxValue === undefined || newValue <= maxValue)
//           ? newValue
//           : newValue < minValue
//           ? minValue
//           : maxValue;
//       setCount(newCount);
//       onChange(newCount);
//     } else {
//       setCount(minValue);
//       onChange(minValue);
//     }
//   };

//   const handleFocus = () => {
//     setIsFocused(true);
//   };

//   const handleBlur = () => {
//     setIsFocused(false);
//   };

//   return (
//     <div className="counter__content-box">
//       {children}
//       <div className={`counter__controls-box ${children && "children "}`}>
//         <button
//           className={`counter__quantity-button ${
//             count === minValue ? "disable" : "active"
//           }`}
//           onClick={handleDecrement}
//           disabled={count <= minValue}
//         >
//           <span className="material-symbols-outlined">remove</span>
//         </button>

//         <div className="input-text__content-box">
//           <label
//             style={{ textTransform: "capitalize" }}
//             htmlFor={label}
//             className={`input-text__label ${
//               isFocused || label ? "input-text__label--up" : ""
//             }`}
//           >
//             {label}
//           </label>

//           <input
//             className="input-text--write"
//             type="number"
//             id={label}
//             name={label}
//             placeholder={label}
//             value={count}
//             onChange={handleChange}
//             onFocus={handleFocus}
//             onBlur={handleBlur}
//             ref={inputRef}
//             required={true}
//             min={minValue}
//           />
//         </div>

//         <button
//           className={`counter__quantity-button ${
//             count >= maxValue ? "disable" : "active"
//           }`}
//           onClick={handleIncrement}
//           disabled={maxValue !== undefined && count >= maxValue}
//         >
//           <span className="material-symbols-outlined">add</span>
//         </button>
//       </div>

//       {description && (
//         <div style={{ width: "100%" }}>
//           <p style={{ width: "70%" }} className="p-light">
//             {description}
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Counter;
