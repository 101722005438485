import React, { useEffect, useState } from "react";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointModeration from "./WindowRentalPointModeration";
import WindowBikeModerationV4 from "./WindowBikeModerationV4";

const WidgetBikeModerationV4 = ({ mode, currentBike, comments }) => {
  const [windowRentalPointModeration, setWindowRentalPointModeration] =
    useState(false);
  const [latestComment, setLatestComment] = useState("");

  useEffect(() => {
    const latestComment = comments?.reduce((latest, current) => {
      return current.timestamp > (latest?.timestamp || 0) ? current : latest;
    }, null);

    setLatestComment(latestComment);
  }, [comments]);

  const handleOpenWindowRentalPointModeration = () => {
    setWindowRentalPointModeration(true);
  };

  const handleClose = () => {
    setWindowRentalPointModeration(false);
  };

  return (
    mode === "moderation" && (
      <>
        {/* POPUP WINDOW */}
        <PopupDirrectory
          isOpen={windowRentalPointModeration}
          onClose={handleClose}
          title="Bike moderation"
        >
          <WindowBikeModerationV4 mode={mode} onClose={handleClose} />
        </PopupDirrectory>
        {/* POPUP WINDOW */}

        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title={
            currentBike?.bikeStatus?.currentStatus === "moderation"
              ? "Moderation"
              : currentBike?.bikeStatus?.currentStatus === "approved"
              ? "Approved"
              : currentBike?.bikeStatus?.currentStatus === "banned" && "Banned"
          }
          description={
            latestComment?.content ||
            (currentBike?.bikeStatus?.currentStatus === "moderation"
              ? "Moderation Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              : currentBike?.bikeStatus?.currentStatus === "approved"
              ? "Approved Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              : currentBike?.bikeStatus?.currentStatus === "banned" &&
                "Banned Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")
          }
          onClick={handleOpenWindowRentalPointModeration}
        />
      </>
    )
  );
};

export default WidgetBikeModerationV4;
