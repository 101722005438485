import React from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { v4 as uuidv4 } from "uuid";
import { truncateText } from "../../../../../functions/truncateText";
import { QRCodeCanvas } from "qrcode.react";
import Barcode from "../WidgetsProfile/Barcode/Barcode";
const formatDate = (date) => {
  const validDate = typeof date === "number" ? new Date(date) : date;
  return validDate instanceof Date && !isNaN(validDate)
    ? `${validDate.getDate()} ${validDate.toLocaleString("en-US", {
        month: "long",
      })} ${validDate.getFullYear()}`
    : "Date not selected";
};
const TotalItemsGroupedBikes = ({ groupItems, barcode }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: "8px",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        {barcode}
      </div>
      <div>
        <p className="p-light">
          TOTAL:{" "}
          {formatCurrencyUSD(
            groupItems?.reduce(
              (acc, item) =>
                acc +
                (item.totalCostBike + (item.totalCostAditioalServices || 0)),
              0
            )
          )}
        </p>
      </div>
    </div>
  );
};

const RentalPointData = ({ item }) => {
  console.log("item RentalPointData", item);
  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <div
        style={{
          gridColumn: "1",
          width: "100%",
          aspectRatio: "1/1",
          borderRadius: "80px",
          boxSizing: "border-box",
          overflow: "hidden",
          backgroundColor: "var(--color-gray-100)",
          backgroundImage: `url(${item?.rootRentalPointData?.logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div
        style={{
          gridColumn: "span 5",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <span>
          <h5>{item?.rootRentalPointData?.title}</h5>
          <p className="p-light">
            {item?.rootRentalPointData?.location?.pointAddress}
          </p>
        </span>
      </div>
    </div>
  );
};

const BikeItemData = ({
  item,
  index,
  groupItems,
  barcode,
  // onUpdateQuantity,
  // onRemoveItem,
}) => {
  const singleBikeCost = item?.totalCostBike / (item?.totals?.quantity || 1);
  const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
  const updatedTotalCost =
    updatedTotalCostBike + (item.totalCostAditioalServices || 0);

  return (
    <div
      style={{
        backgroundColor: "var(--color-gray-100)",
        borderRadius:
          groupItems.length === 1
            ? "16px 16px 0 0"
            : `${index === 0 ? "16px 16px 0 0" : ""} ${
                index === groupItems.length - 1 ? "0 0 16px 16px" : ""
              }`,
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "24px",
      }}
    >
      <div
        style={{
          height: "100%",
          gridColumn: "span 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "4px",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-gray-400)",
              }}
            >
              # {index + 1}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.brand?.title}
              </p>

              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.category?.title}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* Диапазон дат */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
                whiteSpace: "nowrap",
              }}
            >
              <p className="p-light">
                {formatDate(item?.totals?.dateInterval[0] || 0)} —
                {formatDate(item?.totals?.dateInterval[1] || 0)}
              </p>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />

              <p
                className="p-light"
                style={{
                  color: "var(--color-black)",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }}
              >
                {item?.totals?.daysInInterval || 0} day
                {item?.totals?.daysInInterval > 1 && "s"}
              </p>
            </div>

            {/* Название байка и его колличество */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <h5
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {truncateText(
                  item?.bikeData?.basicInformation?.title?.titleOriginal,
                  30
                )}
              </h5>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />

              <h6
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {item?.totals?.quantity || 1} pc
              </h6>
            </div>
          </div>

          {barcode}
        </div>
      </div>

      {item?.orderedAditionalServices?.length > 0 && (
        <div
          style={{
            gridColumn: "span 3",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {item?.orderedAditionalServices?.map((service, idx) => (
                <div
                  key={service?.id || idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateText(service?.title?.titleOriginal, 55)}
                    </p>
                    {/* <span
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                        className="material-symbols-outlined p-light"
                      >
                        close_small
                      </span>
                      <p
                        className="p-light"
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                      >
                        {service?.countOrderedServices}
                      </p> */}
                  </span>

                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--color-gray-200)",
                      height: "1px",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      color: "var(--color-black)",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {service?.countOrderedServices} pc
                  </p>
                </div>
              ))}
            </div>
            {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="p-light">TOTAL:</p>
                <h6>{formatCurrencyUSD(updatedTotalCost)}</h6>
              </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

const WidgetWoucherListV4 = ({ currentRentalPointOrders }) => {
  if (!currentRentalPointOrders || currentRentalPointOrders.length === 0) {
    return <div>No orders available</div>;
  }

  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <RentalPointData item={currentRentalPointOrders[0]} />

        {currentRentalPointOrders.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            {currentRentalPointOrders?.map((item, index) => (
              <div
                key={uuidv4()}
                style={{
                  width: "100%",
                }}
              >
                <BikeItemData
                  item={item}
                  index={index}
                  groupItems={item.bikeData}
                  barcode={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <QRCodeCanvas
                        value={item.id}
                        size={80}
                        fgColor="#000000"
                        bgColor="transparent"
                        level="H"
                      />
                      <Barcode barcodeElementId={item.id} />
                    </div>
                  }
                />
              </div>
            ))}

            <TotalItemsGroupedBikes groupItems={currentRentalPointOrders} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetWoucherListV4;
