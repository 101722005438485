import React, { useEffect, useRef, useState } from "react";
import WidgetLocationModeration from "./WidgetLocationModeration";
import WidgetRentalPointTimetable from "../RentalPointsV3/WidgetRentalPointTimetable";
import WidgetAditionalServices from "../RentalPointsV3/WidgetAditionalServices";
import WidgetRentalPointContact from "../RentalPointsV3/WidgetRentalPointContact";
import WidgetRentalPointBasicInformation from "../RentalPointsV3/WidgetRentalPointBasicInformation";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import WidgetRentalPointModeration from "./WidgetRentalPointModeration";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../WidgetsProfile/UploadFiles/WindgetUploadFilesV4";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { subscribePreviewDocumentsThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";
import { v4 as uuidv4 } from "uuid";
import { setCurrentPreviewData } from "../../../../../reducersToolkit/catalogSliceV4";
import { deleteDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/deleteDataThunkV4";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowBikeDetailModerationV4 from "./WindowBikeDetailModerationV4";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribeRootDocumentsThunkV4";

const WindowRentalPointDetailModerationV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);
  // ------- Windows stats
  const [windowNewBikeDetailModeration, setWindowNewBikeDetailModeration] =
    useState(false);

  // ------- Loading stats
  // ------- Buttons stats
  // ------- Widgets stats
  // ------- Files

  // ------- previewNewData
  // ------- rootNewData

  // ------- tempPreviewData
  const [
    user_moderation_selectedPreviewCurrentData,
    setUser_moderation_selectedPreviewCurrentData,
  ] = useState(null); // <WidgetUsersListModerationV4.js/> setCurrentPreviewData - выбранный пользователь

  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState(null); //setCurrentPreviewData <UIProfileV3.js/> - подписка на всех пользователей

  const [selectedUserIdRootDocument, setSelectedUserIdRootDocument] =
    useState(null);
  const [selectedUserIdPreviewDocument, setSelectedUserIdPreviewDocument] =
    useState(null);

  // const [
  //   idUserRootDocumentModeratorAdded,
  //   setIdUserRootDocumentModeratorAdded,
  // ] = useState(null);

  // const [idUserRootDocumentModeration, setIdUserRootDocumentModeration] =
  //   useState(null);

  // ------- tempRootData
  const [basicInformation, setBasicInformation] = useState({});
  const [location, setLocation] = useState({});
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [contacts, setContacts] = useState({});
  const [additionalServices, setAdditionalServices] = useState({});
  const [timetable, setTimetable] = useState([]);
  const [bikesList, setBikesList] = useState([]);

  // const [currentRentalPointRootData, setCurrentRentalPointRootData] = useState(
  //   {}
  // );
  const [rootRentalPointData, setRootRentalPointData] = useState(null);
  const [previewRentalPointData, setPreviewRentalPointData] = useState(null);
  console.log(
    "previewRentalPointData",
    previewRentalPointData,
    rootRentalPointData
  );
  const [previewLogoFiles, setPreviewLogoFiles] = useState([]);
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  // ------- tempData
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);
  const [idPreviewDocumentRenyalPoint, setidPreviewDocumentRentalPoint] =
    useState(null);
  // ------- loading
  const [loading_create_uploadLogoFiles, setLoading_create_UploadLogoFiles] =
    useState(false);
  const [loading_create_uploadPhotoFiles, setLoading_create_uploadPhotoFiles] =
    useState(false);
  // ------- error
  // ------- localStates
  const [currentUserData, setCurrentUserData] = useState(null);
  // const [idUserInCurrentRentalPoint, setIdUserInCurrentRentalPoint] =
  //   useState(null);
  // const [previewUserNewData, setPreviewUserNewData] = useState(null);
  const [newLogoFiles, setNewLogoFiles] = useState([]);
  const [newLogoPhotoFiles, setNewLogoPhotoFiles] = useState(false);
  const [bikeState, setBikeState] = useState("moderation");
  const [commentsModerator, setCommentsModerator] = useState(null);
  /**
   *
   * Наполнение useState
   *
   */

  useEffect(() => {
    const targetUser = general_usersPreviewNotifications?.find(
      (user) =>
        user.idRootDocument ===
        user_moderation_selectedPreviewCurrentData?.idRootDocument
    );
    setCurrentUserData(targetUser);
  }, [
    user_moderation_selectedPreviewCurrentData?.idRootDocument,
    general_usersPreviewNotifications,
  ]);

  useEffect(() => {
    setBikesList(tempPreviewData?.subscribe_bikesPreview || []);

    setUser_moderation_selectedPreviewCurrentData(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
    );
  }, [
    tempPreviewData?.subscribe_bikesPreview,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
  ]);

  useEffect(() => {
    setIdRootDocumentRentalPoint(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.idRootDocument ||
        null
    );
    setidPreviewDocumentRentalPoint(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.idPreviewDocument ||
        null
    );
    if (mode === "moderation") {
      setSelectedUserIdRootDocument(
        tempData?.rentalPoint_moderation_selectedRentalPoint
          ?.idRootDocumentUser || null
      );
    }
  }, [tempData?.rentalPoint_moderation_selectedRentalPoint, mode]);

  useEffect(() => {
    setLoading_create_UploadLogoFiles(
      loading?.loading_create_uploadLogoFiles || false
    );
    setLoading_create_uploadPhotoFiles(
      loading?.loading_create_uploadPhotoFiles || false
    );
  }, [
    loading?.loading_create_uploadLogoFiles,
    loading?.loading_create_uploadPhotoFiles,
  ]);

  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );
    if (mode === "moderatorAdded") {
      setSelectedUserIdRootDocument(
        tempPreviewData?.user_moderation_selectedPreviewCurrentData
          ?.idRootDocument || null
      );
    }
    setSelectedUserIdPreviewDocument(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.iPreviewDocument || null
    );
  }, [
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.general_usersPreviewNotifications,
    mode,
  ]);

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setPreviewLogoFiles(
        tempRootData?.rentalPoint_create_createRerntalPoint?.files?.logoFiles ||
          []
      );
      setPreviewPhotoFiles(
        tempRootData?.rentalPoint_create_createRerntalPoint?.files
          ?.photoFiles || []
      );
      setBasicInformation(
        tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation ||
          {}
      );
      setLocation(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location || {}
      );
      setCountry(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location
          ?.country || ""
      );
      setCity(
        tempRootData?.rentalPoint_create_createRerntalPoint?.location?.city ||
          ""
      );
      setContacts(
        tempRootData?.rentalPoint_create_createRerntalPoint?.contacts || {}
      );
      setAdditionalServices(
        tempRootData?.rentalPoint_create_createRerntalPoint
          ?.additionalServices || {}
      );
      setTimetable(
        tempRootData?.rentalPoint_create_createRerntalPoint?.timetable || []
      );
      // setCurrentRentalPointRootData(
      //   tempRootData?.rentalPoint_create_createRerntalPoint
      // );
      setRootRentalPointData(
        tempRootData?.rentalPoint_create_createRerntalPoint || null
      );
      setCommentsModerator(
        tempRootData?.rentalPoint_create_createRerntalPoint?.rentalPointStatus
          ?.comments || null
      );
    } else if (mode === "moderation") {
      setPreviewLogoFiles(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.files
          ?.logoFiles || []
      );
      setPreviewPhotoFiles(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.files
          ?.photoFiles || []
      );
      setBasicInformation(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint
          ?.basicInformation || {}
      );
      setLocation(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location || {}
      );
      setCountry(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location
          ?.country || ""
      );
      setCity(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location?.city ||
          ""
      );
      setContacts(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.contacts || {}
      );
      setAdditionalServices(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint
          ?.additionalServices || {}
      );
      setTimetable(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.timetable || []
      );
      // setCurrentRentalPointRootData(
      //   tempRootData?.rentalPoint_moderation_rootRerntalPoint
      // );
      setRootRentalPointData(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint || null
      );
      setCommentsModerator(
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.rentalPointStatus
          ?.comments || null
      );
    }
  }, [
    mode,
    city,
    country,
    tempRootData?.rentalPoint_create_createRerntalPoint,
    tempRootData?.rentalPoint_moderation_rootRerntalPoint,
  ]);

  /**
   *
   * Формирование объектов Root и Preview
   *
   */

  // RentalPoint
  useEffect(() => {
    if (mode === "moderatorAdded") {
      const logoFiles =
        tempRootData?.rentalPoint_create_createRerntalPoint?.files?.logoFiles ||
        [];

      const iconUrlObject =
        logoFiles.find((file) => file.priority === true) || logoFiles[0];

      const iconUrl = iconUrlObject ? iconUrlObject.url : null;

      setPreviewRentalPointData({
        title:
          tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation
            .title?.titleOriginal,
        description:
          tempRootData?.rentalPoint_create_createRerntalPoint?.basicInformation
            .description?.descriptionOriginal,
        pointAddress:
          tempRootData?.rentalPoint_create_createRerntalPoint?.location
            ?.pointAddress,
        iconUrl: iconUrl,
        rentalPointStatus:
          tempRootData?.rentalPoint_create_createRerntalPoint?.rentalPointStatus
            ?.currentStatus,
        idRootDocumentUser: selectedUserIdRootDocument,
        country: country,
        city: city,
      });
    } else if (mode === "moderation") {
      const logoFiles =
        tempRootData?.rentalPoint_moderation_rootRerntalPoint?.files
          ?.logoFiles || [];

      const iconUrlObject =
        logoFiles.find((file) => file.priority === true) || logoFiles[0];

      const iconUrl = iconUrlObject ? iconUrlObject.url : null;

      setPreviewRentalPointData({
        title:
          tempRootData?.rentalPoint_moderation_rootRerntalPoint
            ?.basicInformation?.title?.titleOriginal,
        description:
          tempRootData?.rentalPoint_moderation_rootRerntalPoint
            ?.basicInformation?.description.descriptionOriginal,
        pointAddress:
          tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location
            ?.pointAddress,
        iconUrl: iconUrl,
        rentalPointStatus:
          tempRootData?.rentalPoint_moderation_rootRerntalPoint
            ?.rentalPointStatus?.currentStatus,
        country: country,
        city: city,
        idRootDocumentUser: selectedUserIdRootDocument,
      });
    }
  }, [
    mode,
    city,
    country,
    tempRootData?.rentalPoint_create_createRerntalPoint,
    tempRootData?.rentalPoint_moderation_rootRerntalPoint,
    selectedUserIdRootDocument,
  ]);

  /**
   *
   * Fetch функции
   *
   */
  useEffect(() => {
    if (mode === "moderation" && idRootDocumentRentalPoint) {
      console.log(
        `Выполняем подписку на документ: ${idRootDocumentRentalPoint}`
      );

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "rentalPoints",
              rootCurrentDocumenState:
                "rentalPoint_moderation_rootRerntalPoint",
              idRootDocument: idRootDocumentRentalPoint,
              loadingStateName: "loading_bikes",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписываемся при размонтировании компонента или изменении `idRootDocumentBike`
      return () => {
        unsubscribeRootDocument(idRootDocumentRentalPoint);
      };
    }
  }, [dispatch, mode, idRootDocumentRentalPoint]);

  useEffect(() => {
    if (mode === "moderation") {
      // Если есть selectedRootRentalPomtId страны, выполняем запрос для получения данных для детализации
      if (idRootDocumentRentalPoint) {
        console.log(
          `Есть idRootDocumentRentalPoint: ${idRootDocumentRentalPoint}, выполняем запрос для получения данных для детализации`
        );

        // dispatch(
        //   fetchRootDataThunkV4({
        //     idRootDocument: idRootDocumentRentalPoint,
        //     // Путь до коллекции указывается из НЕ четного колличества вложенностей
        //     rootCollectionPath: "rentalPoints",
        //     // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
        //     rootCurrentDocumentState: "rentalPoint_moderation_rootRerntalPoint",
        //     // Название state который будет помогать отслежывать результаты работы thunk
        //     loadingStateName: "loading_moderation_rootRerntalPoint",
        //     errorStateName: "currentError",
        //   })
        // );

        let unsubscribe; // Объявляем переменную для хранения функции отписки

        // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
        const subscribe = async () => {
          try {
            const result = await dispatch(
              subscribePreviewDocumentsThunkV4({
                collectionPath: `manager_bikesPreview.userIds.${selectedUserIdRootDocument}.reintalPointIds.${idRootDocumentRentalPoint}`,
                previewCurrentDocumenState: "subscribe_bikesPreview",
                excludedIds: ["metadata"],
                loadingStateName: "loading_subscribe_bikesPreview",
                errorStateName: "currentError",
              })
            ).unwrap();

            // Проверяем, что функция отписки определена
            unsubscribe = result?.unsubscribe;
            if (typeof unsubscribe === "function") {
              console.log("Функция отписки установлена успешно");
            } else {
              console.error("Не удалось получить функцию отписки");
            }
          } catch (error) {
            console.error("Ошибка при подписке:", error);
          }
        };

        subscribe(); // Вызываем функцию подписки

        // Отписываемся при размонтировании компонента
        return () => {
          if (typeof unsubscribe === "function") {
            unsubscribe();
            console.log("Отписка выполнена успешно");
          }
        };
      }
    } else if (mode === "new") {
    }
  }, [dispatch, idRootDocumentRentalPoint, mode, selectedUserIdRootDocument]);

  /**
   *
   * CRUD функции
   *
   */

  const updateRentalPoint = () => {
    dispatch(
      updateDataThunkV4({
        // Указываем id preview документа который будем менять
        // idPreviewDocument: idPreviewDocumentRenyalPoint,
        // Указываем id root Элемента
        idRootDocument: idRootDocumentRentalPoint,
        // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
        // updatedData: rootCountryData,

        rootData: rootRentalPointData,
        previewData: previewRentalPointData,

        // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
        newFiles: {
          // или другие массивы файлов, если есть
          logoFiles: newLogoFiles,
          photoFiles: newLogoPhotoFiles,
        },
        // Имя того массива файлов который будет использоваться для назначения иконки
        iconFields: "logoFiles",
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: `rentalPoints`,
        previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}`,
        previewGeneralCollectionPath: "general_rentalPointsPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: `manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}.metadata`,
        metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
        /*************/
        // Сохраняет в state ROOT ОБЪЕКТ как результат выполнения thunk
        // rootCurrentDocumentState: "manager_rentalPointsRoot_create",
        rootCurrentDocumentState: "rentalPoint_moderation_rootRerntalPoint",
        // Сохраняет в state PREVIEW МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "manager_rentalPointsPrevirew_create",
        previewGeneralDocumenState: "general_rentalPointsPreview",
        // Эти состояния будут отображать состояния загрузки и ошибки
        /*************/
        loadingStateName: "loading_manager_rentalPointsPrevirew_create",
        errorStateName: "currentError",
      })
    );
  };

  const addRentalPoint = () => {
    dispatch(
      createDataThunkV4({
        // Данные которые будут использоваться для создания коллекции в firestore
        // data: rootCountryData,
        rootData: rootRentalPointData,
        previewData: previewRentalPointData,
        files: {
          // или другие массивы файлов, если есть
          logoFiles: newLogoFiles,
          photoFiles: newLogoPhotoFiles,
        },
        // Имя того массива файлов который будет использоваться для назначения иконки
        iconFields: "logoFiles",
        // Определяем пути для коллекций и метаданных с точками в качестве разделителей
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: `rentalPoints`,
        previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}`,
        previewGeneralCollectionPath: "general_rentalPointsPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: `manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}.metadata`,
        metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
        // Сохраняет в state ROOT ОБЪЕКТ как результат выполнения thunk
        // rootCurrentDocumentState: "manager_rentalPointsRoot_create",
        rootCurrentDocumentState: "rentalPoint_moderation_rootRerntalPoint",
        // Сохраняет в state PREVIEW МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "manager_rentalPointsPrevirew_create",
        // Название state который будет помогать отслежывать результаты работы thunk
        previewGeneralDocumenState: "general_rentalPointsPreview",
        loadingStateName: "loading_manager_rentalPointsPrevirew_create",
        errorStateName: "currentError",
      })
    )
      .unwrap()
      .then((response) => {
        console.log("rentalPoints успешно создан:", response);
      })
      .catch((error) => {
        console.error("Ошибка при создании rentalPoints:", error);
      });
  };

  const deleteRentalPoint = () => {
    dispatch(
      deleteDataThunkV4({
        // Id документов у которых поле remove будет обновлено
        rootData: rootRentalPointData,
        previewData: previewRentalPointData,
        // Определение путей коллекций
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: `rentalPoints`,
        previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}`,
        previewGeneralCollectionPath: "general_rentalPointsPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: `general_rentalPointsPreview.userIds.${selectedUserIdRootDocument}.metadata`,
        metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
        // Название state который будет содержать массив как результат работы thunk
        // previewCurrentDocumenState: "brandsPreview_deleteBrand",
        // Название state который будет помогать отслежывать результаты работы thunk
        previewGeneralDocumenState: "general_rentalPointsPreview",
        loadingStateName: "loading_deleteRentalPoint",
        errorStateName: "currentError",
      })
    );
  };

  // const handleCurrenPreviewUser = (userItem) => {
  //   dispatch(
  //     setCurrentPreviewData({
  //       currentPreviewDocumenState:
  //         "user_moderation_selectedPreviewCurrentData",
  //       data: userItem,
  //     })
  //   );
  // };

  // const updateCounterUser = async () => {
  //   try {
  //     if (selectedUserIdRootDocument) {
  //       dispatch(
  //         updateDataThunkV4({
  //           // Указываем id preview документа который будем менять
  //           idPreviewDocument: selectedUserIdPreviewDocument,
  //           // Указываем id root

  //           idRootDocument: selectedUserIdRootDocument,
  //           // Данные для обновления, как правило это объект схожий по структуре с документом в firestore

  //           // rootData: newRootData,
  //           previewData: previewUserNewData,

  //           // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
  //           // newFiles: {
  //           //   iconFiles: newPhotoFiles,
  //           //   /* Другие массивы файлов. Массивов файлов может быть несколько*/
  //           // },

  //           // Имя того массива файлов который будет использоваться для назначения иконки
  //           // iconFields: "iconFiles",

  //           // Путь до коллекции указывается из НЕ четного колличества вложенностей

  //           rootCollectionPath: "users",
  //           previewCollectionPath: "usersPreview",

  //           // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
  //           metadataDocumentPath: "usersPreview.metadata",

  //           /*************/
  //           // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
  //           // rootCurrentDocumentState: "",

  //           // Сохраняет в state МАССИВ объектов как результат выполнения thunk
  //           // previewCurrentDocumenState: "",
  //           // Эти состояния будут отображать состояния загрузки и ошибки
  //           /*************/

  //           loadingStateName: "loading_updateUserData",
  //           errorStateName: "currentError",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Ошибка при создании или обновлении:", error);
  //   }
  // };

  /**
   *
   * Логика компонента
   *
   */

  const handleUpdateRentalPoint = () => {
    updateRentalPoint();
    // updateCounterUser();
    // handleCurrenPreviewUser(previewUserNewData);
    onClose();
  };

  const handleAddRentalPoint = () => {
    addRentalPoint();
    // updateCounterUser();
    onClose();
  };

  const handleClose = () => {
    setWindowNewBikeDetailModeration(false);
  };

  const handleToggleNewBike = () => {
    setWindowNewBikeDetailModeration(true);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowNewBikeDetailModeration}
        onClose={handleClose}
        title="Add new bike moderation"
      >
        <WindowBikeDetailModerationV4
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        {/* ------------- Basic information ------------- */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <ContentBox position={mode === "moderation" ? "one" : "start"}>
            <WidgetRentalPointBasicInformation
              mode={mode}
              basicInformation={basicInformation}
            />
          </ContentBox>
          {/* ------------- Moderation ------------- */}
          {mode === "moderation" && (
            <ContentBox position="start">
              <WidgetRentalPointModeration
                mode={mode}
                currentRentalPoint={rootRentalPointData}
                comments={commentsModerator}
              />
            </ContentBox>
          )}
        </div>
        {/* ------------- Files logo ------------- */}
        <ContentBox>
          <WidgetListFileV4
            isLoading={loading_create_uploadLogoFiles}
            // Передаём данные для отображения
            viewFiles={previewLogoFiles}
            // Поле в котором находится массив с файлами
            fieldName="files.logoFiles"
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath="rentalPoints"
            previewCollectionPath={`manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}`}
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath={`manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}.metadata`}
            // Редактируемые документы. Если создётся новый документ значения могуть быть null
            idPreviewDocument={idPreviewDocumentRenyalPoint}
            idRootDocument={idRootDocumentRentalPoint}
            // Динамические состояния загрузки
            loadingStateName="loading_create_uploadLogoFiles"
            errorStateName="currentError"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            // rootCurrentDocumentState="manager_rentalPointsRoot"
            rootCurrentDocumentState="rentalPoint_moderation_rootRerntalPoint"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="manager_rentalPointsPrevirew"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={loading_create_uploadLogoFiles}
            id={uuidv4()}
            title="Add lofo files"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewLogoFiles}
          />
        </ContentBox>
        {/* ------------- Files photo ------------- */}
        <ContentBox>
          <WidgetListFileV4
            isLoading={loading_create_uploadPhotoFiles}
            // Передаём данные для отображения
            viewFiles={previewPhotoFiles}
            // Поле в котором находится массив с файлами
            fieldName="files.photoFiles"
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath="rentalPoints"
            previewCollectionPath={`manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}`}
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath={`manager_rentalPointsPrevirew.userIds.${selectedUserIdRootDocument}.metadata`}
            // Редактируемые документы. Если создётся новый документ значения могуть быть null
            idPreviewDocument={idPreviewDocumentRenyalPoint}
            idRootDocument={idRootDocumentRentalPoint}
            // Динамические состояния загрузки
            loadingStateName="loading_create_uploadPhotoFiles"
            errorStateName="currentError"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            // rootCurrentDocumentState="manager_rentalPointsRoot"
            rootCurrentDocumentState="rentalPoint_moderation_rootRerntalPoint"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="manager_rentalPointsPrevirew"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={loading_create_uploadPhotoFiles}
            id={uuidv4()}
            title="Add photo files"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewLogoPhotoFiles}
          />
        </ContentBox>
        {/* ------------- location ------------- */}
        <ContentBox>
          <WidgetLocationModeration
            location={location}
            ancorId="ancor1"
            mode={mode}
          />
        </ContentBox>
        {/* ------------- Bikes ------------- */}
        {mode !== "moderatorAdded" && (
          <ContentBox>
            <AddData
              icon={<span className="material-symbols-outlined">add</span>}
              title="Add bike"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              onClick={handleToggleNewBike}
            />
            {bikesList.length > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <SegmentedControls
                  options={["moderation", "approved", "banned"]}
                  onSelect="moderation"
                  selected={setBikeState}
                />
                <WidgetBikesList
                  bikesList={bikesList}
                  mode={mode}
                  bikeState={bikeState}
                />
              </div>
            )}
          </ContentBox>
        )}
        {/* ------------- Timetable ------------- */}
        <ContentBox>
          <WidgetRentalPointTimetable mode={mode} timetable={timetable} />
        </ContentBox>
        {/* ------------- Contact ------------- */}
        <ContentBox>
          <WidgetRentalPointContact mode={mode} contacts={contacts} />
        </ContentBox>
        {/* ------------- Aditional services ------------- */}
        <ContentBox>
          <WidgetAditionalServices
            mode={mode}
            additionalServices={additionalServices}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />
          {mode === "moderation" ? (
            <Button
              type="small"
              label="Update"
              color="--color-primary"
              active={true}
              onClick={() => handleUpdateRentalPoint()}
              allert={
                <div
                  style={{
                    padding: "24px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <h1>
                    After approval of this rental point, users will be able to
                    fulfill orders.
                  </h1>
                  <p className="p-light" style={{ width: "70%" }}>
                    You can always change the status of the rental point in case
                    of violation of any conditions or customer complaints.
                  </p>
                </div>
              }
            />
          ) : (
            <Button
              type="small"
              label="Create rental point"
              color="--color-primary"
              active={true}
              onClick={() => handleAddRentalPoint("new")}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Are you sure you want to add Rental Point?</h1>
                </div>
              }
            />
          )}
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPointDetailModerationV4;
