import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ContentBox from "../ContentBox/ContentBox";

import Button from "../Buttons/Button";
import { setTempData } from "../../../../../../reducersToolkit/catalogSliceV4";
import { useDispatch, useSelector } from "react-redux";
import ButtonsBar from "../ButtonsBar/ButtonsBar";

const WindowCalendarV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();
  const { tempData } = useSelector((state) => state.catalogSliceV4);
  const [dates, setDates] = useState([]);
  const [processedDates, setProcessedDates] = useState([]);

  // Инициализация данных из tempData.manage_fdiltersBikes.dateInterval
  useEffect(() => {
    const dateInterval = tempData?.manage_fdiltersBikes?.dateInterval;
    if (Array.isArray(dateInterval) && dateInterval.length === 2) {
      setDates([new Date(dateInterval[0]), new Date(dateInterval[1])]);
    }
  }, [tempData]);

  useEffect(() => {
    if (dates.length === 2) {
      // Устанавливаем начало и конец дня для выбранных дат
      const start = new Date(dates[0]);
      start.setHours(0, 0, 0, 0);

      const end = new Date(dates[1]);
      end.setHours(23, 59, 59, 999);

      setProcessedDates([start, end]);
    }
  }, [dates]);

  const handleApplyButton = () => {
    if (processedDates.length === 2 && processedDates[0] && processedDates[1]) {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "manage_fdiltersBikes.dateInterval",
          data: [processedDates[0].getTime(), processedDates[1].getTime()],
        })
      );
      onClose();
    } else {
      console.error("Processed dates array does not contain enough elements");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <DatePicker
          // Дата, которая будет выбрана изначально
          selected={dates[0]}
          // Обработчик изменений
          onChange={(dateRange) => {
            setDates(dateRange);
          }}
          // Начальная дата диапазона
          startDate={dates[0]}
          // Конечная дата диапазона
          endDate={dates[1]}
          // Включает выбор диапазона дат
          selectsRange
          // Показывает компонент встраиваемым
          inline
          // Количество месяцев, отображаемых одновременно в календаре
          monthsShown={1}
          // Минимальная дата (текущая)
          minDate={new Date()}
        />
      </ContentBox>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel selections"
            onClick={onClose}
            active={true}
          />
          <Button
            type="small"
            color="--color-primary"
            label="Apply"
            active={true}
            onClick={handleApplyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowCalendarV4;

// import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
// import ContentBox from "../ContentBox/ContentBox";
// import "./date.css";
// import Button from "../Buttons/Button";
// import { setBookingDates } from "../../../../../../reducersToolkit/orderSliceV2";
// import { useDispatch, useSelector } from "react-redux";
// import ButtonsBar from "../ButtonsBar/ButtonsBar";
// import { setCatalogBookingDates } from "../../../../../../reducersToolkit/catalogSliceV2";
// import Rate from "../Rate/RateV4";

// const WindowCalendar = ({ mode, onClose }) => {
//   const dispatch = useDispatch();

//   const { tempData, tempPreviewData, tempRootData } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   // const { isLoading, newOrder, selectedOrder } = useSelector(
//   //   (state) => state.catalogSliceV4
//   // );

//   const [dates, setDates] = useState([]);
//   // Bikes Data
//   const [rate, setRate] = useState([]);
//   // Ordered Bike
//   const [orderedBike, setOrderedBike] = useState(null);
//   const [startBookingDates, setStartBookingDates] = useState("");
//   const [endBookingDates, setEndBookingDates] = useState("");
//   const [selectedMonth, setSelectedMonth] = useState(null);
//   console.log("orderedBike_rootData 600", selectedMonth);
//   const [allowedMonths, setAllowedMonths] = useState([]);
//   /**
//    *
//    * Наполнение useState
//    *
//    */

//   useEffect(() => {
//     setRate(orderedBike?.rate || []);
//   }, [orderedBike?.rate]);
//   // Bike
//   useEffect(() => {
//     setOrderedBike(tempData?.bike_moderation_orderedBike || null);
//   }, [tempData?.bike_moderation_orderedBike]);

//   // Динамическое формирование allowedMonths
//   useEffect(() => {
//     const monthsWithPrice = rate
//       .filter((item) => item.price !== 0) // Фильтруем только те элементы, где price !== 0
//       .map((item) => item.month); // Получаем массив месяцев
//     setAllowedMonths(monthsWithPrice);
//   }, [rate]);

//   // Устанавливаем начальную дату на основе selectedMonth
//   useEffect(() => {
//     if (selectedMonth !== undefined) {
//       const currentDate = new Date();
//       currentDate.setMonth(selectedMonth); // Устанавливаем месяц
//       setDates([currentDate]); // Устанавливаем начальную дату
//     }
//   }, [selectedMonth]);

//   // Функция фильтрации дат
//   const isMonthAllowed = (date) => {
//     const month = date.getMonth(); // Получаем месяц из даты
//     return allowedMonths.includes(month); // Проверяем, входит ли месяц в список разрешенных
//   };

//   // Эффект для обновления dates когда defaultDateInterval изменяется
//   useEffect(() => {
//     if (mode === "new") {
//       const startDate = new Date();
//       const endDate = new Date(startDate);
//       endDate.setDate(startDate.getDate() + 7);
//       if (
//         orderedBike?.bookingDates?.startDay &&
//         orderedBike?.bookingDates?.endDay
//       ) {
//         setDates([
//           new Date(orderedBike?.bookingDates?.startDay || 0),
//           new Date(orderedBike?.bookingDates?.endDay || 0),
//         ]);
//       } else {
//         setDates([startDate, endDate]);
//       }
//     }
//   }, [mode, orderedBike]);

//   const handleBookingDates = () => {
//     if (dates.length >= 2) {
//       dispatch(
//         setBookingDates({
//           mode,
//           startDay: dates[0].getTime(),
//           endDay: dates[1].getTime(),
//         })
//       );

//       onClose();
//     } else {
//       console.error("Dates array does not contain enough elements");
//     }
//   };

//   const currentDate = new Date();
//   const currentYear = new Date().getFullYear();
//   const currentMonth = new Date().getMonth();
//   const targetYear =
//     selectedMonth !== null && selectedMonth < currentMonth
//       ? currentYear + 1
//       : currentYear;

//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: "8px",
//         paddingBottom: "72px",
//       }}
//     >
//       <ContentBox position="start">
//         <Rate
//           setSelectedMonth={setSelectedMonth}
//           rate={rate}
//           startBookingDates={startBookingDates}
//           endBookingDates={endBookingDates}
//         />
//       </ContentBox>
//       <ContentBox>
//         <DatePicker
//           selected={
//             selectedMonth !== null
//               ? new Date(
//                   selectedMonth < currentMonth ? currentYear + 1 : currentYear,
//                   selectedMonth,
//                   1
//                 )
//               : dates[0]
//           }
//           onChange={(date) => {
//             setDates(date); // вызов первой функции
//           }}
//           startDate={dates[0]}
//           endDate={dates[1]}
//           selectsRange
//           inline
//           monthsShown={1}
//           minDate={new Date()}
//           filterDate={isMonthAllowed}
//           openToDate={
//             selectedMonth !== null
//               ? new Date(
//                   selectedMonth < currentMonth ? currentYear + 1 : currentYear,
//                   selectedMonth,
//                   1
//                 )
//               : undefined
//           }
//         />
//       </ContentBox>

//       <ButtonsBar>
//         <div
//           style={{
//             width: "100%",
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             alignItems: "center",
//             gap: "16px",
//           }}
//         >
//           <>
//             <Button
//               type="small"
//               color="--color-black"
//               label="Cancel selections"
//               //   onClick={}
//               active={true}
//             />
//             <Button
//               type="small"
//               color="--color-primary"
//               label="Apply"
//               active={true}
//               onClick={handleBookingDates}
//               allert={
//                 <div
//                   style={{
//                     padding: "32px",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     gap: "16px",
//                     textAlign: "center",
//                   }}
//                 >
//                   <h1>What is Lorem Ipsum?</h1>
//                   <p className="p-light">
//                     Lorem Ipsum is simply dummy text of the printing and
//                     typesetting industry. Lorem Ipsum has been the industry's
//                     standard dummy text ever since the 1500s
//                   </p>
//                 </div>
//               }
//             />
//           </>
//         </div>
//       </ButtonsBar>
//     </div>
//   );
// };

// export default WindowCalendar;
