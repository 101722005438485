import React, { useEffect } from "react";
import JsBarcode from "jsbarcode";
import { v4 as uuidv4 } from "uuid";

const Barcode = ({ barcodeElementId }) => {
  useEffect(() => {
    const generateBarcode = () => {
      const uuid = uuidv4(); // Генерация уникального идентификатора
      const barcodeData = uuid.replace(/-/g, "").slice(0, 8); // Удаляем символы "-", чтобы штрихкод был компактнее

      const barcodeElement = document.getElementById(barcodeElementId);

      if (barcodeElement) {
        // Определяем ширину в зависимости от размера экрана
        const isMobile = window.matchMedia("(max-width: 400px)").matches;
        const barcodeWidth = isMobile ? 1 : 2;

        JsBarcode(barcodeElement, barcodeData, {
          format: "CODE128", // Тип штрихкода
          width: barcodeWidth, // Ширина каждого штриха
          height: 80, // Высота штрихкода
          margin: 0, // Убираем отступы
          background: null, // Прозрачный фон
          displayValue: false, // Отключить текст под штрихкодом
        });
      } else {
        console.error(`Элемент с ID ${barcodeElementId} не найден.`);
      }
    };

    generateBarcode();

    // Перегенерация штрихкода при изменении размера экрана
    const handleResize = () => generateBarcode();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [barcodeElementId]);

  return (
    <div
      style={{
        height: "auto",
        padding: 0,
        margin: 0,
        backgroundColor: "var(--color-gray-100)",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <svg
        id={barcodeElementId}
        style={{
          display: "block", // Убираем inline-отступы
          width: "100%", // Растягиваем штрихкод по ширине
          height: "auto", // Подстраиваем высоту автоматически
        }}
      />
    </div>
  );
};

export default Barcode;

// import React, { useEffect } from "react";
// import JsBarcode from "jsbarcode";
// import { v4 as uuidv4 } from "uuid";

// const Barcode = ({ barcodeElementId }) => {
//   useEffect(() => {
//     const generateBarcode = () => {
//       const uuid = uuidv4(); // Генерация уникального идентификатора
//       const barcodeData = uuid.replace(/-/g, "").slice(0, 8); // Удаляем символы "-", чтобы штрихкод был компактнее

//       const barcodeElement = document.getElementById(barcodeElementId);

//       if (barcodeElement) {
//         JsBarcode(barcodeElement, barcodeData, {
//           format: "CODE128", // Тип штрихкода
//           width: 1, // Ширина каждого штриха
//           height: 80, // Высота штрихкода
//           margin: 0, // Убираем отступы
//           background: null, // Прозрачный фон
//           displayValue: false, // Отключить текст под штрихкодом
//         });
//       } else {
//         console.error(`Элемент с ID ${barcodeElementId} не найден.`);
//       }
//     };

//     generateBarcode();
//   }, [barcodeElementId]);

//   return (
//     <div
//       style={{
//         // width: "100%",
//         height: "auto",
//         padding: 0,
//         margin: 0,
//         backgroundColor: "var(--color-gray-100)",
//         // borderRadius: "0 0 16px 16px",
//         overflow: "hidden",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "flex-start",
//       }}
//     >
//       <svg
//         id={barcodeElementId}
//         style={{
//           display: "block", // Убираем inline-отступы
//           width: "100%", // Растягиваем штрихкод по ширине
//           height: "0", // Подстраиваем высоту автоматически
//         }}
//       />
//     </div>
//   );
// };

// export default Barcode;
