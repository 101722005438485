import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import Counter from "../WidgetsProfile/Counter/Counter";
import DateInterval from "../WidgetsProfile/Date/DateInterval";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
import Button from "../WidgetsProfile/Buttons/Button";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Table from "../WidgetsProfile/Table/Table";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import WidgetAditionalServices from "../RentalPointsV3/WidgetAditionalServices";
import { v4 as uuidv4 } from "uuid";

const WindowOrderDetailModeration = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const { tempData, tempPreviewData, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // States
  const [dateIntervalFilter, setDateIntervalFilter] = useState([]);
  const [dateIntervalFilterTimestamp, setDateIntervalFilterTimestamp] =
    useState([]);
  const [dateIntervalOrderedBike, setDateIntervalOrderedBike] = useState(null);
  const [orderedBike, setOrderedBike] = useState(null);

  // const [selectedDateInterval, setSelectedDateInterval] = useState([]);
  const [quantityLimitBike, setQuantityLimitBike] = useState(1);
  const [countBikes, setCountBikes] = useState(1);
  const [rate, setRate] = useState(null);
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);

  const [additionalServices, setAdditionalServices] = useState(null);

  const [selectedAditionalServices, setSelectedAditionalServices] =
    useState(null);
  const [rootOrderData, setRootOrderData] = useState(null);
  const [previewOrderData, setPreviewOrderData] = useState(null);
  const [rootRentalPointData, setRootRentalPointData] = useState(null);

  const [urlLogoRentalPoint, setUrlLogoRentalpoint] = useState(null);
  const [titleRentalPoint, setTitleRentalPoint] = useState(null);
  const [location, setLocation] = useState(null);
  const [idRentalPointRootDocument, setIdRentalPointRootDocument] =
    useState(null);
  const [rentalPointRootDocument, setRentalPointRootDocument] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);

  const [daysInInterval, setDaysInInterval] = useState(0);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */
  useEffect(() => {
    // Устанавливаем дополнительные услуги и данные о точке аренды
    setAdditionalServices(
      tempRootData?.rentalPoints_order_rootData?.additionalServices || null
    );
    setRootRentalPointData(tempRootData?.rentalPoints_order_rootData || null);

    // Проверяем, есть ли логотипы в массиве logoFiles и не пуст ли он
    if (
      Array.isArray(
        tempRootData?.rentalPoints_order_rootData?.files?.logoFiles
      ) &&
      tempRootData?.rentalPoints_order_rootData?.files?.logoFiles.length > 0
    ) {
      // Ищем приоритетный логотип
      const prioritizedFile =
        tempRootData?.rentalPoints_order_rootData?.files?.logoFiles.find(
          (file) => file.priority === true
        );

      // Устанавливаем URL приоритетного логотипа или первого логотипа в массиве, если приоритетного нет
      setUrlLogoRentalpoint(
        prioritizedFile?.url ||
          tempRootData?.rentalPoints_order_rootData?.files?.logoFiles[0].url
      );
    }
    setTitleRentalPoint(
      tempRootData?.rentalPoints_order_rootData?.basicInformation?.title
        ?.titleOriginal || null
    );
    setLocation(tempRootData?.rentalPoints_order_rootData?.location || null);
    setIdRentalPointRootDocument(
      tempRootData?.rentalPoints_order_rootData?.idRootDocument || null
    );
    setRentalPointRootDocument(
      tempRootData?.rentalPoints_order_rootData || null
    );
  }, [tempRootData?.rentalPoints_order_rootData]);

  // Инициализация о точке проката в которой находится байк
  useEffect(() => {
    setIdRootDocumentRentalPoint(
      tempPreviewData?.selectedBike?.idRootDocumentRentalPoint || null
    );
  }, [tempPreviewData?.selectedBike?.idRootDocumentRentalPoint]);

  // Инициализация заказанного байка
  useEffect(() => {
    setRate(tempData?.orderedBike?.rate || []);
    setOrderedBike(tempData?.orderedBike || null);
    setCurrentCart(tempData?.currentCart || null);
  }, [tempData?.orderedBike, tempData?.currentCart]);

  // Логика начальной загрузки данных
  useEffect(() => {
    // дата указанная в фильтрах каталога
    const dateIntervalFilter = tempData?.manage_fdiltersBikes?.dateInterval;
    setDateIntervalFilterTimestamp(dateIntervalFilter);
    if (Array.isArray(dateIntervalFilter) && dateIntervalFilter.length === 2) {
      const start = new Date(dateIntervalFilter[0]);
      const end = new Date(dateIntervalFilter[1]);

      // Устанавливаем начало и конец дня
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateIntervalFilter([start, end]);
    }
    // дата указанная при добавлении байка в корзину
    const dateIntervalOrderedBike =
      tempData?.manage_fdiltersBikes?.dateIntervalOrderedBike;

    if (
      Array.isArray(dateIntervalOrderedBike) &&
      dateIntervalOrderedBike.length === 2
    ) {
      const start = new Date(dateIntervalOrderedBike[0]);
      const end = new Date(dateIntervalOrderedBike[1]);

      // Устанавливаем начало и конец дня
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateIntervalOrderedBike([start, end]);
    }

    // setSelectedDateInterval(
    //   tempData?.manage_fdiltersBikeItem?.dateInterval || []
    // );

    setQuantityLimitBike(
      tempData?.orderedBike?.metricsBike?.quantityBikes || 1
    );
  }, [tempData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */
  useEffect(() => {
    if (idRootDocumentRentalPoint) {
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: idRootDocumentRentalPoint,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "rentalPoints",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "rentalPoints_order_rootData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_order_rentalPoints",
          errorStateName: "currentError",
        })
      );
    }
  }, [dispatch, idRootDocumentRentalPoint]);

  /**
   *
   * Внутренняя логика компонента
   *
   */

  // Сброс ранее выбранной даты при монтировании компонента
  useEffect(() => {
    dispatch(
      setTempData({
        tempCurrentDocumentState:
          "manage_fdiltersBikes.dateIntervalOrderedBike",
        data: null,
      })
    );
    setDateIntervalOrderedBike(null);
  }, [dispatch]);

  // Обработчик изменения интервала дат
  const handleDateIntervalChange = (newDates) => {
    setDateIntervalOrderedBike(newDates);
  };

  const calculateTotalCostBike = (item) => {
    const { bikeData, totals } = item;

    if (totals.dateInterval) {
      const startDate =
        Array.isArray(totals?.dateInterval) && totals?.dateInterval[0];
      const endDate =
        Array.isArray(totals?.dateInterval) && totals?.dateInterval[1];

      const start = startDate instanceof Date ? startDate : new Date(startDate);
      const end = endDate instanceof Date ? endDate : new Date(endDate);

      if (!start || !end || !bikeData.rate) return 0;

      const monthsInRange = getMonthsInInterval(start, end);

      let totalCost = 0;

      monthsInRange.forEach((month) => {
        const daysInMonth = calculateDaysInMonth(month, start, end);
        const monthRate = bikeData.rate.find((r) => r.month === month);

        if (monthRate) {
          totalCost += daysInMonth * monthRate.price;
        }
      });

      return totalCost * totals.quantity;
    }
    return 0;
  };

  // Создание нового заказа
  const addBikeInOrder = () => {
    const updatedBasket = [...(currentCart || []), rootOrderData];

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedBasket,
      })
    );

    // Сохраняем данные после групировки по точкам проката
    const groupedData =
      updatedBasket?.reduce((acc, item) => {
        const key = item?.rootRentalPointData?.idRootDocument || "unknown";

        const totalCostBike = calculateTotalCostBike(item);
        const totalCostAditioalServices = calculateTotalCostAditionalServices(
          item?.orderedAditionalServices
        );

        const updatedItem = {
          ...item,
          totalCostBike,
          totalCostAditioalServices,
        };

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(updatedItem);
        return acc;
      }, {}) || {};

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCartV4",
        data: groupedData,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "orderedBike",
        data: null,
      })
    );

    // Закрытие окон
    onClose();
    closeParent();
  };

  const formatDate = (date) =>
    date instanceof Date
      ? `${date.getDate()} ${date.toLocaleString("en-US", { month: "long" })}`
      : "Date not selected";

  // const calculateDaysInInterval = (startDate, endDate) => {
  //   if (startDate instanceof Date && endDate instanceof Date) {
  //     const diffInMs = endDate.getTime() - startDate.getTime(); // Разница в миллисекундах
  //     return Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // Конвертируем в дни
  //   }
  //   return 0; // Если даты некорректные
  // };
  const calculateDaysInInterval = (startDate, endDate) => {
    if (startDate instanceof Date && endDate instanceof Date) {
      const diffInMs = endDate.getTime() - startDate.getTime();
      return Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // Конвертируем разницу в дни
    }
    return 0; // Если даты некорректные
  };

  // const daysInInterval =
  //   dateIntervalOrderedBike && dateIntervalOrderedBike.length === 2
  //     ? calculateDaysInInterval(
  //         dateIntervalOrderedBike[0],
  //         dateIntervalOrderedBike[1]
  //       )
  //     : dateIntervalFilter.length === 2
  //     ? calculateDaysInInterval(dateIntervalFilter[0], dateIntervalFilter[1])
  //     : 0; // Если интервал не задан
  useEffect(() => {
    if (dateIntervalOrderedBike && dateIntervalOrderedBike.length === 2) {
      setDaysInInterval(
        calculateDaysInInterval(
          dateIntervalOrderedBike[0],
          dateIntervalOrderedBike[1]
        )
      );
    } else if (dateIntervalFilter && dateIntervalFilter.length === 2) {
      setDaysInInterval(
        calculateDaysInInterval(dateIntervalFilter[0], dateIntervalFilter[1])
      );
    } else {
      setDaysInInterval(0);
    }
  }, [dateIntervalOrderedBike, dateIntervalFilter]);

  // Получение всех месяцев в интервале (массив месяцев от 0 до 11)
  const getMonthsInInterval = (startDate, endDate) => {
    const months = [];
    const currentDate = new Date(startDate);
    currentDate.setDate(1); // Устанавливаем день на 1-е число

    while (currentDate <= endDate) {
      months.push(currentDate.getMonth()); // Добавляем номер месяца (от 0 до 11)
      currentDate.setMonth(currentDate.getMonth() + 1); // Переходим к следующему месяцу
    }

    return months;
  };

  // Подсчёт дней для конкретного месяца
  const calculateDaysInMonth = (month, startDate, endDate) => {
    // Устанавливаем начало месяца
    const startOfMonth = new Date(startDate.getFullYear(), month, 1);

    // Если месяц декабря и он за пределами начального года, устанавливаем год из endDate
    if (month < startDate.getMonth()) {
      startOfMonth.setFullYear(endDate.getFullYear());
    }

    // Устанавливаем конец месяца
    const endOfMonth = new Date(
      startOfMonth.getFullYear(),
      month + 1,
      0,
      23,
      59,
      59,
      999
    );

    // Рассчитываем пересечение диапазонов
    const rangeStart = startDate > startOfMonth ? startDate : startOfMonth;
    const rangeEnd = endDate < endOfMonth ? endDate : endOfMonth;

    const diffInMs = rangeEnd.getTime() - rangeStart.getTime();
    return Math.max(0, Math.ceil(diffInMs / (1000 * 60 * 60 * 24))); // Если диапазон некорректен, возвращаем 0
  };

  // Итоговый подсчёт стоимости
  const calculateTotalCost = () => {
    // Получаем даты начала и конца диапазона

    if (dateIntervalOrderedBike || dateIntervalFilter) {
      const startDate =
        (Array.isArray(dateIntervalOrderedBike) &&
          dateIntervalOrderedBike[0]) ||
        (Array.isArray(dateIntervalFilter) && dateIntervalFilter[0]);
      const endDate =
        (Array.isArray(dateIntervalOrderedBike) &&
          dateIntervalOrderedBike[1]) ||
        (Array.isArray(dateIntervalFilter) && dateIntervalFilter[1]);

      if (!startDate || !endDate || !rate) return 0;

      // Получаем все месяцы, входящие в диапазон
      const monthsInRange = getMonthsInInterval(startDate, endDate);

      // Сумма для хранения итоговой стоимости
      let totalCost = 0;

      // Обходим каждый месяц в диапазоне
      monthsInRange.forEach((month) => {
        // Считаем дни, попадающие в этот месяц
        const daysInMonth = calculateDaysInMonth(month, startDate, endDate);

        // Находим тариф для этого месяца
        const monthRate = rate.find((r) => r.month === month);

        // Если тариф найден, добавляем стоимость за этот месяц
        if (monthRate) {
          totalCost += daysInMonth * monthRate.price;
        }
      });

      // Возвращаем итоговую стоимость
      return totalCost * countBikes;
    }
  };

  const totalCostBike = calculateTotalCost();

  const calculateTotalCostAditionalServices = (selectedAditionalServices) => {
    if (!Array.isArray(selectedAditionalServices)) {
      return 0;
    }

    return selectedAditionalServices.reduce((totalCost, service) => {
      // Проверяем наличие всех необходимых данных
      const count = service?.countOrderedServices || 0;
      const price = service?.price || 0;
      return totalCost + count * price;
    }, 0);
  };

  const totalCostAditionalServices = calculateTotalCostAditionalServices(
    selectedAditionalServices || 0
  );

  const handleCountBikesChange = (newCount) => {
    // Обновляем состояние только в useEffect
    setCountBikes(newCount);
  };

  /**
   *
   * Формирование объектов Root и Preview
   *
   */

  // Order

  useEffect(() => {
    setRootOrderData({
      bikeData: orderedBike || null,
      replacementBike: [],

      orderedAditionalServices: selectedAditionalServices,
      replacementAdditionalServices: [],

      rentalPointRootDocument: rentalPointRootDocument,

      totals: {
        quantity: countBikes || 1,
        dateInterval: dateIntervalOrderedBike || dateIntervalFilterTimestamp,
        daysInInterval: daysInInterval,
        totalCostAditionalServices: totalCostAditionalServices,
        totalCostBike: totalCostBike,
        total: totalCostBike + totalCostAditionalServices,
      },
      rootRentalPointData: {
        logo: urlLogoRentalPoint,
        title: titleRentalPoint,
        location: location,
        idRootDocument: idRentalPointRootDocument,
      },
      id: uuidv4(),
    });
  }, [
    orderedBike,
    dateIntervalOrderedBike,
    dateIntervalFilterTimestamp,
    countBikes,
    daysInInterval,
    selectedAditionalServices,
    rootRentalPointData,
    urlLogoRentalPoint,
    titleRentalPoint,
    location,
    idRentalPointRootDocument,
    totalCostAditionalServices,
    totalCostBike,
    rentalPointRootDocument,
  ]);

  const photoFiles = orderedBike?.files?.photoFiles || [];

  // Находим элемент с флагом priority или первый элемент, если такого нет
  const item = photoFiles?.find((file) => file.priority) || photoFiles[0];

  // Извлекаем параметр url из найденного элемента
  const url = item?.url;

  useEffect(() => {
    setPreviewOrderData({
      title: orderedBike?.basicInformation?.title?.titleOriginal || null,
      description:
        orderedBike?.basicInformation?.description?.descriptionOriginal || null,
      file: url || null,

      created: new Date().getTime(),
      dateInterval: dateIntervalOrderedBike
        ? [
            dateIntervalOrderedBike[0]?.getTime() || null,
            dateIntervalOrderedBike[1]?.getTime() || null,
          ]
        : dateIntervalFilter
        ? [
            dateIntervalFilter[0]?.getTime() || null,
            dateIntervalFilter[1]?.getTime() || null,
          ]
        : [null, null],
      quantity: countBikes || 1,
      quantityAditionalServices: selectedAditionalServices?.length || 0,
    });
  }, [
    orderedBike,
    dateIntervalOrderedBike,
    dateIntervalFilter,
    countBikes,
    selectedAditionalServices,
    url,
  ]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <DateInterval
            dates={
              dateIntervalOrderedBike && dateIntervalOrderedBike.length
                ? dateIntervalOrderedBike
                : dateIntervalFilter
            }
            onChange={handleDateIntervalChange}
          />

          <Counter
            minValue={1}
            maxValue={quantityLimitBike}
            value={countBikes}
            onChange={handleCountBikesChange}
            label="Quantity bikes"
            description="Available quantity"
          />
        </ContentBox>
        <ContentBox>
          <WidgetAditionalServices
            mode="order"
            additionalServices={additionalServices}
            exchange={setSelectedAditionalServices}
          />
        </ContentBox>
        <ContentBox>
          <Table
            columns={1}
            title="Order details"
            data={[
              {
                // typeCell: "column",
                title: "Date interval:",
                parameter: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      fontWeight: "600",
                    }}
                  >
                    <p>
                      {formatDate(
                        (dateIntervalOrderedBike &&
                          dateIntervalOrderedBike[0]) ||
                          (dateIntervalFilter && dateIntervalFilter[0])
                      )}
                    </p>
                    —
                    <p>
                      {formatDate(
                        (dateIntervalOrderedBike &&
                          dateIntervalOrderedBike[1]) ||
                          (dateIntervalFilter && dateIntervalFilter[1])
                      )}
                    </p>
                  </div>
                ),
              },
              {
                title: "Number of Booking Days:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>{daysInInterval}</p>
                ),
              },
              {
                title: "Quantity of Bikes in Order:",
                parameter: <p style={{ fontWeight: "600" }}>{countBikes}</p>,
              },
              {
                title: "Total cost additional services:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalCostAditionalServices)}
                  </p>
                ),
              },
              {
                title: "Total cost bike:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalCostBike)}
                  </p>
                ),
              },
              {
                title: "Total:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(
                      totalCostBike + totalCostAditionalServices
                    )}
                  </p>
                ),
              },
            ]}
          />
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel"
            onClick={onClose}
            active={true}
          />

          <Button
            type="small"
            label="Add bike to cart"
            onClick={addBikeInOrder}
            active={true}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderDetailModeration;
