import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderFiltersBikesListV4 from "./WindowOrderFiltersBikesListV4";
import WindowOrderClientV4 from "./WindowRentalPointOrderV4";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import { useDispatch, useSelector } from "react-redux";
import WidgetOrderBikeInfo from "./WidgetOrderBikeInfo";
import CarouselMenu from "../WidgetsProfile/Carousel/CarouselMenu";
import WindowOrderQuantityBikesV4 from "./WindowOrderQuantityBikesV4";
import DropdownFilterItem from "../WidgetsProfile/DropdownList/DropdownFilterItem";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
import WindowCalendarV4 from "../WidgetsProfile/Date/WindowCalendarV4";

const ItemFilters = ({ title, param }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        color: "rgba(0, 0, 0, 0.8)",
      }}
    >
      {title}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          color: "rgba(0, 0, 0, 0.9)",
          fontWeight: "600",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 8px",
          borderRadius: "16px",
        }}
      >
        {param}
      </div>
    </div>
  );
};

const filterBikes = (bikes, filters) => {
  return bikes.filter((bike) => {
    const { brand, category, city, country, dateInterval, quantityBikes } =
      filters;

    const matchesBrand = !brand || bike.brand?.id === brand.idRootDocument;
    const matchesCategory =
      !category || bike.category?.id === category.idRootDocument;
    const matchesCity = !city || bike.city?.id === city.idRootDocument;
    const matchesCountry =
      !country || bike.country?.id === country.idRootDocument;

    // Проверка на совпадение интервала дат и цены
    const matchesDateInterval =
      !dateInterval ||
      (bike.rate &&
        bike.rate.some((rate) => {
          // Начало месяца
          const rateMonthStart = new Date(dateInterval[0]).setMonth(
            rate.month,
            1
          );

          // Конец месяца
          const rateMonthEnd = new Date(rateMonthStart).setMonth(
            rate.month + 1,
            0
          );

          // Проверяем, пересекаются ли интервал даты и месяц
          return (
            rate.price > 0 && // Цена должна быть больше 0
            ((dateInterval[0] >= rateMonthStart &&
              dateInterval[0] <= rateMonthEnd) || // Начало диапазона внутри месяца
              (dateInterval[1] >= rateMonthStart &&
                dateInterval[1] <= rateMonthEnd) || // Конец диапазона внутри месяца
              (rateMonthStart >= dateInterval[0] &&
                rateMonthEnd <= dateInterval[1])) // Месяц полностью внутри диапазона
          );
        }));

    const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;

    return (
      matchesBrand &&
      matchesCategory &&
      matchesCity &&
      matchesCountry &&
      matchesDateInterval &&
      matchesQuantity
    );
  });
};

const WindowCatalogV4 = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  const [temporaryBasketData, setTemporaryBasketData] = useState([]);
  const [orderBike, setOrderBike] = useState(null);

  const [windowOrderFiltersBikesListV4, setWindowOrderFiltersBikesListV4] =
    useState(false);
  const [windowOrderForUserV4, setWindowOrderForUserV4] = useState(false);

  const [windowOrderDeteIntervalV4, setWindowOrderDeteIntervalV4] =
    useState(null);

  const [windowOrderQuantityBikesV4, setWindowOrderQuantityBikesV4] =
    useState(false);

  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);

  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);

  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brandsPreview, setBrandsPreview] = useState([]);

  const [categoriesPreview, setCategoriesPreview] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [dateInterval, setDateInterval] = useState(null);

  const [differenceInDays, setDifferenceInDays] = useState(0);

  const [newRootFilterData, setNewRootFilterData] = useState(null);

  const [quantityBikes, setQuantityBikes] = useState(1);

  const [manage_fdiltersBikes, setManage_fdiltersBikes] = useState(null);

  const [filteredBikes, setFilteredBikes] = useState([]);

  // const [currentCart, setCurrentCart] = useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */

  useEffect(() => {
    // setCurrentCart(
    //   mode === "new"
    //     ? null
    //     : mode === "edit"
    //     ? tempData?.currentCart || null
    //     : null
    // );

    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);
    setQuantityBikes(tempData?.manage_fdiltersBikes?.quantityBikes || 1);
    setDateInterval(tempData?.manage_fdiltersBikes?.dateInterval || null);
    setOrderBike(tempData?.rootCart_orderedBike || null);
    if (
      Array.isArray(tempData?.manage_fdiltersBikes?.dateInterval) &&
      tempData?.manage_fdiltersBikes?.dateInterval.length === 2
    ) {
      const start = new Date(tempData?.manage_fdiltersBikes?.dateInterval[0]); // Начальная дата
      const end = new Date(tempData?.manage_fdiltersBikes?.dateInterval[1]); // Конечная дата

      // Вычисляем количество миллисекунд в одном дне
      const millisecondsInDay = 24 * 60 * 60 * 1000;

      // Разница между датами в миллисекундах
      const differenceInMilliseconds = end - start;

      // Конвертируем разницу в дни
      const differenceInDays = Math.ceil(
        differenceInMilliseconds / millisecondsInDay
      );
      setDifferenceInDays(differenceInDays);
    }
  }, [
    tempData?.manage_fdiltersBikes,
    tempData?.rootCart_orderedBike,
    // tempData?.currentCart,
  ]);

  useEffect(() => {
    setRentalPoint_countriesListPreview(
      tempPreviewData?.rentalPoint_countriesListPreview || []
    );
    setRentalPoint_citiesListPreview(
      tempPreviewData?.rentalPoint_citiesListPreview || []
    );
    setBrandsPreview(tempPreviewData?.brandsPreview || []);
    setCategoriesPreview(tempPreviewData?.categoriesPreview || []);
  }, [
    tempPreviewData?.rentalPoint_countriesListPreview,
    tempPreviewData?.rentalPoint_citiesListPreview,
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
  ]);

  /**
   *
   * Формирование объектов root и preview
   *
   */

  useEffect(() => {
    setNewRootFilterData({
      city: selectedCity || null,
      country: selectedCountry || null,
      category: selectedCategory || null,
      brand: selectedBrand || null,
      dateInterval: dateInterval || [
        new Date().setHours(0, 0, 0, 0), // Начало текущего дня (время в миллисекундах)
        new Date(new Date().setDate(new Date().getDate() + 7)).setHours(
          23,
          59,
          59,
          999
        ), // Конец 7-го дня (время в миллисекундах)
      ],

      quantityBikes: quantityBikes || 1,
    });
  }, [
    dateInterval,
    selectedCity,
    selectedCountry,
    selectedCategory,
    selectedBrand,
    quantityBikes,
  ]);

  useEffect(() => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "manage_fdiltersBikes",
        data: newRootFilterData,
      })
    );
  }, [dispatch, newRootFilterData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  const handleCountrySelect = (data) => {
    if (data?.idRootDocument !== selectedCountry?.idRootDocument) {
      setSelectedCountry(data);

      setSelectedCity(null);

      dispatch(
        fetchPreviewDataV4({
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          previewCollectionPath: `citiesPreview.countryIds.${data?.idRootDocument}`,
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
          errorStateName: "currentError",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "rentalPoint_citiesListPreview",
        })
      );
    }
  };

  /**
   *
   * Логика компонента
   *
   */
  // useEffect(() => {
  //   if (orderBike) {
  //     setTemporaryBasketData((prevBasket) => {
  //       const updatedBasket = [...prevBasket, orderBike];

  //       // После обновления temporaryBasket выполняем dispatch
  //       dispatch(
  //         setTempData({
  //           tempCurrentDocumentState: "rootCart_orderedBike",
  //           data: null,
  //         })
  //       );

  //       return updatedBasket;
  //     });
  //   }
  // }, [orderBike, dispatch]);

  // Получение данных для фильтров
  useEffect(() => {
    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [
    tempData?.manage_fdiltersBikes,
    tempPreviewData?.general_bikesPreviewNotifications,
  ]);

  // Фильтрация байков
  useEffect(() => {
    if (general_bikesPreviewNotifications && manage_fdiltersBikes) {
      const filtered = filterBikes(
        general_bikesPreviewNotifications,
        manage_fdiltersBikes
      );
      setFilteredBikes(filtered);
    }
  }, [general_bikesPreviewNotifications, manage_fdiltersBikes]);

  const handleCitySelect = (data) => {
    if (data?.idRootDocument !== selectedCity?.idRootDocument) {
      setSelectedCity(data);
    }
  };

  useEffect(() => {
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [tempPreviewData?.general_bikesPreviewNotifications]);

  const handleWindowOrderFiltersBikesListV4 = () => {
    setWindowOrderFiltersBikesListV4(true);
  };

  const handleClose = () => {
    setWindowOrderFiltersBikesListV4(false);
    setWindowOrderForUserV4(false);
    setWindowOrderDeteIntervalV4(false);
    setWindowOrderQuantityBikesV4(false);
  };

  const handleOpenWindowOrderForUserV4 = () => {
    setWindowOrderForUserV4(true);
  };

  const handleOpenWindowOrderDeteIntervalV4 = () => {
    setWindowOrderDeteIntervalV4(true);
  };

  const handleOpenWindowOrderQuantityBikesV4 = () => {
    setWindowOrderQuantityBikesV4(true);
  };

  const controlSelectBrand = (brand) => {
    setSelectedBrand(brand);
  };
  const controlSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const options = [
    {
      item: (
        <div
          style={{
            padding: "0 8px",
          }}
        >
          <span className="material-symbols-outlined">sort</span>
        </div>
      ),
      onClick: handleWindowOrderFiltersBikesListV4,
    },
    {
      item: (
        <div
          style={{
            padding: "0 8px",
          }}
        >
          <span className="material-symbols-outlined">page_info</span>
        </div>
      ),
      onClick: handleWindowOrderFiltersBikesListV4,
    },
    {
      item: (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...(selectedCountry && {
                borderRadius: selectedCity ? "80px 0 0 80px" : "80px",
              }),
              height: "100%",
              padding: "0 4px 0 16px",
            }}
          >
            <DropdownFilterItem
              options={rentalPoint_countriesListPreview}
              onSelect={handleCountrySelect}
              label="Select country"
              description="Please select a country."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCountry}
              active={true}
            />
          </div>

          <div
            style={{
              ...(selectedCity && {
                borderRadius: selectedCountry ? "0 80px 80px 0" : "80px",
              }),
              ...(!selectedCountry && {}),
              height: "100%",
              padding: "0 4px 0 8px",
            }}
          >
            <DropdownFilterItem
              options={rentalPoint_citiesListPreview}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCity}
              active={!!selectedCountry}
            />
          </div>
        </div>
      ),
    },

    {
      item: (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...(selectedBrand
                ? {
                    borderRadius: "80px",
                  }
                : {}),
              height: "100%",
              padding: "0 4px 0 16px",
            }}
          >
            <DropdownFilterItem
              options={brandsPreview}
              onSelect={controlSelectBrand}
              label="Select Brand"
              description="Please select a brand."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedBrand}
              active={true}
            />
          </div>
        </div>
      ),
    },
    {
      item: (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...(selectedCategory
                ? {
                    borderRadius: "80px",
                  }
                : {}),
              height: "100%",
              padding: "0 4px 0 16px",
            }}
          >
            <DropdownFilterItem
              options={categoriesPreview}
              onSelect={controlSelectCategory}
              label="Select Category"
              description="Please select a category."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCategory}
              active={true}
            />
          </div>
        </div>
      ),
    },
    {
      item: (
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              ...(Array.isArray(dateInterval) &&
              dateInterval[0] &&
              dateInterval[1]
                ? {
                    backgroundColor: "var(--color-gray-100)",
                    borderRadius: "80px",
                  }
                : {}),
              height: "100%",
              padding: "0 4px 0 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ItemFilters
              title={`${
                Array.isArray(dateInterval) && dateInterval[0]
                  ? new Date(dateInterval[0])?.toLocaleDateString()
                  : ""
              } — ${
                Array.isArray(dateInterval) && dateInterval[1]
                  ? new Date(dateInterval[1])?.toLocaleDateString()
                  : ""
              }`}
              param={
                differenceInDays && differenceInDays > 1
                  ? `${differenceInDays} days`
                  : `${differenceInDays} day`
              }
            />
          </div>
        </div>
      ),
      onClick: handleOpenWindowOrderDeteIntervalV4,
    },
    {
      item: (
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              ...(quantityBikes > 1
                ? {
                    backgroundColor: "var(--color-gray-100)",
                    borderRadius: "80px",
                  }
                : {}),
              height: "100%",
              padding: "0 4px 0 16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ItemFilters
              title="Quantity bikes:"
              param={
                quantityBikes > 1
                  ? `${quantityBikes} bikes`
                  : `${quantityBikes} bike`
              }
            />
          </div>
        </div>
      ),
      onClick: handleOpenWindowOrderQuantityBikesV4,
    },
  ];

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowOrderDeteIntervalV4}
        onClose={handleClose}
        title={`Dete interval`}
      >
        <WindowCalendarV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderQuantityBikesV4}
        onClose={handleClose}
        title={`Dete interval`}
      >
        <WindowOrderQuantityBikesV4 onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title={`Order for user`}
      >
        <WindowOrderClientV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderFiltersBikesListV4}
        onClose={handleClose}
        title={`Create order`}
      >
        <WindowOrderFiltersBikesListV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          // marginBottom: "72px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <CarouselMenu options={options} />

          <ContentBox position="start">
            <WidgetBikesList
              bikesList={filteredBikes}
              bikeState="approved"
              mode="moderation"
            />
          </ContentBox>
        </div>
      </div>
    </>
  );
};

export default WindowCatalogV4;
