import React, { useEffect, useState } from "react";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetAlert from "../WidgetsProfile/Alerts/WidgetAlert";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import DateInterval from "../WidgetsProfile/Date/DateInterval";
import Counter from "../WidgetsProfile/Counter/Counter";
import WidgetLocationModeration from "../Moderation/WidgetLocationModeration";
import { useSelector } from "react-redux";
import WidgetBrandCategory from "../BikesV3/WidgetBrandCategory";

const WindowOrderFiltersBikesListV4 = ({ onClose }) => {
  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  const [countBikes, setCountBikes] = useState(0);
  const [location, setLocation] = useState({});
  const [brandsPreview, setBrandsPreview] = useState([]);
  const [categoriesPreview, setCategoriesPreview] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setLocation(
      tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location || {}
    );
    setBrandsPreview(tempPreviewData?.brandsPreview || []);

    setCategoriesPreview(tempPreviewData?.categoriesPreview || []);
  }, [
    tempRootData?.rentalPoint_moderation_rootRerntalPoint?.location,
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
  ]);

  const onChange = (newCount) => {
    setCountBikes(newCount);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <DateInterval mode="new" onClose={onClose} dates={[0, 0]} />
        </ContentBox>

        <ContentBox>
          <Counter
            minValue={1}
            maxValue={1}
            value={1}
            onChange={(newCount) => onChange(newCount)}
            label={`Quntity bikes`}
            description={`Cabbage soup available`}
          />
        </ContentBox>
        <ContentBox>
          <WidgetLocationModeration
            location={location}
            ancorId="ancor1"
            mode="edit"
          />
        </ContentBox>
        <ContentBox>
          <WidgetBrandCategory
            mode="edit"
            brands={brandsPreview}
            categories={categoriesPreview}
            initialSelectedBrand={selectedBrand}
            initialSelectedCategory={selectedCategory}
          />
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          <Button
            type="small"
            label="Apply"
            active={true}
            // onClick={() => handleCreateOrUpdateData()}
            allert={
              <WidgetAlert
                title={`Are you sure you want to add the page to the directory?`}
              />
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderFiltersBikesListV4;
