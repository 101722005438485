// По пути metadataDocumentPath содержится lastUpdatedDocument:{
//   lastUpdatedDocumentId:id
//   }
//   И по пути metadataGeneralDocumentPath содержится lastUpdatedDocument:{
//   lastUpdatedDocumentId:id
//   }
//   Значения lastUpdatedDocumentId по каждому пути отличаются и уникальны.
//   Нужно учесть это в работе функции. Потому как сейчас при редактировании lastUpdatedDocumentId берется из metadataDocumentPath что является ошибкой.

//   Сделай пожалуйста полный код компонента.

import { createAsyncThunk } from "@reduxjs/toolkit";
import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../../../services/firebase/authFirebase";

export const deleteFilesThunkV4 = createAsyncThunk(
  "files/deleteFilesThunkV4",
  async (
    {
      fieldName,
      fileIds,
      rootCollectionPath,
      previewCollectionPath,
      previewGeneralCollectionPath,
      idPreviewDocument,
      idRootDocument,
      metadataDocumentPath,
      metadataGeneralDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      previewGeneralDocumenState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue }
  ) => {
    try {
      // 1. Поиск idPreviewDocument, если не передан
      if (!idPreviewDocument) {
        const previewCollectionRef = collection(
          db,
          ...previewCollectionPath.split(".")
        );
        const snapshot = await getDocs(previewCollectionRef);
        snapshot.forEach((doc) => {
          const data = doc.data().data || [];
          if (data.find((item) => item.idRootDocument === idRootDocument)) {
            idPreviewDocument = doc.id;
          }
        });
        if (!idPreviewDocument) {
          throw new Error(
            `Документ с idRootDocument ${idRootDocument} не найден в ${previewCollectionPath}.`
          );
        }
      }

      // 2. Удаление файлов
      const finalFiles = await removeFilesV4(
        fieldName,
        fileIds,
        rootCollectionPath,
        previewCollectionPath,
        idPreviewDocument,
        idRootDocument
      );

      const {
        rootDocumentData,
        previewDocumentData: updatedPreviewDocumentData,
      } = finalFiles;

      // 3. Обновление previewCollectionPath
      const previewDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idPreviewDocument
      );
      const previewDocSnap = await getDoc(previewDocRef);
      if (!previewDocSnap.exists())
        throw new Error(
          `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
        );

      const previewDocumentDataFromFirestore = previewDocSnap.data();
      const newPreviewDataArray = [...previewDocumentDataFromFirestore.data];
      const updatedItemIndex = newPreviewDataArray.findIndex(
        (item) => item.idRootDocument === idRootDocument
      );

      if (updatedItemIndex !== -1) {
        newPreviewDataArray[updatedItemIndex] = updatedPreviewDocumentData;
      }

      const newDocumentSize = new Blob([
        JSON.stringify({ data: newPreviewDataArray }),
      ]).size;

      await setDoc(
        previewDocRef,
        { data: newPreviewDataArray, documentSize: newDocumentSize },
        { merge: true }
      );

      // 4. Обновление previewGeneralCollectionPath, если предоставлен
      if (previewGeneralCollectionPath) {
        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию

        if (metadataGeneralDocumentPath) {
          const generalMetadataDocRef = doc(
            db,
            ...metadataGeneralDocumentPath.split(".")
          );
          const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

          if (generalMetadataDocSnap.exists()) {
            const generalMetadataData = generalMetadataDocSnap.data();
            generalLastUpdatedDocumentId =
              generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
              idPreviewDocument;
          }
        }

        const generalPreviewRef = doc(
          db,
          ...previewGeneralCollectionPath.split("."),
          generalLastUpdatedDocumentId
        );

        await setDoc(
          generalPreviewRef,
          { data: newPreviewDataArray, documentSize: newDocumentSize },
          { merge: true }
        );
      }

      // 5. Обновление метаданных для metadataDocumentPath
      if (metadataDocumentPath) {
        const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
        const metadataDocSnap = await getDoc(metadataDocRef);

        let lastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (metadataDocSnap.exists()) {
          const metadataData = metadataDocSnap.data();
          lastUpdatedDocumentId =
            metadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          metadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId, // Сохраняем правильное значение
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      // 6. Обновление метаданных для metadataGeneralDocumentPath
      if (metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (generalMetadataDocSnap.exists()) {
          const generalMetadataData = generalMetadataDocSnap.data();
          generalLastUpdatedDocumentId =
            generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          generalMetadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId: generalLastUpdatedDocumentId, // Сохраняем уникальное значение
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      // 7. Возвращаем результат
      return {
        fieldName,
        rootDocumentData,
        previewDocumentData: updatedPreviewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        idPreviewDocument,
        idRootDocument,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      console.error(
        "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
        error
      );
      const errorMessage = interpretAuthErrorV2(error.code);
      return rejectWithValue(errorMessage);
    }
  }
);

/** ЭТОТ ВАРИАНТ НЕ УЧИТЫВАЕТ ID в GENERAL коллекции */
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
// import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
// import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const deleteFilesThunkV4 = createAsyncThunk(
//   "files/deleteFilesThunkV4",
//   async (
//     {
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // Поиск idPreviewDocument, если не передан
//       if (!idPreviewDocument) {
//         const previewCollectionRef = collection(
//           db,
//           ...previewCollectionPath.split(".")
//         );
//         const snapshot = await getDocs(previewCollectionRef);
//         snapshot.forEach((doc) => {
//           const data = doc.data().data || [];
//           if (data.find((item) => item.idRootDocument === idRootDocument)) {
//             idPreviewDocument = doc.id;
//           }
//         });
//         if (!idPreviewDocument) {
//           throw new Error(
//             `Документ с idRootDocument ${idRootDocument} не найден в ${previewCollectionPath}.`
//           );
//         }
//       }

//       // Удаление файлов с помощью removeFilesV4
//       const finalFiles = await removeFilesV4(
//         fieldName,
//         fileIds,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument
//       );
//       const {
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//       } = finalFiles;

//       // Обновление previewCollectionPath
//       const previewDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idPreviewDocument
//       );
//       const previewDocSnap = await getDoc(previewDocRef);
//       if (!previewDocSnap.exists())
//         throw new Error(
//           `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
//         );
//       const previewDocumentDataFromFirestore = previewDocSnap.data();
//       const newPreviewDataArray = [...previewDocumentDataFromFirestore.data];
//       const updatedItemIndex = newPreviewDataArray.findIndex(
//         (item) => item.idRootDocument === idRootDocument
//       );

//       if (updatedItemIndex !== -1) {
//         newPreviewDataArray[updatedItemIndex] = updatedPreviewDocumentData;
//       }

//       const newDocumentSize = new Blob([
//         JSON.stringify({ data: newPreviewDataArray }),
//       ]).size;
//       await setDoc(
//         previewDocRef,
//         { data: newPreviewDataArray, documentSize: newDocumentSize },
//         { merge: true }
//       );

//       // Обновление previewGeneralCollectionPath, если предоставлен
//       if (previewGeneralCollectionPath) {
//         const generalPreviewRef = doc(
//           db,
//           ...previewGeneralCollectionPath.split("."),
//           idPreviewDocument
//         );
//         await setDoc(
//           generalPreviewRef,
//           { data: newPreviewDataArray, documentSize: newDocumentSize },
//           { merge: true }
//         );
//       }

//       // Обновление данных lastUpdatedDocument по метаданным
//       const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
//       await setDoc(
//         metadataDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idPreviewDocument,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       if (metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         await setDoc(
//           generalMetadataDocRef,
//           {
//             lastUpdatedDocument: {
//               lastUpdatedDocumentId: idPreviewDocument,
//               documentSize: newDocumentSize,
//             },
//           },
//           { merge: true }
//         );
//       }

//       return {
//         fieldName,
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         previewGeneralCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         previewGeneralDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       console.error(
//         "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
//         error
//       );
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
// import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
// import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const deleteFilesThunkV4 = createAsyncThunk(
//   "files/deleteFilesThunkV4",
//   async (
//     {
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // Если idPreviewDocument не передан, ищем его по idRootDocument
//       if (!idPreviewDocument) {
//         console.log("idPreviewDocument не передан, выполняем поиск...");

//         const previewCollectionRef = collection(
//           db,
//           ...previewCollectionPath.split(".")
//         );
//         const snapshot = await getDocs(previewCollectionRef);

//         // Поиск документа с нужным idRootDocument
//         snapshot.forEach((doc) => {
//           const data = doc.data().data || [];
//           const foundItem = data.find(
//             (item) => item.idRootDocument === idRootDocument
//           );

//           if (foundItem) {
//             idPreviewDocument = doc.id;
//             console.log(
//               `Найден документ с idRootDocument: ${idRootDocument} в документе ${idPreviewDocument}`
//             );
//           }
//         });

//         if (!idPreviewDocument) {
//           throw new Error(
//             `Документ с idRootDocument ${idRootDocument} не найден в ${previewCollectionPath}.`
//           );
//         }
//       }

//       // Удаление файлов с помощью removeFilesV4
//       const finalFiles = await removeFilesV4(
//         fieldName,
//         fileIds,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument
//       );

//       const {
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//       } = finalFiles;

//       // Получаем документ по пути previewCollectionPath
//       const previewDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idPreviewDocument
//       );
//       const previewDocSnap = await getDoc(previewDocRef);

//       if (!previewDocSnap.exists()) {
//         throw new Error(
//           `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
//         );
//       }

//       const previewDocumentDataFromFirestore = previewDocSnap.data();
//       console.log(
//         "previewDocumentDataFromFirestore",
//         previewDocumentDataFromFirestore
//       );

//       let lastUpdatedDocId = idPreviewDocument;
//       let lastUpdatedDocSize =
//         previewDocumentDataFromFirestore?.documentSize || 0;

//       console.log(
//         "Текущие данные документа:",
//         lastUpdatedDocId,
//         lastUpdatedDocSize
//       );

//       // Обновляем элемент в массиве data
//       const newPreviewDataArray = [...previewDocumentDataFromFirestore.data];
//       const updatedItemIndex = newPreviewDataArray.findIndex(
//         (item) => item.idRootDocument === idRootDocument
//       );

//       if (updatedItemIndex !== -1) {
//         newPreviewDataArray[updatedItemIndex] = updatedPreviewDocumentData;
//       }

//       // Пересчитываем размер документа после обновления
//       const newDocumentSize = new Blob([
//         JSON.stringify({ data: newPreviewDataArray }),
//       ]).size;

//       console.log("Новый размер документа:", newDocumentSize);

//       // Обновляем поле documentSize в previewDocument
//       await setDoc(
//         previewDocRef,
//         {
//           data: newPreviewDataArray,
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // Обновление данных lastUpdatedDocument по пути metadataPath
//       const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
//       await setDoc(
//         metadataDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: lastUpdatedDocId,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       console.log("Поле lastUpdatedDocument успешно обновлено:", {
//         lastUpdatedDocumentId: lastUpdatedDocId,
//         documentSize: newDocumentSize,
//       });

//       return {
//         fieldName,
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         previewGeneralDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       console.error(
//         "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
//         error
//       );
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
// import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const deleteFilesThunkV4 = createAsyncThunk(
//   "files/deleteFilesThunkV4",
//   async (
//     {
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//       metadataDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     console.log(
//       "files/deleteFilesThunkV4",
//       "fieldName:",
//       fieldName,
//       "fileIds:",
//       fileIds,
//       "fileIds:",
//       rootCollectionPath,
//       "previewCollectionPath:",
//       previewCollectionPath,
//       "idPreviewDocument:",
//       idPreviewDocument,
//       "idRootDocument:",
//       idRootDocument,
//       "metadataDocumentPath:",
//       metadataDocumentPath,
//       "rootCurrentDocumentState:",
//       rootCurrentDocumentState,
//       "previewCurrentDocumenState:",
//       previewCurrentDocumenState,
//       "loadingStateName:",
//       loadingStateName,
//       "errorStateName:",
//       errorStateName
//     );
//     try {
//       // Удаление файлов с помощью removeFilesV4
//       const finalFiles = await removeFilesV4(
//         fieldName,
//         fileIds,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument
//       );

//       const {
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//       } = finalFiles;

//       // Получаем документ по пути previewCollectionPath
//       const previewDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idPreviewDocument
//       );
//       const previewDocSnap = await getDoc(previewDocRef);

//       if (!previewDocSnap.exists()) {
//         throw new Error(
//           `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
//         );
//       }

//       const previewDocumentDataFromFirestore = previewDocSnap.data();
//       console.log(
//         "previewDocumentDataFromFirestore",
//         previewDocumentDataFromFirestore
//       );

//       let lastUpdatedDocId = idPreviewDocument;
//       console.log(
//         "previewDocumentDataFromFirestore lastUpdatedDocId",
//         lastUpdatedDocId
//       );

//       let lastUpdatedDocSize =
//         previewDocumentDataFromFirestore?.documentSize || 0;

//       console.log(
//         "Текущие данные документа:",
//         lastUpdatedDocId,
//         lastUpdatedDocSize
//       );

//       // Обновляем элемент в массиве data
//       const newPreviewDataArray = [...previewDocumentDataFromFirestore.data];
//       const updatedItemIndex = newPreviewDataArray.findIndex(
//         (item) => item.idRootDocument === idRootDocument
//       );

//       if (updatedItemIndex !== -1) {
//         newPreviewDataArray[updatedItemIndex] = updatedPreviewDocumentData;
//       }

//       // Пересчитываем размер документа после обновления
//       const newDocumentSize = new Blob([
//         JSON.stringify({ data: newPreviewDataArray }),
//       ]).size;

//       console.log("Новый размер документа:", newDocumentSize);

//       // Обновляем поле documentSize в previewDocument
//       await setDoc(
//         previewDocRef,
//         {
//           data: newPreviewDataArray,
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // Обновление данных lastUpdatedDocument по пути metadataPath
//       const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
//       await setDoc(
//         metadataDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: lastUpdatedDocId,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       console.log("Поле lastUpdatedDocument успешно обновлено:", {
//         lastUpdatedDocumentId: lastUpdatedDocId,
//         documentSize: newDocumentSize,
//       });

//       return {
//         fieldName,
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       console.error(
//         "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
//         error
//       );
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
// import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const deleteFilesThunkV4 = createAsyncThunk(
//   "files/deleteFilesThunkV4",
//   async (
//     {
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//       metadataPath,
//     },
//     { rejectWithValue }
//   ) => {
//     console.log(
//       "files/deleteFilesThunkV4",
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//       metadataPath
//     );

//     try {
//       // Удаление файлов с помощью removeFilesV4
//       const finalFiles = await removeFilesV4(
//         fieldName,
//         fileIds,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//         metadataPath
//       );

//       const {
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//       } = finalFiles;

//       // Получаем документ по пути previewCollectionPath
//       const previewDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idPreviewDocument
//       );
//       const previewDocSnap = await getDoc(previewDocRef);

//       if (!previewDocSnap.exists()) {
//         throw new Error(
//           `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
//         );
//       }

//       const previewDocumentDataFromFirestore = previewDocSnap.data();
//       console.log(
//         "previewDocumentDataFromFirestore",
//         previewDocumentDataFromFirestore
//       );
//       let lastUpdatedDocId =
//         previewDocumentDataFromFirestore?.id || idPreviewDocument;
//       console.log(
//         "previewDocumentDataFromFirestore lastUpdatedDocId",
//         lastUpdatedDocId
//       );
//       let lastUpdatedDocSize =
//         previewDocumentDataFromFirestore?.documentSize || 0;

//       console.log(
//         "Текущие данные документа:",
//         lastUpdatedDocId,
//         lastUpdatedDocSize
//       );

//       // Вычисление нового размера документа после удаления файлов
//       const removedFileSize = fileIds.length; // Пример: уменьшить размер на количество удаленных файлов
//       const newDocumentSize = Math.max(0, lastUpdatedDocSize - removedFileSize); // Размер не может быть отрицательным

//       // Обновление данных lastUpdatedDocument
//       const languageDocRef = doc(db, "catalog", "countries");
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: lastUpdatedDocId,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       console.log("Поле lastUpdatedDocument успешно обновлено:", {
//         lastUpdatedDocumentId: lastUpdatedDocId,
//         documentSize: newDocumentSize,
//       });

//       return {
//         fieldName,
//         rootDocumentData,
//         previewDocumentData: updatedPreviewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//       };
//     } catch (error) {
//       console.error(
//         "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
//         error
//       );
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import interpretAuthErrorV2 from "../../../../functions/interpretAuthErrorV2";
// import { removeFilesV4 } from "../../../../services/firebase/crudFirestore";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "../../../../services/firebase/authFirebase";

// export const deleteFilesThunkV4 = createAsyncThunk(
//   "files/deleteFilesThunkV4",
//   async (
//     {
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       idPreviewDocument,
//       idRootDocument,
//     },
//     { rejectWithValue }
//   ) => {
//     console.log(
//       "files/deleteFilesThunkV4",
//       fieldName,
//       fileIds,
//       rootCollectionPath,
//       previewCollectionPath,
//       idPreviewDocument,
//       idRootDocument
//     );

//     try {
//       // Удаление файлов с помощью removeFilesV4
//       const finalFiles = await removeFilesV4(
//         fieldName,
//         fileIds,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument
//       );

//       const { rootDocumentData, previewDocumentData } = finalFiles;

//       // Получаем ссылку на документ language
//       const languageDocRef = doc(db, "catalog", "countries");
//       const languageDocSnap = await getDoc(languageDocRef);

//       if (!languageDocSnap.exists()) {
//         throw new Error("Документ language не найден.");
//       }

//       const languageData = languageDocSnap.data();
//       let lastUpdatedDocId =
//         languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//       let lastUpdatedDocSize =
//         languageData.lastUpdatedDocument?.documentSize || 0;

//       console.log(
//         "Текущие данные lastUpdatedDocument:",
//         lastUpdatedDocId,
//         lastUpdatedDocSize
//       );

//       // Вычисление нового размера документа после удаления файлов
//       const removedFileSize = fileIds.length; // Пример: уменьшить размер на количество удаленных файлов
//       const newDocumentSize = Math.max(0, lastUpdatedDocSize - removedFileSize); // Размер не может быть отрицательным

//       // Обновление lastUpdatedDocument в документе language
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: lastUpdatedDocId,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       console.log("Поле lastUpdatedDocument успешно обновлено:", {
//         lastUpdatedDocumentId: lastUpdatedDocId,
//         documentSize: newDocumentSize,
//       });

//       return {
//         fieldName,
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         idPreviewDocument,
//         idRootDocument,
//       };
//     } catch (error) {
//       console.error(
//         "Ошибка при удалении файлов и обновлении lastUpdatedDocument:",
//         error
//       );
//       const errorMessage = interpretAuthErrorV2(error.code);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
