import React from "react";
import Table from "../WidgetsProfile/Table/Table";

const WidgetPaymentMethodInform = ({ paymentMethod }) => {
  return (
    <>
      {paymentMethod === "cryptocurrency" && (
        <Table
          columns={1}
          title="Cryptocurrency"
          data={[
            {
              title: "Bitcoin",
              parameter: <></>,
            },
            {
              title: "Ethereum",
              parameter: <></>,
            },
            {
              title: "Litecoin",
              parameter: <></>,
            },
            {
              title: "USDT",
              parameter: <></>,
            },
          ]}
        />
      )}
      {paymentMethod === "electronicWallets" && (
        <Table
          columns={1}
          title="Electronic wallets"
          data={[
            {
              title: "PayPal",
              parameter: <></>,
            },
            {
              title: "Alipay",
              parameter: <></>,
            },
            {
              title: "WeChat Pay",
              parameter: <></>,
            },
            {
              title: "Samsung Pay",
              parameter: <></>,
            },
            {
              title: "Amazon Pay",
              parameter: <></>,
            },
            {
              title: "Venmo",
              parameter: <></>,
            },
            {
              title: "Skrill",
              parameter: <></>,
            },
            {
              title: "Neteller",
              parameter: <></>,
            },
          ]}
        />
      )}
      {paymentMethod === "bankCard" && (
        <Table
          columns={1}
          title="Bank card"
          data={[
            {
              title: "Visa",
              parameter: <></>,
            },
            {
              title: "Mastercard",
              parameter: <></>,
            },
            {
              title: "American Express (Amex)",
              parameter: <></>,
            },
            {
              title: "Discover",
              parameter: <></>,
            },
            {
              title: "Diners Club",
              parameter: <></>,
            },
            {
              title: "JCB",
              parameter: <></>,
            },
            {
              title: "UnionPay",
              parameter: <></>,
            },
          ]}
        />
      )}
    </>
  );
};

export default WidgetPaymentMethodInform;
