import React, { useEffect, useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowAditionalServiceDetail from "./WindowAditionalServiceDetail";
import { useDispatch } from "react-redux";
import WindowAditionalServiceDetailModeration from "../Moderation/WindowAditionalServiceDetailModeration";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
import Counter from "../WidgetsProfile/Counter/Counter";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";

const ItemAditionalServiceModeration = ({
  additionalServiceItem,
  handleToggleWidgetEditAditionalService,
}) => (
  <div
    onClick={() =>
      handleToggleWidgetEditAditionalService(additionalServiceItem)
    }
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "var(--color-gray-100)",
      ...(additionalServiceItem.remove > 0 ? { opacity: 0.2 } : { opacity: 1 }),
      borderRadius: "16px",
      padding: "16px 16px 16px 16px",
      boxSizing: "border-box",
      cursor: "pointer",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "flex-start",
        justifyContent: "space-between",

        width: "88%",
      }}
    >
      <p>
        {`${additionalServiceItem?.title?.titleOriginal} — ${
          additionalServiceItem?.counter || 0
        } PC`}
      </p>
      <p
        className="p-light"
        style={{
          width: "100%",
        }}
      >
        {additionalServiceItem?.description?.descriptionOriginal}
      </p>

      <p
        style={{
          fontWeight: "600",
        }}
      >
        {additionalServiceItem?.price > 0
          ? formatCurrencyUSD(additionalServiceItem?.price)
          : "Free"}
      </p>
    </div>

    <span className="material-symbols-outlined">edit</span>
  </div>
);

const ItemAditionalServiceOrder = ({
  additionalServiceItem,
  handleCounterChange,
  activeItems,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "var(--color-gray-100)",
      ...(additionalServiceItem.remove > 0 ? { opacity: 0.2 } : { opacity: 1 }),
      borderRadius: "16px",
      padding: "16px 16px 16px 16px",
      boxSizing: "border-box",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <p>
          {`${additionalServiceItem?.title?.titleOriginal} — ${
            additionalServiceItem?.counter || 0
          } PC`}
        </p>
        <span
          className="p-light"
          style={{
            width: "100%",
          }}
        >
          <CollapsableText
            text={additionalServiceItem?.description?.descriptionOriginal}
          />
        </span>

        <p
          style={{
            fontWeight: "600",
          }}
        >
          {additionalServiceItem?.price > 0
            ? formatCurrencyUSD(additionalServiceItem?.price)
            : "Free"}
        </p>
      </div>

      <Counter
        maxValue={additionalServiceItem.counter}
        minValue={0}
        value={
          activeItems?.find(
            (activeItem) => activeItem.id === additionalServiceItem.id
          )?.countOrderedServices || 0
        }
        onChange={(newCount) =>
          handleCounterChange(newCount, additionalServiceItem)
        }
        buttonStyle={{
          backgroundColor: "var(--color-gray-200)",
        }}
      />
    </div>
  </div>
);

const WidgetAditionalServicesList = ({
  mode,
  additionalServices,
  exchange,
}) => {
  const dispatch = useDispatch();
  const [activeItems, setActiveItems] = useState([]);
  const [selectedAditionalService, setSelectedAditionalService] = useState({});
  const [
    isOpenWidgetEditAditionalService,
    setIsOpenWidgetEditAditionalService,
  ] = useState(false);

  const [
    isOpenWidgetEditAditionalServiceModeration,
    setIsOpenWidgetEditAditionalServiceModeration,
  ] = useState(false);

  useEffect(() => {
    if (typeof exchange === "function") {
      exchange(activeItems);
    }
  }, [activeItems, exchange]);

  const handleCloseWidgetEditAditionalService = () => {
    setIsOpenWidgetEditAditionalService(false);
    setIsOpenWidgetEditAditionalServiceModeration(false);
  };

  const handleToggleWidgetEditAditionalService = (additionalServiceItem) => {
    if (mode === "moderation" || mode === "moderatorAdded") {
      setSelectedAditionalService(additionalServiceItem);
      setIsOpenWidgetEditAditionalServiceModeration(true);
      dispatch(
        setTempData({
          tempCurrentDocumentState:
            "rentalPoint_moderation_selectedAdditionalService",
          data: additionalServiceItem,
        })
      );
    }
  };

  const handleCounterChange = (newCount, item) => {
    if (!item) return; // Проверка на существование элемента, чтобы избежать ошибок
    setActiveItems((prev) => {
      const existingIndex = prev.findIndex(
        (activeItem) => activeItem.id === item.id
      );

      if (newCount > 0) {
        const updatedItem = { ...item, countOrderedServices: newCount };

        if (existingIndex > -1) {
          const updatedItems = [...prev];
          updatedItems[existingIndex] = updatedItem;
          return updatedItems;
        } else {
          return [...prev, updatedItem];
        }
      } else {
        return prev.filter((activeItem) => activeItem.id !== item.id);
      }
    });
  };

  return (
    <>
      <PopupDirrectory
        isOpen={isOpenWidgetEditAditionalService}
        onClose={handleCloseWidgetEditAditionalService}
        title="Edit aditional services"
      >
        <WindowAditionalServiceDetail
          mode={mode}
          selectedAditionalService={selectedAditionalService}
          onClose={handleCloseWidgetEditAditionalService}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenWidgetEditAditionalServiceModeration}
        onClose={handleCloseWidgetEditAditionalService}
        title="Edit aditional services moderation"
      >
        <WindowAditionalServiceDetailModeration
          mode={mode}
          selectedAditionalService={selectedAditionalService}
          onClose={handleCloseWidgetEditAditionalService}
        />
      </PopupDirrectory>

      {additionalServices?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {additionalServices.map((additionalServiceItem, index) => {
            if (mode === "moderation" || mode === "moderatorAdded") {
              return (
                <ItemAditionalServiceModeration
                  key={index}
                  additionalServiceItem={additionalServiceItem}
                  handleToggleWidgetEditAditionalService={
                    handleToggleWidgetEditAditionalService
                  }
                />
              );
            } else if (mode === "order") {
              return (
                additionalServiceItem.counter > 0 && (
                  <ItemAditionalServiceOrder
                    key={index}
                    additionalServiceItem={additionalServiceItem}
                    handleCounterChange={handleCounterChange}
                    activeItems={activeItems}
                  />
                )
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
};

export default WidgetAditionalServicesList;
