import React, { useEffect, useState } from "react";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import WidgetUsersListModerationV4 from "../Moderation/WidgetUsersListModerationV4";
import { useDispatch, useSelector } from "react-redux";
import WidgetOrderSearchUserV4 from "./WidgetOrderSearchUserV4";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { truncateText } from "../../../../../functions/truncateText";
import Counter from "../WidgetsProfile/Counter/Counter";
import { v4 as uuidv4 } from "uuid";
import JsBarcode from "jsbarcode";
// import generateBarcode from "../../../../../functions/generateBarcode";
import Barcode from "../WidgetsProfile/Barcode/Barcode";

import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowOrderUsersListV4 from "./WindowOrderUsersListV4";
import WidgetOrderUsersListV4 from "./WidgetOrderUsersListV4";
import Table from "../WidgetsProfile/Table/Table";
import CarouselMenu from "../WidgetsProfile/Carousel/CarouselMenu";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
import { QRCodeCanvas } from "qrcode.react";
import WidgetPaymentMethodInform from "./WidgetPaymentMethodInform";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/createDataThunkV4";
import WidgetWoucherListV4 from "./WidgetWoucherListV4";

const calculateTotals = (currentCart) => {
  console.log("currentOrder 200", currentCart);
  if (!Array.isArray(currentCart)) return {};

  return currentCart.reduce(
    (totals, item) => {
      const itemTotals = item?.totals || {};
      return {
        // daysInInterval:
        //   totals.daysInInterval + (itemTotals.daysInInterval || 0),
        quantity: totals.quantity + (itemTotals.quantity || 0),
        total: totals.total + (itemTotals.total || 0),
        totalCostAditionalServices:
          totals.totalCostAditionalServices +
          (itemTotals.totalCostAditionalServices || 0),
        totalCostBike: totals.totalCostBike + (itemTotals.totalCostBike || 0),
      };
    },
    {
      // daysInInterval: 0,
      quantity: 0,
      total: 0,
      totalCostAditionalServices: 0,
      totalCostBike: 0,
    }
  );
};

// Получение всех месяцев в интервале (массив месяцев от 0 до 11)
const getMonthsInInterval = (startDate, endDate) => {
  const months = [];
  const currentDate = new Date(startDate);
  currentDate.setDate(1); // Устанавливаем день на 1-е число

  while (currentDate <= endDate) {
    months.push(currentDate.getMonth()); // Добавляем номер месяца (от 0 до 11)
    currentDate.setMonth(currentDate.getMonth() + 1); // Переходим к следующему месяцу
  }

  return months;
};

// Подсчёт дней для конкретного месяца
const calculateDaysInMonth = (month, startDate, endDate) => {
  // Устанавливаем начало месяца
  const startOfMonth = new Date(startDate.getFullYear(), month, 1);

  // Если месяц декабря и он за пределами начального года, устанавливаем год из endDate
  if (month < startDate.getMonth()) {
    startOfMonth.setFullYear(endDate.getFullYear());
  }

  // Устанавливаем конец месяца
  const endOfMonth = new Date(
    startOfMonth.getFullYear(),
    month + 1,
    0,
    23,
    59,
    59,
    999
  );

  // Рассчитываем пересечение диапазонов
  const rangeStart = startDate > startOfMonth ? startDate : startOfMonth;
  const rangeEnd = endDate < endOfMonth ? endDate : endOfMonth;

  const diffInMs = rangeEnd.getTime() - rangeStart.getTime();
  return Math.max(0, Math.ceil(diffInMs / (1000 * 60 * 60 * 24))); // Если диапазон некорректен, возвращаем 0
};

const calculateTotalCostBike = (item) => {
  const { bikeData, totals } = item;

  if (totals.dateInterval) {
    const startDate =
      Array.isArray(totals?.dateInterval) && totals?.dateInterval[0];
    const endDate =
      Array.isArray(totals?.dateInterval) && totals?.dateInterval[1];

    const start = startDate instanceof Date ? startDate : new Date(startDate);
    const end = endDate instanceof Date ? endDate : new Date(endDate);

    if (!start || !end || !bikeData.rate) return 0;

    const monthsInRange = getMonthsInInterval(start, end);

    let totalCost = 0;

    monthsInRange.forEach((month) => {
      const daysInMonth = calculateDaysInMonth(month, start, end);
      const monthRate = bikeData.rate.find((r) => r.month === month);

      if (monthRate) {
        totalCost += daysInMonth * monthRate.price;
      }
    });

    return totalCost * totals.quantity;
  }
  return 0;
};

const calculateTotalCostAditionalServices = (selectedAditionalServices) => {
  if (!Array.isArray(selectedAditionalServices)) {
    return 0;
  }

  return selectedAditionalServices.reduce((totalCost, service) => {
    // Проверяем наличие всех необходимых данных
    const count = service?.countOrderedServices || 0;
    const price = service?.price || 0;
    return totalCost + count * price;
  }, 0);
};

const WindowRentalPointOrderV4 = ({ onClose }) => {
  const dispatch = useDispatch();
  // Извлекаем данные из Redux-хранилища
  const { tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Локальное состояние для хранения списка пользователей
  const [currentRentalPointOrders, setCurrentRentalPointOrders] =
    useState(null);

  const [currentCart, setCurrentCart] = useState(null);

  const [windowUsersListV4, setWindowUsersListV4] = useState(false);
  // const [currentOrder, setCurrentOrder] = useState(null);
  const [totalOrders, setTotalOrders] = useState(null);
  // const [selectedUserForOrder, setSelectedUserForOrder] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  // const [newOrderData, setNewOrderData] = useState(null);

  const [currentOrder_selectedUser, setCurrentOrder_selectedUser] =
    useState(null);

  const [newRootOrderData, setNewRootOrderData] = useState(null);
  const [newPreviewOrderData, setNewPreviewOrderData] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);

  const options = [
    {
      item: (
        <div
          style={{
            height: "100px",
            width: "180px",
            // aspectRatio: "16/9",
            borderRadius: "8px",
            backgroundColor:
              paymentMethod === "bankCard"
                ? "var(--color-gray-900)"
                : "var(--color-white)",
            display: "flex",
            alignItems: "end",
            justifyContent: "flex-end",
            overflow: "hidden",
            textAlign: "center",
            cursor: "pointer",
            color:
              paymentMethod === "bankCard"
                ? "var(--color-white)"
                : "var(--color-black)",
            position: "relative",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          <span
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
              boxSizing: "border-box",
            }}
            className="material-symbols-outlined"
          >
            {paymentMethod === "bankCard" ? "check" : "credit_card"}
          </span>

          <p>Bank card</p>
        </div>
      ),
      onClick: () => handlePaymentMethod("bankCard"),
    },

    {
      item: (
        <div
          style={{
            height: "100px",
            width: "180px",
            // aspectRatio: "16/9",
            borderRadius: "8px",
            backgroundColor:
              paymentMethod === "electronicWallets"
                ? "var(--color-gray-900)"
                : "var(--color-white)",
            display: "flex",
            alignItems: "end",
            justifyContent: "flex-end",
            overflow: "hidden",
            textAlign: "center",
            cursor: "pointer",
            color:
              paymentMethod === "electronicWallets"
                ? "var(--color-white)"
                : "var(--color-black)",
            position: "relative",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          <span
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
            className="material-symbols-outlined"
          >
            {paymentMethod === "electronicWallets" ? "check" : "credit_card"}
          </span>
          <p>Electronic wallets</p>
        </div>
      ),
      onClick: () => handlePaymentMethod("electronicWallets"),
    },
    {
      item: (
        <div
          style={{
            height: "100px",
            width: "180px",
            // aspectRatio: "16/9",
            borderRadius: "8px",
            backgroundColor:
              paymentMethod === "cryptocurrency"
                ? "var(--color-gray-900)"
                : "var(--color-white)",
            display: "flex",
            alignItems: "end",
            justifyContent: "flex-end",
            overflow: "hidden",
            textAlign: "center",
            cursor: "pointer",
            color:
              paymentMethod === "cryptocurrency"
                ? "var(--color-white)"
                : "var(--color-black)",
            position: "relative",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          <span
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
            className="material-symbols-outlined"
          >
            {paymentMethod === "cryptocurrency" ? "check" : "credit_card"}
          </span>
          <p>Cryptocurrency</p>
        </div>
      ),
      onClick: () => handlePaymentMethod("cryptocurrency"),
    },
  ];

  /**
   *
   * Наполнение useState
   *
   * */

  useEffect(() => {
    setCurrentCart(tempData?.currentCart || null);
    setCurrentRentalPointOrders(tempData?.currentRentalPointOrders || null);
    // setCurrentOrder(tempData?.currentOrder || null);
    // setNewOrderData(tempData?.currentOrder || null);
    // setSelectedUserForOrder(tempData?.currentOrder_selectedUser || null);
    setCurrentOrder_selectedUser(tempData?.currentOrder_selectedUser || null);

    setUserIdRootDocument(
      tempData?.currentOrder_selectedUser?.idRootDocument || null
    );
  }, [
    tempData?.currentRentalPointOrders,
    tempData?.currentCart,
    tempData?.currentOrder_selectedUser,
  ]);

  // При монтировании сбрасываем selectedUserForOrder
  // useEffect(() => {
  //   setCurrentOrder_selectedUser(null);
  // }, []);

  useEffect(() => {
    if (currentRentalPointOrders) {
      setTotalOrders(calculateTotals(currentRentalPointOrders));
    }
  }, [currentRentalPointOrders]);

  console.log("currentOrder_selectedUser", currentOrder_selectedUser);
  /**
   *
   * Формирование объектов Root и Preview
   *
   * */

  useEffect(() => {
    setNewRootOrderData({
      totals: totalOrders,
      client: currentOrder_selectedUser,
      paymentMethod: paymentMethod,
      currentCart: currentRentalPointOrders,
    });

    setNewPreviewOrderData({
      orderStatus: "new",
      created: Date.now(),
      totals: totalOrders,
      client: {
        email: currentOrder_selectedUser?.email || null,
        phoneNumber: currentOrder_selectedUser?.phoneNumber || null,
        avatarUrl: currentOrder_selectedUser?.avatarUrl || null,
        ownerName: currentOrder_selectedUser?.ownerName || null,
      },
      paymentMethod: paymentMethod,
    });
  }, [
    totalOrders,
    currentOrder_selectedUser,
    paymentMethod,
    currentRentalPointOrders,
  ]);

  /**
   *
   * CRUD функции
   *
   */

  const createData = async () => {
    try {
      dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore
          rootData: newRootOrderData,
          previewData: newPreviewOrderData,

          // files: {
          //   photoFiles: newPhotoFiles,
          //   //  Ниже можно использовать другие массивы файлов, если требуется
          // },
          // Имя того массива файлов который будет использоваться для назначения иконки
          // iconFields: "photoFiles",
          // Определяем пути для коллекций и метаданных с точками в качестве разделителей

          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "orders",

          previewCollectionPath: `manager_ordersPreview.userIds.${userIdRootDocument}.reintalPointIds.${currentRentalPointOrders[0].rootRentalPointData?.idRootDocument}`,

          previewGeneralCollectionPath: `general_ordersPreview.reintalPointIds.${currentRentalPointOrders[0].rootRentalPointData?.idRootDocument}`,

          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: `manager_ordersPreview.userIds.${userIdRootDocument}.reintalPointIds.${currentRentalPointOrders[0].rootRentalPointData?.idRootDocument}.metadata`,

          metadataGeneralDocumentPath: `general_ordersPreview.reintalPointIds.${currentRentalPointOrders[0].rootRentalPointData?.idRootDocument}.metadata`,

          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          // rootCurrentDocumentState: "selectedBike_rootData",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "subscribe_bikesPreview",
          // Сохраняет в общий список массивов объектов
          // previewGeneralDocumenState: "general_bikesPreview",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_manager_order_create",
          errorStateName: "currentError",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("Order успешно создан:", response);

          // Удаляем все элементы из currentRentalPointOrders
          const updatedOrders = currentCart.filter(
            (order) =>
              !currentRentalPointOrders.some(
                (rentalOrder) => rentalOrder.id === order.id
              )
          );

          // Обновляем состояние с пустым currentRentalPointOrders
          dispatch(
            setTempData({
              tempCurrentDocumentState: "currentCart",
              data: updatedOrders,
            })
          );

          // Сбрасываем выбранного пользователя
          dispatch(
            setTempData({
              tempCurrentDocumentState: "currentOrder_selectedUser",
              data: null,
            })
          );

          // Закрываем окно
          onClose();
        })
        .catch((error) => {
          console.error("Ошибка при создании Order:", error);
        });
    } catch (error) {
      console.error("Ошибка при создании или обновлении Order:", error);
    }
  };

  /**
   *
   * Внутренняя логика компонента
   *
   */
  useEffect(() => {
    // Сбрасываем `currentOrder_selectedUser` при монтировании компонента
    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentOrder_selectedUser",
        data: null,
      })
    );

    // Если нужен сброс при размонтировании, добавьте return в useEffect
    return () => {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "currentOrder_selectedUser",
          data: null,
        })
      );
    };
  }, [dispatch]);

  const onRemoveItem = (item) => {
    const updatedOrders = currentCart.filter((order) => order.id !== item.id);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedOrders,
      })
    );
  };

  const handleOpenWindowUsersListV4 = () => {
    setWindowUsersListV4(true);
  };

  const handleClose = () => {
    setWindowUsersListV4(false);
  };

  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowUsersListV4}
        onClose={handleClose}
        title={`Users list`}
      >
        <WindowOrderUsersListV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <WidgetWoucherListV4
            currentRentalPointOrders={currentRentalPointOrders}
          />
        </ContentBox>

        {/* Компонент для ввода email пользователя */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // gap: "2px",
          }}
        >
          <ContentBox position="end">
            <WidgetOrderUsersListV4
              selectedUser={currentOrder_selectedUser}
              onClick={() => handleOpenWindowUsersListV4()}
            />
          </ContentBox>

          <CarouselMenu options={options} height={100} />
          <ContentBox position="start">
            <WidgetPaymentMethodInform paymentMethod={paymentMethod} />
          </ContentBox>
        </div>
        <ContentBox>
          <Table
            columns={1}
            title="Order totals"
            data={[
              {
                title: "Quantity of Bikes in Order:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {totalOrders?.quantity || 0}
                  </p>
                ),
              },
              {
                title: "Total cost additional services:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(
                      totalOrders?.totalCostAditionalServices || 0
                    )}
                  </p>
                ),
              },
              {
                title: "Total cost bike:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.totalCostBike || 0)}
                  </p>
                ),
              },
              {
                title: "Total:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.total || 0)}
                  </p>
                ),
              },
            ]}
          />
        </ContentBox>
      </div>

      {/* Панель кнопок */}
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          {/* Кнопка отмены */}
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          {/* Кнопка подтверждения */}
          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={createData}
            // Здесь можно добавить логику для обработки выбранных пользователей
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPointOrderV4;
