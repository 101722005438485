import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetOrdersList from "./WidgetOrdersList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Preloader from "../../../../UIElements/Preloader";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowCatalogV4 from "./WindowCatalogV4";
import CurrentCartV4 from "./WidgetCurrentCartV4";
import { useDispatch } from "react-redux";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";

const calculateTotalItems = (currentCart) => {
  if (!currentCart || typeof currentCart !== "object") {
    console.error("currentCartV4 не является объектом или не существует.");
    return 0;
  }

  // Суммируем длины всех массивов в объекте
  return Object.values(currentCart).reduce((total, group) => {
    if (Array.isArray(group)) {
      return total + group.length;
    }
    return total;
  }, 0);
};

const WidgetOrders = ({ orders, isLoading, currentCart }) => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState(null);
  const [orderType, setOrderType] = useState(null);
  // const [filteredOrders, setFilteredOrders] = useState([]);

  // WINDOW
  const [windowCreateNewOrderV4, setWindowCreateNewOrderV4] = useState(false);

  // useEffect(() => {
  //   if (orderType) {
  //     const filtered = orders?.filter(
  //       (order) => order.orderStatuses?.currentStatus === orderType
  //     );
  //     setFilteredOrders(filtered);
  //   }
  // }, [orderType, orders]);

  /**
   *
   * Логика компонента
   *
   */
  const createNewOrder = (mode) => {
    setMode(mode);
    setWindowCreateNewOrderV4(true);
  };

  const handleClose = () => {
    setWindowCreateNewOrderV4(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowCreateNewOrderV4}
        onClose={handleClose}
        title={`Create order`}
      >
        <WindowCatalogV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      <ContentBox>
        {!isLoading ? (
          <>
            {/* <AddData
              onClick={() =>
                createNewOrder(currentCart?.length > 0 ? "edit" : "new")
              }
              icon={
                currentCart?.length > 0 ? (
                  <span className="material-symbols-outlined">edit</span>
                ) : (
                  <span className="material-symbols-outlined">add</span>
                )
              }
              title={
                currentCart?.length > 0 ? "Edit order" : "Create new order"
              }
              description={
                currentCart?.length > 0
                  ? "Edit lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              }             
            /> */}
            <AddData
              onClick={() =>
                createNewOrder(
                  calculateTotalItems(currentCart) > 0 ? "edit" : "new"
                )
              }
              icon={
                <span className="material-symbols-outlined">grid_view</span>
              }
              title={"Create new order"}
              description={
                "lorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />

            {/*
             <SegmentedControls
            options={[
              "new",
              "exchange",
              "in progress",
              "completed",
              "canceled",
            ]}
            onSelect="new"
            // label="Orders"
            selected={setOrderType}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt justo odio, gravida tempus erat convallis id. Suspendisse maximus purus non tellus feugiat, ac laoreet diam feugiat."
          /> 
          */}

            {orderType && orders?.length > 0 && (
              <>
                <SegmentedControls
                  options={[
                    "new",
                    "exchange",
                    "in progress",
                    "completed",
                    "canceled",
                  ]}
                  onSelect="new"
                  // label="Orders"
                  selected={setOrderType}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt justo odio, gravida tempus erat convallis id. Suspendisse maximus purus non tellus feugiat, ac laoreet diam feugiat."
                />
                <WidgetOrdersList filteredOrders={null} />
              </>
            )}
          </>
        ) : (
          <Preloader />
        )}
      </ContentBox>
    </>
  );
};

export default WidgetOrders;
