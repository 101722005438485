import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTempData } from "../../../../reducersToolkit/catalogSliceV4";
import { fetchPreviewDataV4 } from "../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import PopupDirrectory from "./WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointOrderV4 from "./OrdersV3/WindowRentalPointOrderV4";
import ContentBox from "./WidgetsProfile/ContentBox/ContentBox";
import WidgetCurrentCartV4 from "./OrdersV3/WidgetCurrentCartV4";

const filterBikes = (bikes, filters) => {
  return bikes.filter((bike) => {
    const { brand, category, city, country, dateInterval, quantityBikes } =
      filters;

    const matchesBrand = !brand || bike.brand?.id === brand.idRootDocument;
    const matchesCategory =
      !category || bike.category?.id === category.idRootDocument;
    const matchesCity = !city || bike.city?.id === city.idRootDocument;
    const matchesCountry =
      !country || bike.country?.id === country.idRootDocument;

    // Проверка на совпадение интервала дат и цены
    const matchesDateInterval =
      !dateInterval ||
      (bike.rate &&
        bike.rate.some((rate) => {
          // Начало месяца
          const rateMonthStart = new Date(dateInterval[0]).setMonth(
            rate.month,
            1
          );

          // Конец месяца
          const rateMonthEnd = new Date(rateMonthStart).setMonth(
            rate.month + 1,
            0
          );

          // Проверяем, пересекаются ли интервал даты и месяц
          return (
            rate.price > 0 && // Цена должна быть больше 0
            ((dateInterval[0] >= rateMonthStart &&
              dateInterval[0] <= rateMonthEnd) || // Начало диапазона внутри месяца
              (dateInterval[1] >= rateMonthStart &&
                dateInterval[1] <= rateMonthEnd) || // Конец диапазона внутри месяца
              (rateMonthStart >= dateInterval[0] &&
                rateMonthEnd <= dateInterval[1])) // Месяц полностью внутри диапазона
          );
        }));

    const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;

    return (
      matchesBrand &&
      matchesCategory &&
      matchesCity &&
      matchesCountry &&
      matchesDateInterval &&
      matchesQuantity
    );
  });
};

const UIBasketV3 = ({ onClose, mode }) => {
  const dispatch = useDispatch();

  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  const [orderBike, setOrderBike] = useState(null);

  const [windowOrderFiltersBikesListV4, setWindowOrderFiltersBikesListV4] =
    useState(false);
  const [windowOrderForUserV4, setWindowOrderForUserV4] = useState(false);

  const [windowOrderDeteIntervalV4, setWindowOrderDeteIntervalV4] =
    useState(null);

  const [windowOrderQuantityBikesV4, setWindowOrderQuantityBikesV4] =
    useState(false);

  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);

  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);

  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brandsPreview, setBrandsPreview] = useState([]);

  const [categoriesPreview, setCategoriesPreview] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [dateInterval, setDateInterval] = useState(null);

  const [differenceInDays, setDifferenceInDays] = useState(0);

  const [newRootFilterData, setNewRootFilterData] = useState(null);

  const [quantityBikes, setQuantityBikes] = useState(1);

  const [manage_fdiltersBikes, setManage_fdiltersBikes] = useState(null);

  const [filteredBikes, setFilteredBikes] = useState([]);

  const [currentCart, setCurrentCart] = useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */

  useEffect(() => {
    setCurrentCart(tempData?.currentCart || null);
    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);
    setQuantityBikes(tempData?.manage_fdiltersBikes?.quantityBikes || 1);
    setDateInterval(tempData?.manage_fdiltersBikes?.dateInterval || null);
    setOrderBike(tempData?.rootCart_orderedBike || null);
    if (
      Array.isArray(tempData?.manage_fdiltersBikes?.dateInterval) &&
      tempData?.manage_fdiltersBikes?.dateInterval.length === 2
    ) {
      const start = new Date(tempData?.manage_fdiltersBikes?.dateInterval[0]); // Начальная дата
      const end = new Date(tempData?.manage_fdiltersBikes?.dateInterval[1]); // Конечная дата

      // Вычисляем количество миллисекунд в одном дне
      const millisecondsInDay = 24 * 60 * 60 * 1000;

      // Разница между датами в миллисекундах
      const differenceInMilliseconds = end - start;

      // Конвертируем разницу в дни
      const differenceInDays = Math.ceil(
        differenceInMilliseconds / millisecondsInDay
      );
      setDifferenceInDays(differenceInDays);
    }
  }, [
    tempData?.manage_fdiltersBikes,
    tempData?.rootCart_orderedBike,
    tempData?.currentCart,
    tempData?.currentCartV4,
  ]);

  useEffect(() => {
    setRentalPoint_countriesListPreview(
      tempPreviewData?.rentalPoint_countriesListPreview || []
    );
    setRentalPoint_citiesListPreview(
      tempPreviewData?.rentalPoint_citiesListPreview || []
    );
    setBrandsPreview(tempPreviewData?.brandsPreview || []);
    setCategoriesPreview(tempPreviewData?.categoriesPreview || []);
  }, [
    tempPreviewData?.rentalPoint_countriesListPreview,
    tempPreviewData?.rentalPoint_citiesListPreview,
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
  ]);

  /**
   *
   * Формирование объектов root и preview
   *
   */

  useEffect(() => {
    setNewRootFilterData({
      city: selectedCity || null,
      country: selectedCountry || null,
      category: selectedCategory || null,
      brand: selectedBrand || null,
      dateInterval: dateInterval || [
        new Date().setHours(0, 0, 0, 0), // Начало текущего дня (время в миллисекундах)
        new Date(new Date().setDate(new Date().getDate() + 7)).setHours(
          23,
          59,
          59,
          999
        ), // Конец 7-го дня (время в миллисекундах)
      ],

      quantityBikes: quantityBikes || 1,
    });
  }, [
    dateInterval,
    selectedCity,
    selectedCountry,
    selectedCategory,
    selectedBrand,
    quantityBikes,
  ]);

  useEffect(() => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "manage_fdiltersBikes",
        data: newRootFilterData,
      })
    );
  }, [dispatch, newRootFilterData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  /**
   *
   * Логика компонента
   *
   */

  const onRemoveItem = (item) => {
    const updatedOrders = currentCart.filter((order) => order.id !== item.id);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedOrders,
      })
    );
  };

  const onUpdateQuantity = (item, newQuantity) => {
    const updatedOrders = currentCart.map((order) => {
      if (order.id === item.id) {
        // Убедимся, что quantity всегда больше 0 и является числом
        const currentQuantity = order.totals.quantity || 1; // Подставляем 1, если undefined или 0
        const totalCostBike = order.totals.totalCostBike || 0; // Защита от undefined
        const totalCostAditioalServices = order.totalCostAditioalServices || 0; // Защита от undefined

        const updatedTotalCostBike =
          (totalCostBike / currentQuantity) * newQuantity;

        const updatedTotal = updatedTotalCostBike + totalCostAditioalServices;

        return {
          ...order,
          totals: {
            ...order.totals,
            quantity: newQuantity,
            total: updatedTotal, // Всегда корректное значение
          },
          totalCostBike: updatedTotalCostBike,
        };
      }
      return order;
    });

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedOrders,
      })
    );
  };

  // Получение данных для фильтров
  useEffect(() => {
    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [
    tempData?.manage_fdiltersBikes,
    tempPreviewData?.general_bikesPreviewNotifications,
  ]);

  // Фильтрация байков
  useEffect(() => {
    if (general_bikesPreviewNotifications && manage_fdiltersBikes) {
      const filtered = filterBikes(
        general_bikesPreviewNotifications,
        manage_fdiltersBikes
      );
      setFilteredBikes(filtered);
    }
  }, [general_bikesPreviewNotifications, manage_fdiltersBikes]);

  useEffect(() => {
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [tempPreviewData?.general_bikesPreviewNotifications]);

  const handleClose = () => {
    setWindowOrderFiltersBikesListV4(false);
    setWindowOrderForUserV4(false);
    setWindowOrderDeteIntervalV4(false);
    setWindowOrderQuantityBikesV4(false);
  };

  const handleOpenWindowOrderForUserV4 = (groupItems) => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentRentalPointOrders",
        data: groupItems,
      })
    );
    setWindowOrderForUserV4(true);
  };

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title={`Order for user`}
      >
        <WindowRentalPointOrderV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            paddingTop: "80px",
            maxWidth: "var(--width-mobile-content)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
              width: "100%",
              marginBottom: "72px",
            }}
          >
            <ContentBox>
              <WidgetCurrentCartV4
                onRemoveItem={onRemoveItem}
                onUpdateQuantity={onUpdateQuantity}
                orders={currentCart}
                onClick={handleOpenWindowOrderForUserV4}
              />
            </ContentBox>
          </div>
        </div>
      </div>
      {/* <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
          />

          <Button
            type="small"
            active={true}
            label="Place your order"
            onClick={handleOpenWindowOrderForUserV4}
          />
        </div>
      </ButtonsBar> */}
    </>
  );
};

export default UIBasketV3;
