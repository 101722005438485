import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
import { db } from "../../../../../services/firebase/authFirebase";
import { v4 as uuidv4 } from "uuid";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// Создаем асинхронный thunk для добавления данных в Firestore
export const createDataThunkV4 = createAsyncThunk(
  "locations/createDataThunkV4", // Название экшена
  async (
    {
      rootData, // Основные данные для корневого документа
      idRootDocument, // ID корневого документа (если есть)
      previewData, // Данные для предварительного документа
      files, // Файлы для загрузки
      iconFields, // Поля для иконок
      rootCollectionPath, // Путь к коллекции для корневого документа
      previewCollectionPath, // Путь к коллекции для предварительных документов
      previewGeneralCollectionPath, // Путь к общей коллекции предварительных документов
      metadataDocumentPath, // Путь к документу метаданных
      metadataGeneralDocumentPath, // Путь к общим метаданным
      rootCurrentDocumentState, // Текущее состояние корневого документа
      previewCurrentDocumenState, // Текущее состояние предварительного документа
      previewGeneralDocumenState, // Текущее состояние общего предварительного документа
      loadingStateName, // Название состояния загрузки
      errorStateName, // Название состояния ошибки
    },
    { rejectWithValue }
  ) => {
    try {
      // 1. Создание или получение корневого документа
      const docRef = idRootDocument
        ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
        : doc(collection(db, ...rootCollectionPath.split(".")));

      // Записываем основные данные в Firestore
      await setDoc(docRef, rootData);

      // 2. Загрузка файлов
      const uploadedFiles = {};

      if (files && Object.keys(files).length > 0) {
        for (const [fieldName, fileArray] of Object.entries(files)) {
          if (fileArray && fileArray.length > 0) {
            const uploaded = await uploadFilesToFirebase(
              `files/${docRef.id}/${fieldName}`,
              fileArray
            );

            const formattedFiles = uploaded.map((file) => ({
              url: file.url,
              name: file.name,
              priority: file.priority || false,
              id: uuidv4(),
            }));

            uploadedFiles[fieldName] = formattedFiles;
          }
        }
      }

      // Обновляем данные документа с загруженными файлами
      const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
        acc.files = acc.files || {};
        acc.files[key] = uploadedFiles[key];
        return acc;
      }, {});

      if (Object.keys(updatedData).length > 0) {
        await updateDoc(docRef, updatedData);
      }

      // Выбираем файл с приоритетом или первый файл
      const selectedFile =
        uploadedFiles[iconFields]?.find((file) => file.priority) ||
        uploadedFiles[iconFields]?.[0] ||
        null;

      // Данные для предварительного документа
      const previewDocumentData = {
        ...previewData,
        idRootDocument: docRef.id,
        idPreviewDocument: "",
        fileUrl: selectedFile ? selectedFile.url : "",
      };

      // Рассчитываем размер объекта данных
      const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

      // Получаем метаданные последнего обновленного документа
      const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
      const languageDocSnap = await getDoc(languageDocRef);

      let lastUpdatedDocId;
      let lastUpdatedDocSize;

      if (languageDocSnap.exists()) {
        const languageData = languageDocSnap.data();
        lastUpdatedDocId =
          languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
        lastUpdatedDocSize =
          languageData.lastUpdatedDocument?.documentSize || 0;
      } else {
        lastUpdatedDocId = null;
        lastUpdatedDocSize = 0;
      }

      let newDocumentSize;
      let idDocumentToSave;
      let isNewDocument = false;

      // Проверяем, можем ли добавить данные в существующий документ
      if (
        lastUpdatedDocId &&
        lastUpdatedDocSize + objectSize <= 1024 * 1024 - 500
      ) {
        idDocumentToSave = lastUpdatedDocId;
        newDocumentSize = lastUpdatedDocSize + objectSize;
      } else {
        // Создаем новый документ
        const newOriginalDocRef = doc(
          collection(db, ...previewCollectionPath.split("."))
        );
        idDocumentToSave = newOriginalDocRef.id;
        newDocumentSize = objectSize;
        isNewDocument = true;
      }

      // Сохраняем данные предварительного документа
      const originalDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idDocumentToSave
      );
      previewDocumentData.idPreviewDocument = idDocumentToSave;

      await setDoc(
        originalDocRef,
        {
          data: arrayUnion(previewDocumentData),
          documentSize: newDocumentSize,
        },
        { merge: true }
      );

      // Обновляем данные о последнем обновленном документе
      const updatedLastUpdatedDocument = isNewDocument
        ? {
            lastUpdatedDocumentId: idDocumentToSave,
            documentSize: newDocumentSize,
          }
        : {
            documentSize: newDocumentSize,
          };

      await setDoc(
        languageDocRef,
        {
          lastUpdatedDocument: updatedLastUpdatedDocument,
        },
        { merge: true }
      );

      // Работа с общими метаданными, если пути предоставлены
      if (previewGeneralCollectionPath && metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

        let generalLastUpdatedDocId;
        let generalLastUpdatedDocSize;

        if (generalMetadataDocSnap.exists()) {
          const generalMetadataData = generalMetadataDocSnap.data();
          generalLastUpdatedDocId =
            generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
          generalLastUpdatedDocSize =
            generalMetadataData.lastUpdatedDocument?.documentSize || 0;
        } else {
          generalLastUpdatedDocId = null;
          generalLastUpdatedDocSize = 0;
        }

        let generalNewDocumentSize;
        let generalIdDocumentToSave;
        let isNewGeneralDocument = false;

        // Проверяем, можем ли добавить данные в существующий общий документ
        if (
          generalLastUpdatedDocId &&
          generalLastUpdatedDocSize + objectSize <= 1024 * 1024 - 500
        ) {
          generalIdDocumentToSave = generalLastUpdatedDocId;
          generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
        } else {
          const newGeneralDocRef = doc(
            collection(db, ...previewGeneralCollectionPath.split("."))
          );
          generalIdDocumentToSave = newGeneralDocRef.id;
          generalNewDocumentSize = objectSize;
          isNewGeneralDocument = true;
        }

        const generalDocRef = doc(
          db,
          ...previewGeneralCollectionPath.split("."),
          generalIdDocumentToSave
        );

        await setDoc(
          generalDocRef,
          {
            data: arrayUnion(previewDocumentData),
            documentSize: generalNewDocumentSize,
          },
          { merge: true }
        );

        // Обновляем метаданные общего документа
        const updatedGeneralLastUpdatedDocument = isNewGeneralDocument
          ? {
              lastUpdatedDocumentId: generalIdDocumentToSave,
              documentSize: generalNewDocumentSize,
            }
          : {
              documentSize: generalNewDocumentSize,
            };

        await setDoc(
          generalMetadataDocRef,
          {
            lastUpdatedDocument: updatedGeneralLastUpdatedDocument,
          },
          { merge: true }
        );
      }

      // Обновляем новые идентификаторы данных в метаданных
      await setDoc(
        languageDocRef,
        {
          newDataIds: arrayUnion(docRef.id),
        },
        { merge: true }
      );

      if (metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        await setDoc(
          generalMetadataDocRef,
          {
            newDataIds: arrayUnion(docRef.id),
          },
          { merge: true }
        );
      }

      // Подготавливаем данные для возврата
      const rootDocumentData = {
        idRootDocument: docRef.id,
        ...rootData,
        files: updatedData.files || {},
      };

      // Возвращаем результат
      return {
        rootDocumentData,
        previewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error(
        "Ошибка при добавлении данных в Firestore:",
        error,
        "error.code: ",
        error.code
      );
      return rejectWithValue({ errorMessage });
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// // Создаем асинхронный thunk для добавления данных в Firestore
// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4", // Название экшена
//   async (
//     {
//       rootData, // Основные данные для корневого документа
//       idRootDocument, // ID корневого документа (если есть)
//       previewData, // Данные для предварительного документа
//       files, // Файлы для загрузки
//       iconFields, // Поля для иконок
//       rootCollectionPath, // Путь к коллекции для корневого документа
//       previewCollectionPath, // Путь к коллекции для предварительных документов
//       previewGeneralCollectionPath, // Путь к общей коллекции предварительных документов
//       metadataDocumentPath, // Путь к документу метаданных
//       metadataGeneralDocumentPath, // Путь к общим метаданным
//       rootCurrentDocumentState, // Текущее состояние корневого документа
//       previewCurrentDocumenState, // Текущее состояние предварительного документа
//       previewGeneralDocumenState, // Текущее состояние общего предварительного документа
//       loadingStateName, // Название состояния загрузки
//       errorStateName, // Название состояния ошибки
//     },
//     { rejectWithValue } // Функция для отката ошибки
//   ) => {
//     try {
//       // 1. Создание или получение корневого документа
//       const docRef = idRootDocument
//         ? doc(db, ...rootCollectionPath.split("."), idRootDocument) // Если ID документа есть, получаем его по пути
//         : doc(collection(db, ...rootCollectionPath.split("."))); // Иначе создаем новый документ

//       // Записываем основные данные в Firestore
//       await setDoc(docRef, rootData);

//       // 2. Загрузка файлов
//       const uploadedFiles = {}; // Для хранения данных о загруженных файлах

//       // Проверяем, есть ли файлы для загрузки
//       if (files && Object.keys(files).length > 0) {
//         // Для каждого поля файлов в объекте files
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             // Загружаем файлы в Firebase
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             // Форматируем файлы для сохранения
//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             // Сохраняем загруженные файлы в объекте
//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       // Обновляем данные документа с загруженными файлами
//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       // Если есть загруженные файлы, обновляем документ в Firestore
//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       // Выбираем файл с приоритетом или первый файл
//       const selectedFile =
//         uploadedFiles[iconFields]?.find((file) => file.priority) ||
//         uploadedFiles[iconFields]?.[0] ||
//         null;

//       // Данные для предварительного документа
//       const previewDocumentData = {
//         ...previewData,
//         idRootDocument: docRef.id, // ID корневого документа
//         idPreviewDocument: "",
//         fileUrl: selectedFile ? selectedFile.url : "", // URL файла, если он есть
//       };

//       // Рассчитываем размер документа
//       const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

//       // Пытаемся получить данные о последнем обновленном документе для локализации
//       const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       // Если документ существует, получаем данные о последнем обновленном документе
//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//       } else {
//         lastUpdatedDocId = null;
//         lastUpdatedDocSize = 0;
//       }

//       let newDocumentSize;
//       let idDocumentToSave;

//       // Если предыдущий документ не переполняет лимит размера, обновляем его
//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         // Создаем новый документ, если лимит размера превышен
//         const newOriginalDocRef = doc(
//           collection(db, ...previewCollectionPath.split("."))
//         );
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       // Сохраняем данные предварительного документа
//       const originalDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idDocumentToSave
//       );
//       previewDocumentData.idPreviewDocument = idDocumentToSave;

//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(previewDocumentData), // Добавляем новые данные в массив
//           documentSize: newDocumentSize,
//         },
//         { merge: true } // Используем merge, чтобы не перезаписать весь документ
//       );

//       // Обновляем данные о последнем обновленном документе
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       // Если есть общий путь метаданных, обновляем и их
//       if (previewGeneralCollectionPath && metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

//         let generalLastUpdatedDocId;
//         let generalLastUpdatedDocSize;

//         if (generalMetadataDocSnap.exists()) {
//           const generalMetadataData = generalMetadataDocSnap.data();
//           generalLastUpdatedDocId =
//             generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
//           generalLastUpdatedDocSize =
//             generalMetadataData.lastUpdatedDocument?.documentSize || 0;
//         } else {
//           generalLastUpdatedDocId = null;
//           generalLastUpdatedDocSize = 0;
//         }

//         let generalNewDocumentSize;

//         // Если общий документ не переполняет лимит размера, обновляем его
//         if (
//           generalLastUpdatedDocId === idDocumentToSave &&
//           generalLastUpdatedDocSize + objectSize <= 1024 * 1024
//         ) {
//           generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
//         } else {
//           // Создаем новый общий документ, если лимит превышен
//           generalNewDocumentSize = objectSize;
//         }

//         // Сохраняем данные в общий документ
//         const generalDocRef = doc(
//           db,
//           ...previewGeneralCollectionPath.split("."),
//           idDocumentToSave
//         );

//         await setDoc(
//           generalDocRef,
//           {
//             data: arrayUnion(previewDocumentData),
//             documentSize: generalNewDocumentSize,
//           },
//           { merge: true }
//         );

//         // Обновляем данные о последнем обновленном общем документе
//         await setDoc(
//           generalMetadataDocRef,
//           {
//             lastUpdatedDocument: {
//               lastUpdatedDocumentId: idDocumentToSave,
//               documentSize: generalNewDocumentSize,
//             },
//           },
//           { merge: true }
//         );
//       }

//       // Обновляем новые идентификаторы данных в метаданных
//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       if (metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         await setDoc(
//           generalMetadataDocRef,
//           {
//             newDataIds: arrayUnion(docRef.id),
//           },
//           { merge: true }
//         );
//       }

//       // Подготавливаем данные для возврата
//       const rootDocumentData = {
//         idRootDocument: docRef.id,
//         ...rootData,
//         files: updatedData.files || {},
//       };

//       // Возвращаем результат
//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         previewGeneralCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         previewGeneralDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       // Если произошла ошибка, интерпретируем и возвращаем её
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при добавлении данных в Firestore:",
//         error,
//         "error.code: ",
//         error.code
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4",
//   async (
//     {
//       rootData,
//       idRootDocument,
//       previewData,
//       files,
//       iconFields,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // 1. Создание или получение корневого документа
//       const docRef = idRootDocument
//         ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
//         : doc(collection(db, ...rootCollectionPath.split(".")));

//       await setDoc(docRef, rootData);

//       // 2. Загрузка файлов
//       const uploadedFiles = {};

//       if (files && Object.keys(files).length > 0) {
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       const selectedFile =
//         uploadedFiles[iconFields]?.find((file) => file.priority) ||
//         uploadedFiles[iconFields]?.[0] ||
//         null;

//       const previewDocumentData = {
//         ...previewData,
//         idRootDocument: docRef.id,
//         idPreviewDocument: "",
//         fileUrl: selectedFile ? selectedFile.url : "",
//       };

//       const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

//       const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//       } else {
//         lastUpdatedDocId = null;
//         lastUpdatedDocSize = 0;
//       }

//       let newDocumentSize;
//       let idDocumentToSave;

//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         const newOriginalDocRef = doc(
//           collection(db, ...previewCollectionPath.split("."))
//         );
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       const originalDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idDocumentToSave
//       );
//       previewDocumentData.idPreviewDocument = idDocumentToSave;

//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(previewDocumentData),
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       if (previewGeneralCollectionPath && metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

//         let generalLastUpdatedDocId;
//         let generalLastUpdatedDocSize;

//         if (generalMetadataDocSnap.exists()) {
//           const generalMetadataData = generalMetadataDocSnap.data();
//           generalLastUpdatedDocId =
//             generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
//           generalLastUpdatedDocSize =
//             generalMetadataData.lastUpdatedDocument?.documentSize || 0;
//         } else {
//           generalLastUpdatedDocId = null;
//           generalLastUpdatedDocSize = 0;
//         }

//         let generalNewDocumentSize;

//         if (
//           generalLastUpdatedDocId === idDocumentToSave &&
//           generalLastUpdatedDocSize + objectSize <= 1024 * 1024
//         ) {
//           generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
//         } else {
//           generalNewDocumentSize = objectSize;
//         }

//         const generalDocRef = doc(
//           db,
//           ...previewGeneralCollectionPath.split("."),
//           idDocumentToSave
//         );

//         await setDoc(
//           generalDocRef,
//           {
//             data: arrayUnion(previewDocumentData),
//             documentSize: generalNewDocumentSize,
//           },
//           { merge: true }
//         );

//         await setDoc(
//           generalMetadataDocRef,
//           {
//             lastUpdatedDocument: {
//               lastUpdatedDocumentId: idDocumentToSave,
//               documentSize: generalNewDocumentSize,
//             },
//           },
//           { merge: true }
//         );
//       }

//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       if (metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         await setDoc(
//           generalMetadataDocRef,
//           {
//             newDataIds: arrayUnion(docRef.id),
//           },
//           { merge: true }
//         );
//       }

//       const rootDocumentData = {
//         idRootDocument: docRef.id,
//         ...rootData,
//         files: updatedData.files || {},
//       };

//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         previewGeneralCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         previewGeneralDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при добавлении данных в Firestore:",
//         error,
//         "error.code: ",
//         error.code
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4",
//   async (
//     {
//       rootData,
//       idRootDocument,
//       previewData,
//       files,
//       iconFields,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       metadataDocumentPath,
//       metadataGeneralDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // 1. Создание или получение корневого документа
//       const docRef = idRootDocument
//         ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
//         : doc(collection(db, ...rootCollectionPath.split(".")));

//       await setDoc(docRef, rootData);

//       // 2. Загрузка файлов
//       const uploadedFiles = {};

//       if (files && Object.keys(files).length > 0) {
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       // 3. Обновление корневого документа с загруженными файлами
//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       // 4. Подготовка previewDocumentData
//       const selectedFile =
//         uploadedFiles[iconFields]?.find((file) => file.priority) ||
//         uploadedFiles[iconFields]?.[0] ||
//         null;

//       const previewDocumentData = {
//         ...previewData,
//         idRootDocument: docRef.id,
//         idPreviewDocument: "",
//         fileUrl: selectedFile ? selectedFile.url : "",
//       };

//       const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

//       // 5. Получение информации lastUpdatedDocument для previewCollectionPath
//       const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//       } else {
//         // Инициализируем значения, если документа метаданных нет
//         lastUpdatedDocId = null;
//         lastUpdatedDocSize = 0;
//       }

//       // 6. Определение документа для сохранения в previewCollectionPath
//       let newDocumentSize;
//       let idDocumentToSave;

//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         const newOriginalDocRef = doc(
//           collection(db, ...previewCollectionPath.split("."))
//         );
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       const originalDocRef = doc(
//         db,
//         ...previewCollectionPath.split("."),
//         idDocumentToSave
//       );
//       previewDocumentData.idPreviewDocument = idDocumentToSave;

//       // 7. Добавление previewDocumentData в previewCollectionPath
//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(previewDocumentData),
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // 8. Обновление lastUpdatedDocument в metadataDocumentPath
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       // 9. Обработка previewGeneralCollectionPath аналогично
//       if (previewGeneralCollectionPath && metadataGeneralDocumentPath) {
//         // Получаем информацию lastUpdatedDocument для previewGeneralCollectionPath
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );
//         const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

//         let generalLastUpdatedDocId;
//         let generalLastUpdatedDocSize;

//         if (generalMetadataDocSnap.exists()) {
//           const generalMetadataData = generalMetadataDocSnap.data();
//           generalLastUpdatedDocId =
//             generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
//           generalLastUpdatedDocSize =
//             generalMetadataData.lastUpdatedDocument?.documentSize || 0;
//         } else {
//           // Инициализируем значения, если документа метаданных нет
//           generalLastUpdatedDocId = null;
//           generalLastUpdatedDocSize = 0;
//         }

//         // Определяем документ для сохранения в previewGeneralCollectionPath
//         let generalNewDocumentSize;
//         let generalIdDocumentToSave;

//         if (
//           generalLastUpdatedDocId &&
//           generalLastUpdatedDocSize + objectSize <= 1024 * 1024
//         ) {
//           generalIdDocumentToSave = generalLastUpdatedDocId;
//           generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
//         } else {
//           const newGeneralDocRef = doc(
//             collection(db, ...previewGeneralCollectionPath.split("."))
//           );
//           generalIdDocumentToSave = newGeneralDocRef.id;
//           generalNewDocumentSize = objectSize;
//         }

//         const generalDocRef = doc(
//           db,
//           ...previewGeneralCollectionPath.split("."),
//           generalIdDocumentToSave
//         );

//         // Добавляем previewDocumentData в previewGeneralCollectionPath
//         await setDoc(
//           generalDocRef,
//           {
//             data: arrayUnion(previewDocumentData),
//             documentSize: generalNewDocumentSize,
//           },
//           { merge: true }
//         );

//         // Обновляем lastUpdatedDocument в metadataGeneralDocumentPath
//         await setDoc(
//           generalMetadataDocRef,
//           {
//             lastUpdatedDocument: {
//               lastUpdatedDocumentId: generalIdDocumentToSave,
//               documentSize: generalNewDocumentSize,
//             },
//           },
//           { merge: true }
//         );
//       }

//       // 10. Обновление newDataIds в метаданных
//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       if (metadataGeneralDocumentPath) {
//         const generalMetadataDocRef = doc(
//           db,
//           ...metadataGeneralDocumentPath.split(".")
//         );

//         await setDoc(
//           generalMetadataDocRef,
//           {
//             newDataIds: arrayUnion(docRef.id),
//           },
//           { merge: true }
//         );
//       }

//       // 11. Подготовка rootDocumentData
//       const rootDocumentData = {
//         idRootDocument: docRef.id,
//         ...rootData,
//         files: updatedData.files || {},
//       };

//       return {
//         rootDocumentData,
//         previewDocumentData,
//         rootCollectionPath,
//         previewCollectionPath,
//         previewGeneralCollectionPath,
//         rootCurrentDocumentState,
//         previewCurrentDocumenState,
//         previewGeneralDocumenState,
//         loadingStateName,
//         errorStateName,
//       };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при добавлении данных в Firestore:",
//         error,
//         "error.code: ",
//         error.code
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   collection,
//   doc,
//   setDoc,
//   updateDoc,
//   arrayUnion,
//   getDoc,
// } from "firebase/firestore";
// import { uploadFilesToFirebase } from "../../../../../services/firebase/crudFirestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import { v4 as uuidv4 } from "uuid";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";

// export const createDataThunkV4 = createAsyncThunk(
//   "locations/createDataThunkV4",
//   async (
//     {
//       rootData,
//       idRootDocument,
//       previewData,
//       files,
//       iconFields,
//       rootCollectionPath,
//       previewCollectionPath,
//       previewGeneralCollectionPath,
//       metadataDocumentPath,
//       rootCurrentDocumentState,
//       previewCurrentDocumenState,
//       previewGeneralDocumenState,
//       loadingStateName,
//       errorStateName,
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       // Если передан idRootDocument, то создаем ссылку на документ с этим ID, иначе генерируем новый ID
//       const docRef = idRootDocument
//         ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
//         : doc(collection(db, ...rootCollectionPath.split(".")));

//       // Сохранение данных в Firestore без файлов
//       await setDoc(docRef, rootData);

//       // 2. Загрузка файлов в Firebase Storage и обновление документа в Firestore
//       const uploadedFiles = {};

//       // Обработка файлов по массивам (например: iconFiles, galleryFiles)
//       if (files && Object.keys(files).length > 0) {
//         for (const [fieldName, fileArray] of Object.entries(files)) {
//           if (fileArray && fileArray.length > 0) {
//             const uploaded = await uploadFilesToFirebase(
//               `files/${docRef.id}/${fieldName}`,
//               fileArray
//             );

//             // Преобразуем загруженные файлы в нужный формат
//             const formattedFiles = uploaded.map((file) => ({
//               url: file.url,
//               name: file.name,
//               priority: file.priority || false,
//               id: uuidv4(),
//             }));

//             // Добавляем загруженные файлы в объект uploadedFiles с соответствующим именем поля
//             uploadedFiles[fieldName] = formattedFiles;
//           }
//         }
//       }

//       // 3. Обновление документа в Firestore с ссылками на загруженные файлы
//       const updatedData = Object.keys(uploadedFiles).reduce((acc, key) => {
//         acc.files = acc.files || {};
//         acc.files[key] = uploadedFiles[key];
//         return acc;
//       }, {});

//       if (Object.keys(updatedData).length > 0) {
//         await updateDoc(docRef, updatedData);
//       }

//       // 4. Сохранение ID документа в newDataIds
//       const languageDocRef = doc(db, ...metadataDocumentPath.split("."));

//       await setDoc(
//         languageDocRef,
//         {
//           newDataIds: arrayUnion(docRef.id),
//         },
//         { merge: true }
//       );

//       // 5. Чтение данных из поля lastUpdatedDocument
//       const languageDocSnap = await getDoc(languageDocRef);

//       let lastUpdatedDocId;
//       let lastUpdatedDocSize;

//       if (languageDocSnap.exists()) {
//         const languageData = languageDocSnap.data();
//         lastUpdatedDocSize =
//           languageData.lastUpdatedDocument?.documentSize || 0;
//         lastUpdatedDocId =
//           languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
//       } else {
//         return rejectWithValue("Документ language не найден.");
//       }

//       // 6. Оценка размера объекта previewData
//       const originalCollectionRef = collection(
//         db,
//         ...previewCollectionPath.split(".")
//       );

//       // Выбор нулевого элемента или элемента с флагом priority: true из iconFiles
//       const selectedFile =
//         uploadedFiles[iconFields]?.find((file) => file.priority) ||
//         uploadedFiles[iconFields]?.[0] ||
//         null;

//       // Используем previewData, который был передан в качестве аргумента
//       const previewDocumentData = {
//         ...previewData,
//         idRootDocument: docRef.id, // ID основного документа
//         idPreviewDocument: "", // будет назначено позже
//         fileUrl: selectedFile ? selectedFile.url : "", // Используем только выбранный файл
//       };

//       const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

//       // 7. Определение документа для сохранения
//       let newDocumentSize;
//       let idDocumentToSave;

//       if (lastUpdatedDocId && lastUpdatedDocSize + objectSize <= 1024 * 1024) {
//         idDocumentToSave = lastUpdatedDocId;
//         newDocumentSize = lastUpdatedDocSize + objectSize;
//       } else {
//         const newOriginalDocRef = doc(originalCollectionRef);
//         idDocumentToSave = newOriginalDocRef.id;
//         newDocumentSize = objectSize;
//       }

//       // 8. Обновление документа в original
//       const originalDocRef = doc(originalCollectionRef, idDocumentToSave);
//       previewDocumentData.idPreviewDocument = idDocumentToSave; // Назначаем ID для preview документа

//       await setDoc(
//         originalDocRef,
//         {
//           data: arrayUnion(previewDocumentData),
//           documentSize: newDocumentSize,
//         },
//         { merge: true }
//       );

//       // 9. Обновление lastUpdatedDocument в документе language
//       await setDoc(
//         languageDocRef,
//         {
//           lastUpdatedDocument: {
//             lastUpdatedDocumentId: idDocumentToSave,
//             documentSize: newDocumentSize,
//           },
//         },
//         { merge: true }
//       );

//       // Формируем возвращаемые данные для сохранения в срезе
//       const rootDocumentData = {
//         idRootDocument: docRef.id, // Сохраняется в срезе, но не в Firestore
//         ...rootData,
//         files: updatedData.files || {}, // Добавляем файлы
//       };

//       return {
//         rootDocumentData, // Объект, содержащий данные основного документа (root), включая ID и файлы
//         previewDocumentData, // Объект, содержащий данные документа предварительного просмотра (preview)
//         rootCollectionPath, // Строка с путём до коллекции основного документа
//         previewCollectionPath, // Строка с путём до коллекции документа предварительного просмотра
//         previewGeneralCollectionPath,
//         rootCurrentDocumentState, // Строка, представляющая название состояния, связанного с основным документом в Redux срезе
//         previewCurrentDocumenState, // Строка, представляющая название состояния, связанного с документом предварительного просмотра в Redux срезе
//         previewGeneralDocumenState,
//         loadingStateName, // Строка, представляющая название состояния загрузки (loading state) в Redux срезе
//         errorStateName, // Строка, представляющая название состояния ошибки (error state) в Redux срезе
//       };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при добавлении данных в Firestore:",
//         error,
//         "error.code: ",
//         error.code
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );
